import React, { useState, useEffect, useMemo, useContext } from "react";
import Swal from "sweetalert2";

import { config } from "../config.js";
import { useForm } from "react-hook-form";
import { useConnection } from "../components/Utils/MyHooks";
import CardComponent from "./Templates/CardComponent.jsx";
import TestModal from "./Templates/TestModal";
import TableEditableComponent from "./Templates/TableEditableComponent";
import BasicView from "./Templates/BasicView";
import * as services from "../services/embarazo_services";
import * as utils from "../components/Utils/Utils";
import { MenuContext } from "../components/Utils/MenuContext";
import ButtonStatus from "./Templates/ButtonStatus.jsx";

const FormLaboratorio = React.forwardRef((props, ref) => {
  const [fields, setFields] = useState({});

  useEffect(() => {
    if (props.data) setFields(props.data);
  }, [props.data]);

  return (
    <div className="form form-row">
      <div className="form-group col-md-3 col-lg-3">
        <label className="az-content-label-sm tx-gray-600">Nombre</label>
        <input
          type="text"
          style={{ textTransform: "capitalize" }}
          className={
            props.errors["nombre"]
              ? "form-control bd bd-1 bd-danger"
              : "form-control"
          }
          name="nombre"
          ref={ref({ required: true })}
          defaultValue={fields["nombre"]}
        />
      </div>
      <div className="form-group col-md-2 col-lg-2">
        <label className="az-content-label-sm tx-gray-600">Abreviatura</label>
        <input
          type="text"
          style={{ textTransform: "uppercase" }}
          className={
            props.errors["abreviatura"]
              ? "form-control bd bd-1 bd-danger"
              : "form-control"
          }
          name="abreviatura"
          placeholder={"Max. 10 caract."}
          ref={ref({ required: true, maxLength: 10 })}
          defaultValue={fields["abreviatura"]}
        />
      </div>
      <div className="form-group col-md-2 col-lg-2">
        <label className="az-content-label-sm tx-gray-600">Valor Texto</label>
        <label className="ckbox">
          <input
            type="checkbox"
            name="valor_text"
            ref={ref}
            checked={fields["valor_text"] || false}
            onChange={(e) => {
              let value = e.target.checked;
              setFields((prev) => ({ ...prev, valor_text: value }));
            }}
          />
          <span></span>
        </label>
      </div>
      <div className="form-group col-md-2 col-lg-2">
        <label className="az-content-label-sm tx-gray-600">Valor +/-</label>
        <label className="ckbox">
          <input
            type="checkbox"
            name="valor_bool"
            ref={ref}
            checked={fields["valor_bool"] || false}
            onChange={(e) => {
              let value = e.target.checked;
              setFields((prev) => ({ ...prev, valor_bool: value }));
            }}
          />
          <span></span>
        </label>
      </div>
      <div className="form-group col-md-2 col-lg-2">
        <label className="az-content-label-sm tx-gray-600">
          Valor Numérico
        </label>
        <label className="ckbox">
          <input
            type="checkbox"
            name="valor_int"
            ref={ref}
            checked={fields["valor_int"] || false}
            onChange={(e) => {
              let value = e.target.checked;
              setFields((prev) => ({ ...prev, valor_int: value }));
            }}
          />
          <span></span>
        </label>
      </div>
    </div>
  );
});

export default function AdminLaboratoriosView(props) {
  const [body, setBody] = useState([]);
  const menu = useContext(MenuContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modal_data, setModalData] = useState({});

  const lab_conn_new = useConnection();
  const lab_conn_update = useConnection();
  const lab_init = useConnection(true);

  const lab_form_new = useForm();
  const lab_form_update = useForm();

  const [status, setStatus] = useState("");

  useEffect(() => {
    lab_init.get(config.api_laboratorios);
  }, []);

  useEffect(() => {
    if (lab_init.status === "done") {
      if (lab_init.data) {
        setBody(lab_init.data);
      }
    }
  }, [lab_init.status]);

  useEffect(() => {
    if (lab_conn_new.status === "done") {
      if (lab_conn_new.data) {
        setBody((prev) => [lab_conn_new.data, ...prev]);
        setStatus("done");
        setIsOpen(false);
      } else {
        setIsOpen(false);
        setStatus("error");
        error_msg(lab_conn_new.error.msg);
      }
    }
  }, [lab_conn_new.status]);

  useEffect(() => {
    if (lab_conn_update.status === "done") {
      if (lab_conn_update.data) {
        setBody((prev) =>
          prev.map((o) => {
            let tmp = lab_conn_update.data;
            if (o.id === lab_conn_update.data.id) return tmp;
            return o;
          })
        );
        setIsOpen(false);
      } else {
        setIsOpen(false);
        error_msg(lab_conn_update.error.msg);
      }
    }
  }, [lab_conn_update.status]);

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  return (
    <BasicView
      title={""}
      error={lab_init.error ? [lab_init.error.msg] : []}
      loading={lab_init.isLoading}
      nav={[
        {
          id: "home",
          nav: "Inicio",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("home")}
            >
              <i className="typcn typcn-home-outline"></i> Inicio
            </a>
          ),
        },
        {
          id: "pacientes_list",
          nav: "Listado Pacientes",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("pacientes-list")}
            >
              <i className="far fa-address-book mg-r-5 tx-16"></i> Listado
              Pacientes
            </a>
          ),
        },
        {
          id: "embarazos_list",
          nav: "Listado Embarazos",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("embarazos-list")}
            >
              <i className="far fa-list-alt mg-r-5 tx-16"></i> Listado Embarazos
            </a>
          ),
        },
      ]}
    >
      <CardComponent>
        <div className="az-content-label tx-12 mg-b-5">
          Agregar Nuevo Laboratorio
        </div>
        <div className="form form-row mg-t-10">
          <div className="form-group col-md-8 col-lg-8">
            <FormLaboratorio
              key={lab_conn_new.status === "done" ? 1 : 0}
              ref={lab_form_new.register}
              errors={lab_form_new.errors}
            />
          </div>
          <div className="form-group col-md-3 col-lg-3">
            <ButtonStatus
              clase={"btn btn-az-primary mg-t-10 float-right"}
              status={status}
              // txt_error={
              //   lab_conn_new.error ? "Error " + lab_conn_new.error.code : null
              // }
              txt_success={"Guardado!"}
              txt_loading="Guardando.."
              onClick={lab_form_new.handleSubmit((x) => {
                setStatus("loading");
                let form = services.format_abm_laboratorios(x);
                lab_conn_new.post(config.api_laboratorios, form);
              })}
            >
              <i className="far fa-save tx-15 mg-r-5"></i>
              Crear Laboratorio
            </ButtonStatus>
          </div>
        </div>
      </CardComponent>

      <TestModal
        title={"Editar Laboratorio"}
        isOpen={modalIsOpen}
        aceptar={
          <button
            type="button"
            className="btn btn-az-primary"
            onClick={lab_form_update.handleSubmit((x) => {
              let form = services.format_abm_laboratorios(x);
              lab_conn_update.patch(
                config.api_laboratorios + modal_data.id + "/",
                form
              );
            })}
          >
            <i className="far fa-save tx-15 mg-r-5"></i>
            Actualizar
          </button>
        }
        cancelar={
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </button>
        }
      >
        <FormLaboratorio
          ref={lab_form_update.register}
          errors={lab_form_update.errors}
          data={modal_data}
        />
      </TestModal>
      <CardComponent style={"mg-t-10"}>
        <div className="az-content-label tx-12 mg-b-5">
          Listado de Laboratorios
        </div>
        <div style={{ height: 500 }} className="scroll mg-t-10">
          {useMemo(
            () => (
              <TableEditableComponent
                no_toolbar={true}
                sorting={true}
                print_button={false}
                download_button={false}
                paging={true}
                no_editable={true}
                max_items={7}
                data={body}
                header={[
                  {
                    title: "ID",
                    field: "id",
                    width: "5%",
                    defaultSort: "desc",
                    render: (x) => {
                      return x.id;
                    },
                  },
                  {
                    title: "Nombre",
                    field: "nombre",
                    width: "20%",
                    render: (x) => {
                      return x.nombre;
                    },
                  },
                  {
                    title: "Abreviatura",
                    field: "abreviatura",
                    width: "10%",
                    render: (x) => {
                      if (x !== "") return x.abreviatura.toUpperCase();
                      else return "-";
                    },
                  },
                  {
                    title: "Valor Texto",
                    field: "valor_text",
                    width: "10%",
                    render: (x) => {
                      if (x.valor_text)
                        return <i className="fas fa-check tx-success"></i>;
                      else return <i className="fas fa-times tx-danger"></i>;
                    },
                  },
                  {
                    title: "Valor +/-",
                    field: "valor_bool",
                    width: "10%",
                    render: (x) => {
                      if (x.valor_bool)
                        return <i className="fas fa-check tx-success"></i>;
                      else return <i className="fas fa-times tx-danger"></i>;
                    },
                  },
                  {
                    title: "Valor Numérico",
                    field: "valor_int",
                    width: "10%",
                    render: (x) => {
                      if (x.valor_int)
                        return <i className="fas fa-check tx-success"></i>;
                      else return <i className="fas fa-times tx-danger"></i>;
                    },
                  },
                  {
                    title: "Acciones",
                    field: "",
                    width: "10%",
                    render: (x) => {
                      return (
                        <div className="btn-group" role="group">
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#edit_consulta_obst"
                            className="btn btn-icon pd-0 wd-20 mg-r-15"
                            onClick={() => {
                              setModalData(x);
                              setIsOpen(true);
                            }}
                          >
                            <i className="typcn typcn-edit tx-gray-500 tx-25-f"></i>
                          </button>
                        </div>
                      );
                    },
                  },
                ]}
              />
            ),
            [body]
          )}
        </div>
      </CardComponent>
    </BasicView>
  );
}
