import React, { useEffect, useState } from "react";
import ColComponent from "../../Templates/ColComponent";
import CardComponent from "../../Templates/CardComponent";
import ContentView from "../../Templates/ContentView";
import Swal from "sweetalert2";
import AntecedentesEmbarazo from "./AntecedentesEmbarazo";
import AntecedentesGeneral from "./AntecedentesGeneral";
import { useForm } from "react-hook-form";
import { config } from "../../../config";
import { useConnection } from "../../../components/Utils/MyHooks";
import ButtonStatus from "../../Templates/ButtonStatus";
import * as services from "../../../services/embarazo_services";
import * as utils from "../../../components/Utils/Utils";
import NavTabs from "../../Templates/NavTabs";
import ContentTab from "../../Templates/ContentTab";
import NewNavBar from "../../../components/NavBar/NewNabBar";
import TabComponent from "../../Templates/TabComponent";

export default function AntecedentesView(props) {
  const antecedentes_form_gral = useForm();
  const antecedentes_form_emb = useForm();
  const get_list_antec = useConnection(true);
  const save = useConnection();
  const [status, setStatus] = useState("");

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  useEffect(() => {
    get_list_antec.get(config.api_antecedentes);
  }, []);

  useEffect(() => {
    let err = null;
    if (save.status === "done") {
      if (save.data) {
        save.data.forEach((element) => {
          if (element.error) err = "Error al guardar antecedentes";
        });
        setStatus("done");
      } else {
        err = save.error.msg;
        setStatus("");
      }

      if (err) error_msg(err);
    }
  }, [save.status]);

  return (
    <ContentView
      error={get_list_antec.error ? [get_list_antec.error.msg] : []}
      loading={get_list_antec.isLoading}
    >
      {/* <div
        className="overflow-y-auto overflow-x-hidden pd-r-5 style-scroll"
        style={{ height: "620px" }}
      > */}
      <TabComponent
        nav={[
          {
            id: "antecedentes_personales",
            nav: "Antecedentes Personales",
            active: true,
            component: (
              <div
                style={{
                  padding: "10px",
                  height: "600px",
                }}
              >
                <AntecedentesGeneral
                  init_data={
                    get_list_antec.data &&
                    get_list_antec.data.filter((x) => {
                      return x.tipo === "P";
                    })
                  }
                  data={props.data_antecedentes}
                  paciente={props.data_paciente}
                  // ref={antecedentes_form_gral.register}
                />
              </div>
            ),
          },
          {
            id: "antecedentes_familiares",
            nav: "Antecedentes Familiares",
            active: false,
            component: (
              <div
                style={{
                  padding: "10px",
                  height: "600px",
                }}
              >
                <AntecedentesGeneral
                  init_data={
                    get_list_antec.data &&
                    get_list_antec.data.filter((x) => {
                      return x.tipo === "F";
                    })
                  }
                  data={props.data_antecedentes}
                  paciente={props.data_paciente}
                  // ref={antecedentes_form_gral.register}
                />
              </div>
            ),
          },
          {
            id: "antecedentes_obst",
            nav: "Antecedentes Obstétricos",
            active: false,
            component: (
              <div style={{ padding: "10px", height: "550px" }}>
                <AntecedentesEmbarazo
                  data={props.data_antecedentes_embarazo}
                  paciente={props.data_paciente}
                />
              </div>
            ),
          },
        ]}
      />
    </ContentView>
  );
}
