import React from "react";

export default function ColComponent(props) {
  let html = [];
  let count = 1;

  if (props.children) {
    html.push(
      <div
        className={
          "col-" +
          (props.sm ? props.sm : "12") +
          " col-md-" +
          (props.md ? props.md : props.xl) +
          " col-lg-" +
          props.lg +
          " col-xl-" +
          props.xl +
          " " +
          props.style
        }
        key={count}
      >
        {props.children}
      </div>
    );
  } else {
    html = <p>Sin Datos</p>;
  }

  return html;
}
