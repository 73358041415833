import React, { useState, useEffect, useMemo } from "react";
import * as utils from "../../../components/Utils/Utils";
import OptionList from "../../Templates/OptionList";

import MyDatePicker from "../../Templates/MyDatePicker.jsx";

const LaboratoriosForm = React.forwardRef((props, ref) => {
  const [selected_lab, setSelectedLab] = useState("");
  const [selected_render, setSelectedRender] = useState(null);
  const [fields, setFields] = useState({});

  useEffect(() => {
    if (props.reset && props.reset.status === "loading") {
      // setSelectedRender(null);
      // setSelectedLab("");
      setFields({});
    }
  }, [props.reset]);

  useEffect(() => {
    if (props.data) {
      setSelectedLab(props.data.laboratorio);
      setFields(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    let tmp = null;
    if (props.data_labs) {
      tmp = props.data_labs.filter((x) => {
        return x.id === Number(selected_lab);
      })[0];
      setSelectedRender(tmp);
      // setFields({});
    }
  }, [selected_lab]);

  return (
    <>
      <div className="form form-row">
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Fecha</label>
          <MyDatePicker
            ref={ref}
            fecha={utils.inputFecha(utils.formatFecha(fields["fecha"]))}
            name={"fecha"}
            disabled={fields["fecha"] !== undefined}
          />
        </div>
        <div className="form-group col-md-4 col-lg-4">
          <label className="az-content-label-sm tx-gray-600">Laboratorio</label>
          <select
            className={
              props.errors["laboratorio"]
                ? "custom-select bd bd-1 bd-danger"
                : "custom-select"
            }
            disabled={fields["laboratorio"] ? true : false}
            name="laboratorio"
            ref={ref({ required: true })}
            value={selected_lab}
            onChange={(e) => {
              setSelectedLab(e.target.value);
            }}
          >
            <OptionList
              opcion_vacia={"-Seleccionar-"}
              data={props.data_labs}
              value_field={(x) => {
                return x.id;
              }}
              name_field={(x) => {
                return x.nombre;
              }}
            />
          </select>
        </div>
        {selected_render && selected_render.valor_bool && (
          <div className="form-group col-md-2 col-lg-2">
            <label className="az-content-label-sm tx-gray-600">Valor +/-</label>
            <select
              className={
                props.errors["valor_bool"]
                  ? "custom-select bd bd-1 bd-danger wd-100p"
                  : "custom-select wd-100p"
              }
              name="valor_bool"
              ref={ref({ required: true })}
              value={fields["valor_bool"] || ""}
              onChange={(e) => {
                let value = e.target.value;
                setFields((prev) => ({ ...prev, valor_bool: value }));
              }}
            >
              <option value={""}></option>
              <option value={true}>+</option>
              <option value={false}>-</option>
            </select>
          </div>
        )}
        {selected_render && selected_render.valor_int && (
          <div className="form-group col-md-2 col-lg-2">
            <label className="az-content-label-sm tx-gray-600">
              Valor Numerico
            </label>
            <input
              type="number"
              className={
                props.errors["valor_int"]
                  ? "form-control bd bd-1 bd-danger"
                  : "form-control"
              }
              name="valor_int"
              ref={ref({ required: true })}
              value={fields["valor_int"] || ""}
              onChange={(e) => {
                let value = e.target.value;
                setFields((prev) => ({ ...prev, valor_int: value }));
              }}
            />
          </div>
        )}
      </div>

      {selected_render && selected_render.valor_text && (
        <div className="form form-row">
          <div className="form-group col-md-7 col-lg-7">
            <label className="az-content-label-sm tx-gray-600">Texto</label>
            <textarea
              className={
                props.errors["valor_text"]
                  ? "form-control bd bd-1 bd-danger"
                  : "form-control"
              }
              name="valor_text"
              ref={ref({ required: true })}
              defaultValue={fields["valor_text"] || ""}
            />
          </div>
        </div>
      )}
    </>
  );
});

export default LaboratoriosForm;
