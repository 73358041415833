import React, { useState, useEffect, useMemo, useContext } from "react";
import Swal from "sweetalert2";

import { config } from "../../config.js";
import { useForm } from "react-hook-form";
import { useConnection } from "../../components/Utils/MyHooks";

import CardComponent from "../Templates/CardComponent.jsx";

import ContentView from "../Templates/ContentView.jsx";

import TestModal from "../Templates/TestModal";
import LaboratoriosTable from "./Laboratorios/LaboratoriosTable";
import LaboratoriosForm from "./Laboratorios/LaboratoriosForm";
import * as services from "../../services/embarazo_services";
import { useWrapper } from "../../components/Authentication/WrapperPermisos";
import { AuthContext } from "../../components/Authentication/AuthComponent";
import ButtonStatus from "../Templates/ButtonStatus.jsx";
import ErrorElement from "../Templates/ErrorElement.jsx";
import DebugErrors from "../Templates/DebugErrors.jsx";

export default function LaboratoriosMainView(props) {
  const [data, setData] = useState([]);
  const labs_data = useConnection();
  const new_lab_con = useConnection();
  const new_lab_form = useForm();

  const update_lab_con = useConnection();
  const update_lab_form = useForm();

  const del_lab_con = useConnection();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modal_data, setModalData] = useState({});

  const wrapper = useWrapper();
  const auth = useContext(AuthContext);

  const [status, setStatus] = useState("");

  const del_msg = (id) =>
    Swal.fire({
      title: "¿Eliminar Laboratorio?",
      icon: "warning",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        del_lab_con.del(config.api_pacientes_laboratorios + id + "/");
      }
    });

  const no_permisos = () =>
    Swal.fire({
      title: "No Tiene Permisos",
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  useEffect(() => {
    labs_data.get(config.api_laboratorios);
  }, []);

  useEffect(() => {
    if (del_lab_con.status === "done") {
      if (!del_lab_con.error) {
        setIsOpen(false);
        let l = data.filter((x) => {
          return x.id !== modal_data.id;
        });
        setData(l);
      } else {
        setIsOpen(false);
        // error_msg(del_lab_con.error.msg);
      }
    }
  }, [del_lab_con.status]);

  useEffect(() => {
    if (labs_data.status === "done") {
      if (labs_data.data) {
        setData(props.data_laboratorios);
      }
    }
  }, [labs_data.status, props.data_laboratorios]);

  useEffect(() => {
    if (new_lab_con.status === "done") {
      if (new_lab_con.data) {
        setStatus("done");
        setIsOpen(false);
        setData((prev) => [...prev, new_lab_con.data]);
      } else {
        setStatus("");
        // error_msg(new_lab_con.error.msg);
        setIsOpen(false);
      }
    }
  }, [new_lab_con.status]);

  useEffect(() => {
    if (update_lab_con.status === "done") {
      if (update_lab_con.data) {
        setIsOpen(false);
        setData((prev) =>
          prev.map((o) => {
            let tmp = update_lab_con.data;
            if (o.id === update_lab_con.data.id) return tmp;
            return o;
          })
        );
      } else {
        setIsOpen(false);
        // error_msg(update_lab_con.error.msg);
      }
    }
  }, [update_lab_con.status]);

  return (
    <ContentView
      error={labs_data.error ? [labs_data.error.msg] : []}
      loading={labs_data.loading === "loading"}
    >
      <TestModal
        title={modal_data.new ? "Crear Laboratorio" : "Editar Laboratorio"}
        isOpen={modalIsOpen}
        aceptar={
          <button
            type="button"
            className="btn btn-az-primary"
            onClick={update_lab_form.handleSubmit((x) => {
              if (!modal_data.new) {
                let form = services.format_laboratorios({
                  ...x,
                  laboratorio: modal_data.laboratorio,
                });
                update_lab_con.patch(
                  config.api_pacientes_laboratorios + modal_data.id + "/",
                  form
                );
              } else {
                let form = services.format_laboratorios({
                  ...x,
                  laboratorio: modal_data.laboratorio,
                  paciente: props.data_paciente.id,
                  medico: auth.getSessionData().id,
                });
                new_lab_con.post(config.api_pacientes_laboratorios, form);
              }
            })}
          >
            <i className="far fa-save tx-15 mg-r-5"></i>{" "}
            {modal_data.new ? "Crear" : "Actualizar"}
          </button>
        }
        cancelar={
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </button>
        }
        eliminar={
          !modal_data.new && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setIsOpen(false);
                del_msg(modal_data.id);
              }}
            >
              <i className="far fa-trash-alt tx-15 mg-r-5"></i>Eliminar
            </button>
          )
        }
      >
        <LaboratoriosForm
          ref={update_lab_form.register}
          errors={update_lab_form.errors}
          data_labs={labs_data.data}
          data={modal_data}
        />
      </TestModal>
      <CardComponent>
        <div className="form form-row">
          <div className="az-content-label tx-12 mg-b-5">
            Carga de Laboratorio
          </div>
          <div className="form-group col-md-9 col-lg-9">
            <LaboratoriosForm
              reset={new_lab_con}
              ref={new_lab_form.register}
              errors={new_lab_form.errors}
              data_labs={labs_data.data}
            />
          </div>
          <div className="form-group col-md-3 col-lg-3">
            <ButtonStatus
              clase={"btn btn-az-primary mg-t-10 float-right"}
              status={status}
              txt_success={"Guardado!"}
              txt_loading="Guardando.."
              onClick={new_lab_form.handleSubmit((x) => {
                setStatus("loading");
                let form = services.format_laboratorios({
                  ...x,
                  laboratorio: x.laboratorio,
                  paciente: props.data_paciente.id,
                  medico: auth.getSessionData().id,
                });
                new_lab_con.post(config.api_pacientes_laboratorios, form);
              })}
            >
              <i className="fas fa-flask tx-13 mg-r-5"></i> Cargar Laboratorio
            </ButtonStatus>
          </div>
        </div>
      </CardComponent>
      {config.debug && (
        <DebugErrors watch={[new_lab_con, update_lab_con, del_lab_con]} />
      )}
      <CardComponent style={"mg-t-10"}>
        <div className="az-content-label tx-12 mg-b-5">
          Laboratorios Cargados
        </div>
        <div className={"mg-t-10"}>
          <LaboratoriosTable
            data={data}
            laboratorios_base={labs_data.data}
            edit={(x) => {
              if (wrapper.checkPermisos([x.medico], ["propietario"])) {
                setModalData(x);
                setIsOpen(true);
              } else {
                no_permisos();
              }
            }}
            new={(x, y) => {
              if (y[0]) {
                setModalData({
                  fecha: x,
                  laboratorio: y[0].laboratorio,
                  new: true,
                });
                setIsOpen(true);
              }
            }}
          />
        </div>
      </CardComponent>
    </ContentView>
  );
}
