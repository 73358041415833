import React, { useState, useEffect, useContext } from "react";

import Swal from "sweetalert2";
import ButtonStatus from "../../../Templates/ButtonStatus";
import NewConsultaEmbarazo from "./NewConsultaEmbarazo";
import DatosEmbarazoActual from "./DatosEmbarazoActual";
import CardComponent from "../../../Templates/CardComponent";
import { useForm } from "react-hook-form";
import { useConnection } from "../../../../components/Utils/MyHooks";
import * as services from "../../../../services/embarazo_services";
import { config } from "../../../../config";
import * as utils from "../../../../components/Utils/Utils";
import { AuthContext } from "../../../../components/Authentication/AuthComponent";

export default function NewEmbarazo(props) {
  const [status, setStatus] = useState("");
  const [error, setError] = useState(null);
  const form_embarazo = useForm();
  const form_consulta = useForm();

  const new_embarazo = useConnection();
  const new_resto = useConnection();

  const auth = useContext(AuthContext);

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  useEffect(() => {
    if (new_embarazo.status === "done") {
      if (new_embarazo.data) {
        let x = form_consulta.getValues();
        let body_consulta = services.format_consulta_embarazo({
          ...x,
          embarazo: new_embarazo.data.id,
          medico: auth.getSessionData().id,
        });

        new_resto.post(config.api_consultas_embarazos, body_consulta);
      } else {
        setStatus("error");
        error_msg(new_embarazo.error.msg);
      }
    }
  }, [new_embarazo.status]);

  useEffect(() => {
    if (new_resto.status === "done") {
      if (new_resto.data) {
        // setStatus('done');
        props.callback(new_embarazo.data);
      } else {
        setStatus("error");
        error_msg(new_resto.error.msg);
      }
    }
  }, [new_resto.status]);

  return (
    <div className="mg-t-10">
      <div
        className="scroll"
        style={{
          padding: "10px",
          height: "585px",
          overflowY: "auto",
          borderTop: "1px solid #cdd4e0",
        }}
      >
        <CardComponent header={""}>
          <DatosEmbarazoActual
            ref={form_embarazo.register}
            errors={form_embarazo.errors}
            new={true}
          />
        </CardComponent>
        <CardComponent style={"mg-t-10"} header={""}>
          <NewConsultaEmbarazo
            errors={form_consulta.errors}
            ref={form_consulta.register}
          />
        </CardComponent>
      </div>
      <div style={{ borderTop: "1px solid #cdd4e0" }}>
        <ButtonStatus
          clase={"btn btn-az-primary mg-t-10 float-right"}
          status={status}
          // txt_error={error ? "Error " + error : null}
          txt_success={"Guardado!"}
          txt_loading="Guardando.."
          onClick={async () => {
            let emb = await form_embarazo.trigger();
            let con = await form_consulta.trigger();

            if (emb && con) {
              let x = form_embarazo.getValues();
              let body = services.format_data_embarazo({
                ...x,
                paciente: props.paciente.id,
              });

              setStatus("loading");
              setError(null);

              if (new_embarazo.data)
                //En el caso de que ocurra algun error y no se cambie la vista, este boton no deberia postear
                new_embarazo.patch(
                  config.api_embarazos + new_embarazo.data.id + "/",
                  body
                );
              else new_embarazo.post(config.api_embarazos, body);
            }
          }}
        >
          <i className="far fa-save tx-15 mg-r-5"></i>Guardar
        </ButtonStatus>
      </div>
    </div>
  );
}
