import React, { useContext, useEffect, useState } from "react";
import BasicView from "../Templates/BasicView";
import CardComponent from "../Templates/CardComponent";
import ContentView from "../Templates/ContentView";
import { MenuContext } from "../../components/Utils/MenuContext";
import { useConnection } from "../../components/Utils/MyHooks";
import { config } from "../../config";
import TableEditableComponent from "../Templates/TableEditableComponent";
import * as utils from "../../components/Utils/Utils";
import Options from "../Templates/OptionList";

const selectYears = () => {
  let date_start = 2019;
  let date_end = new Date().getFullYear();
  let array = [];
  for (let index = date_start; index <= date_end; index++) {
    array.push(index);
  }
  return array.reverse();
};

export default function EmbarazosListView(props) {
  const data_search = useConnection(true);
  const menu = useContext(MenuContext);
  const [body, setBody] = useState([]);
  const [month_sel, setMonthSel] = useState("");
  const [year_sel, setYearSel] = useState("");
  const [estado_sel, setEstadoSel] = useState("");

  const filterBody = () => {
    if (!data_search.data) return;
    if (estado_sel !== "") {
      let tmp = data_search.data.filter((e) => {
        return e.estado === estado_sel;
      });
      setBody(tmp);
    } else {
      setBody(data_search.data);
    }
  };

  useEffect(() => {
    data_search.get(config.api_embarazos_month);
  }, []);

  useEffect(() => {
    if (data_search.status === "done") if (data_search.data) filterBody();
  }, [data_search.status]);

  useEffect(() => {
    if (year_sel !== "") {
      data_search.get(config.api_embarazos_month + year_sel);
    }
  }, [year_sel]);

  useEffect(() => {
    if (month_sel !== "") {
      data_search.get(config.api_embarazos_month + year_sel + "/" + month_sel);
    }
  }, [month_sel]);

  useEffect(() => {
    filterBody();
  }, [estado_sel]);

  return (
    <BasicView
      title={""}
      error={[]}
      loading={false}
      nav={[
        {
          id: "home",
          nav: "Inicio",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("home")}
            >
              <i className="typcn typcn-home-outline"></i> Inicio
            </a>
          ),
        },
        {
          id: "pacientes_list",
          nav: "Listado Pacientes",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("pacientes-list")}
            >
              <i className="far fa-address-book mg-r-5 tx-16"></i> Listado
              Pacientes
            </a>
          ),
        },
        {
          id: "embarazos_list",
          nav: "Listado Embarazos",
          active: true,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("embarazos-list")}
            >
              <i className="far fa-list-alt mg-r-5 tx-16"></i> Listado Embarazos
            </a>
          ),
        },
      ]}
    >
      <CardComponent style={"ht-725"}>
        <div className="form form-row">
          <div className="form-group col-md-2">
            <label className="az-content-label-sm tx-gray-600">Año</label>
            <select
              className="custom-select wd-100p"
              name="anio"
              value={year_sel}
              onChange={(e) => {
                setYearSel(e.target.value);
              }}
            >
              <option value="">Historico</option>
              {selectYears().map((e) => {
                return (
                  <option key={e} value={e}>
                    {e}
                  </option>
                );
              })}
            </select>
          </div>
          {year_sel !== "" && (
            <>
              <div className="form-group col-md-2">
                <label className="az-content-label-sm tx-gray-600">Mes</label>
                <select
                  className="custom-select wd-100p"
                  name="mes"
                  value={month_sel}
                  onChange={(e) => {
                    setMonthSel(e.target.value);
                  }}
                >
                  <option value="">Historico</option>
                  {[...Array(12).keys()].map((e) => {
                    return (
                      <option key={e} value={e + 1}>
                        {utils.meses_txt[e]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </>
          )}
          <div className="form-group col-md-2">
            <label className="az-content-label-sm tx-gray-600">Estado</label>
            <select
              className="custom-select wd-100p"
              name="mes"
              value={estado_sel}
              onChange={(e) => {
                setEstadoSel(e.target.value);
              }}
            >
              <Options
                opcion_vacia={"Todos"}
                data={utils.estados_embarazo}
                value_field={(x) => {
                  return x.id;
                }}
                name_field={(x) => {
                  return x.nombre;
                }}
              />
            </select>
          </div>
        </div>
        <ContentView
          error={data_search.error ? [data_search.error.msg] : []}
          loading={data_search.isLoading}
        >
          <div style={{ marginTop: "-20px" }}>
            <TableEditableComponent
              no_toolbar={false}
              sorting={true}
              search={true}
              callback_row={(x) => {
                console.log(x);
                menu.setView("pacientes", x.id_paciente);
              }}
              print_button={false}
              download_button={false}
              paging={true}
              no_editable={true}
              max_items={8}
              data={body}
              header={[
                {
                  title: "Apellido",
                  field: "apellido",
                  // width: "5%",
                  defaultSort: "desc",
                  render: (x) => {
                    return x.apellido;
                  },
                },
                {
                  title: "Nombres",
                  field: "nombres",
                  // width: "5%",
                  defaultSort: "desc",
                  render: (x) => {
                    return x.nombres;
                  },
                },
                {
                  title: "Estado",
                  field: "estado",
                  // width: "5%",
                  defaultSort: "desc",
                  render: (x) => {
                    if (x.estado === "P")
                      return (
                        <span className="badge badge-primary">En Proceso</span>
                      );
                    if (x.estado === "F")
                      return (
                        <span className="badge badge-info">Finalizado</span>
                      );
                    if (x.estado === "A")
                      return <span className="badge badge-success">Alta</span>;
                    return (
                      <span className="badge badge-danger">No Continua</span>
                    );
                  },
                },
                {
                  title: "FPP",
                  field: "fpp",
                  // width: "5%",
                  defaultSort: "desc",
                  render: (x) => {
                    return utils.displayFecha(x.fpp);
                  },
                },
                {
                  title: "Terminación",
                  field: "fin_parto_fecha",
                  // width: "5%",
                  defaultSort: "desc",
                  render: (x) => {
                    if (x) return utils.displayFecha(x.fin_parto_fecha);
                    else return "-";
                  },
                },
                {
                  title: "Forma Terminación",
                  field: "fin_parto_tipo",
                  // width: "5%",
                  defaultSort: "desc",
                  render: (x) => {
                    if (x.fin_parto_tipo)
                      return utils.terminaciones_embarazo.filter((e) => {
                        return e.id === x.fin_parto_tipo;
                      })[0].nombre;
                    else return "-";
                  },
                },
              ]}
            />
          </div>
        </ContentView>
      </CardComponent>
    </BasicView>
  );
}
