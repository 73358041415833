import React, { useState, useEffect, useMemo, useContext } from "react";
import Swal from "sweetalert2";

import { config } from "../config.js";
import { useForm } from "react-hook-form";
import { useConnection } from "../components/Utils/MyHooks";
import CardComponent from "./Templates/CardComponent.jsx";
import TestModal from "./Templates/TestModal";
import TableEditableComponent from "./Templates/TableEditableComponent";
import BasicView from "./Templates/BasicView";
import * as services from "../services/embarazo_services";
import * as utils from "../components/Utils/Utils";
import { MenuContext } from "../components/Utils/MenuContext";
import { AuthContext } from "../components/Authentication/AuthComponent";
import Switch from "./Templates/Switch.jsx";
import ButtonStatus from "./Templates/ButtonStatus.jsx";

const FormUsuario = React.forwardRef((props, ref) => {
  const [fields, setFields] = useState({});
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (props.data) setFields(props.data);
  }, [props.data]);

  return (
    <>
      <div className="form form-row">
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Usuario</label>
          <input
            readOnly={!auth.getSessionData().is_staff}
            type="text"
            className={"form-control"}
            name="username"
            ref={ref}
            defaultValue={fields["username"]}
          />
        </div>
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Nombres</label>
          <input
            type="text"
            className={
              props.errors["first_name"]
                ? "form-control bd bd-1 bd-danger"
                : "form-control"
            }
            style={{ textTransform: "capitalize" }}
            name="first_name"
            ref={ref({ required: true })}
            defaultValue={fields["first_name"]}
          />
        </div>
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Apellido</label>
          <input
            type="text"
            className={
              props.errors["last_name"]
                ? "form-control bd bd-1 bd-danger"
                : "form-control"
            }
            style={{ textTransform: "capitalize" }}
            name="last_name"
            ref={ref({ required: true })}
            defaultValue={fields["last_name"]}
          />
        </div>
      </div>
      <div className="form form-row">
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Email</label>
          <input
            type="email"
            className={
              props.errors["email"]
                ? "form-control bd bd-1 bd-danger"
                : "form-control"
            }
            name="email"
            ref={ref}
            defaultValue={fields["email"]}
          />
        </div>
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">
            Especialidad
          </label>
          <input
            type="text"
            className={"form-control"}
            name="especialidad"
            style={{ textTransform: "capitalize" }}
            ref={ref}
            defaultValue={fields["especialidad"]}
          />
        </div>
        <div className="form-group col-md-4 col-lg-4">
          <label className="az-content-label-sm tx-gray-600">Activo</label>
          <Switch
            name={"is_active"}
            ref={ref}
            defaultValue={fields["is_active"]}
          />
        </div>
      </div>
    </>
  );
});

export default function AdminColaboradoresView(props) {
  const [body, setBody] = useState([]);
  const menu = useContext(MenuContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modal_data, setModalData] = useState({});

  const user_conn_new = useConnection();
  const user_conn_update = useConnection();
  const users_init = useConnection(true);

  const user_form_new = useForm();
  const user_form_update = useForm();

  const [last_id, setLastId] = useState(0);

  const auth = useContext(AuthContext);
  const reset = useConnection();
  const [status, setStatus] = useState("");

  useEffect(() => {
    users_init.get(config.api_users);
  }, []);

  useEffect(() => {
    let id = 0;
    let body = [];
    if (users_init.status === "done") {
      if (users_init.data) {
        let group = auth.getGroupData().filter((x) => {
          return x.name === "colaboradores";
        });

        users_init.data.forEach((element) => {
          if (element.id >= id) id = element.id;

          if (auth.getSessionData().is_staff) {
            body.push(element);
          } else {
            if (!element.is_staff && element.groups.includes(group[0].id))
              body.push(element);
          }
        });
        setBody(body);
        setLastId(id);
      }
    }
  }, [users_init.status]);

  useEffect(() => {
    if (user_conn_new.status === "done") {
      if (user_conn_new.data) {
        setBody((prev) => [user_conn_new.data, ...prev]);
        setLastId(last_id + 1);
        setIsOpen(false);
        setStatus("done");
      } else {
        error_msg(user_conn_new.error.msg);
        setStatus("error");
      }
    }
  }, [user_conn_new.status]);

  useEffect(() => {
    if (user_conn_update.status === "done") {
      if (user_conn_update.data) {
        setBody((prev) =>
          prev.map((o) => {
            let tmp = user_conn_update.data;
            if (o.id === user_conn_update.data.id) return tmp;
            return o;
          })
        );
        setIsOpen(false);
      } else {
        setIsOpen(false);
        error_msg(user_conn_update.error.msg);
      }
    }
  }, [user_conn_update.status]);

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  const succ_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "success",
      confirmButtonText: "Aceptar",
    });

  const reset_passwd = (data) =>
    Swal.fire({
      title: "¿Resetear Contraseña de Usuario?",
      icon: "warning",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((x) => {
      if (x.isConfirmed) {
        let f = new FormData();
        f.append("password", data.username);
        reset.patch(config.api_users + data.id + "/", f);
      }
    });

  useEffect(() => {
    if (reset.status === "done") {
      if (!reset.error) {
        succ_msg("Contraseña Reseteada con Exito");
      } else {
        error_msg(reset.error.msg);
      }
    }
  }, [reset.status]);

  return (
    <BasicView
      title={""}
      error={users_init.error ? [users_init.error.msg] : []}
      loading={users_init.isLoading}
      nav={[
        {
          id: "home",
          nav: "Inicio",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("home")}
            >
              <i className="typcn typcn-home-outline"></i> Inicio
            </a>
          ),
        },
        {
          id: "pacientes_list",
          nav: "Listado Pacientes",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("pacientes-list")}
            >
              <i className="far fa-address-book mg-r-5 tx-16"></i> Listado
              Pacientes
            </a>
          ),
        },
        {
          id: "embarazos_list",
          nav: "Listado Embarazos",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("embarazos-list")}
            >
              <i className="far fa-list-alt mg-r-5 tx-16"></i> Listado Embarazos
            </a>
          ),
        },
      ]}
    >
      <CardComponent>
        <div className="az-content-label tx-12 mg-b-5">
          Agregar Nuevo Colaborador
        </div>
        <div className="form form-row mg-t-10">
          <div className="form-group col-md-8 col-lg-8">
            <FormUsuario
              key={status === "done" ? 1 : 0}
              ref={user_form_new.register}
              errors={user_form_new.errors}
              data={{ username: "user" + last_id }}
            />
          </div>
          <div className="form-group col-md-3 col-lg-3">
            <ButtonStatus
              clase={"btn btn-az-primary mg-t-10 float-right"}
              status={status}
              // txt_error={
              //   user_conn_new.error ? "Error " + user_conn_new.error.code : null
              // }
              txt_success={"Guardado!"}
              txt_loading="Guardando.."
              onClick={user_form_new.handleSubmit((x) => {
                let form = services.format_abm_usuario({
                  ...x,
                  password: x.username,
                  groups: [
                    auth.getGroupData().filter((x) => {
                      return x.name === "colaboradores";
                    })[0].id,
                  ],
                });
                setStatus("loading");
                user_conn_new.post(config.api_users, form);
              })}
            >
              <i className="far fa-user tx-15 mg-r-5"></i>
              Crear Usuario
            </ButtonStatus>
          </div>
        </div>
      </CardComponent>

      <TestModal
        title={"Editar Laboratorio"}
        isOpen={modalIsOpen}
        aceptar={
          <button
            type="button"
            className="btn btn-az-primary"
            onClick={user_form_update.handleSubmit((x) => {
              let form = services.format_abm_usuario(x);
              user_conn_update.patch(
                config.api_users + modal_data.id + "/",
                form
              );
            })}
          >
            <i className="far fa-save tx-15 mg-r-5"></i>
            Actualizar
          </button>
        }
        cancelar={
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </button>
        }
      >
        <FormUsuario
          ref={user_form_update.register}
          errors={user_form_update.errors}
          data={modal_data}
        />
      </TestModal>
      <CardComponent style={"mg-t-10"}>
        <div className="az-content-label tx-12 mg-b-5">
          Listado de Colaboradores
        </div>
        <div style={{ height: 425 }} className="scroll mg-t-10">
          {useMemo(
            () => (
              <TableEditableComponent
                no_toolbar={true}
                sorting={true}
                print_button={false}
                download_button={false}
                paging={true}
                no_editable={true}
                max_items={5}
                data={body}
                header={[
                  {
                    title: "Usuario",
                    field: "id",
                    width: "5%",
                    defaultSort: "desc",
                    render: (x) => {
                      return x.username;
                    },
                  },
                  {
                    title: "Apellido",
                    field: "last_name",
                    width: "10%",
                    render: (x) => {
                      return x.first_name;
                    },
                  },
                  {
                    title: "Nombres",
                    field: "first_name",
                    width: "10%",
                    render: (x) => {
                      return x.last_name;
                    },
                  },
                  {
                    title: "Activo",
                    field: "is_active",
                    width: "10%",
                    render: (x) => {
                      if (x.is_active)
                        return <i className="fas fa-check tx-success"></i>;
                      else return <i className="fas fa-times tx-danger"></i>;
                    },
                  },
                  {
                    title: "Acciones",
                    field: "",
                    width: "10%",
                    render: (x) => {
                      return (
                        <div className="btn-group" role="group">
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#edit_consulta_obst"
                            className="btn btn-icon pd-0 wd-20 mg-r-15"
                            onClick={() => {
                              setModalData(x);
                              setIsOpen(true);
                            }}
                          >
                            <i className="typcn typcn-edit tx-gray-500 tx-25-f"></i>
                          </button>
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#edit_consulta_obst"
                            className="btn btn-icon pd-0 wd-20 mg-r-15"
                            onClick={() => {
                              reset_passwd(x);
                              // setModalData(x);
                              // setIsOpen(true);
                            }}
                          >
                            <i className="typcn typcn-arrow-sync tx-gray-500 tx-25-f"></i>
                          </button>
                        </div>
                      );
                    },
                  },
                ]}
              />
            ),
            [body]
          )}
        </div>
      </CardComponent>
    </BasicView>
  );
}
