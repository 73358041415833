import React, { useContext, useEffect, useState } from "react";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import BasicView from "../Templates/BasicView";
import CardComponent from "../Templates/CardComponent";

import OptionList from "../Templates/OptionList";
import { MenuContext } from "../../components/Utils/MenuContext";
import { useConnection, useGetList } from "../../components/Utils/MyHooks";
import MyDatePicker from "../Templates/MyDatePicker";
import { config } from "../../config";
import * as utils from "../../components/Utils/Utils";
import * as services from "../../services/embarazo_services";
import InputElement from "react-input-mask";
import ButtonStatus from "../Templates/ButtonStatus";
import { useForm } from "react-hook-form";

export default function PacientesABMDatos(props) {
  const menu = useContext(MenuContext);
  const [fields, setFields] = useState([]);
  const [status, setStatus] = useState("");
  // const [update_status, setUpdateStatus] = useState("");
  // const [update_error, setUpdateError] = useState(null);

  const conn = useConnection();
  const save = useConnection();

  const form = useForm();

  useEffect(() => {
    if (props.arg) {
      conn.get(config.api_pacientes + props.arg + "/");
    }
  }, [props.arg]);

  useEffect(() => {
    if (conn.status === "done") {
      if (conn.data) {
        setFields(conn.data);
      }
    }
  }, [conn.status]);

  useEffect(() => {
    if (save.status === "done") {
      if (save.error) {
        setStatus("");
        error_msg(save.error.msg);
      } else {
        setStatus("done");
        if (props.arg) {
        } else {
          succ_msg("Paciente creado!", save.data.id);
        }
      }
    }
  }, [save.status]);

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  const succ_msg = (msg, id) =>
    Swal.fire({
      title: msg,
      icon: "success",
      confirmButtonText: "Aceptar",
    }).then(() => {
      menu.setView("pacientes", id);
    });

  return (
    <BasicView
      title={""}
      error={conn.error ? [conn.error.msg] : []}
      loading={conn.status === "loading"}
      nav={[
        {
          id: "home",
          nav: "Inicio",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("home")}
            >
              <i className="typcn typcn-home-outline"></i> Inicio
            </a>
          ),
        },
      ]}
    >
      <CardComponent style={"mg-b-10"}>
        <>
          <div className="az-content-label mg-b-10 tx-15">Datos Paciente</div>
          <div className="form form-row">
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Apellido
              </label>
              <input
                className={
                  "form-control ht-30 " +
                  (form.errors["apellido"] ? "parsley-error" : "")
                }
                style={{ textTransform: "capitalize" }}
                name="apellido"
                ref={form.register({ required: true })}
                defaultValue={fields["apellido"]}
              />
            </div>
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">Nombres</label>
              <input
                className={
                  "form-control ht-30 " +
                  (form.errors["nombres"] ? "parsley-error" : "")
                }
                style={{ textTransform: "capitalize" }}
                name="nombres"
                ref={form.register({ required: true })}
                defaultValue={fields["nombres"]}
              />
            </div>
            <div className="form-group offset-md-1 col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Fecha Nacimiento
              </label>
              <div
                style={{ width: "max-content" }}
                className={
                  form.errors["fecha_nacimiento"] ? "bd bd-1 bd-danger" : ""
                }
              >
                <MyDatePicker
                  name={"fecha_nacimiento"}
                  ref={form.register({ required: true })}
                  fecha={fields["fecha_nacimiento"]}
                />
              </div>
            </div>
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Documento
              </label>
              <input
                className={
                  "form-control ht-30 " +
                  (form.errors["apellido"] ? "parsley-error" : "")
                }
                name="documento"
                ref={form.register({ required: true })}
                defaultValue={fields["documento"]}
              />
            </div>
            <div className="form-group offset-md-1 col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Grupo Sanguineo
              </label>
              <select
                className="wd-50p custom-select"
                name="grupo_sanguineo"
                ref={form.register}
                value={fields["grupo_sanguineo"] || ""}
                onChange={(event) => {
                  let e = event.target.value;
                  setFields((fields) => ({
                    ...fields,
                    grupo_sanguineo: e,
                  }));
                }}
              >
                <OptionList
                  opcion_vacia={"-"}
                  data={utils.grupo_sanguineo}
                  name_field={(x) => {
                    return x.nombre;
                  }}
                  value_field={(x) => {
                    return x.id;
                  }}
                />
              </select>
            </div>
          </div>
          <div className="form form-row mg-t-10">
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Domicilio
              </label>
              <input
                style={{ textTransform: "capitalize" }}
                className="form-control"
                name="domicilio"
                ref={form.register}
                defaultValue={fields["domicilio"]}
              />
            </div>
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Localidad
              </label>
              <input
                style={{ textTransform: "capitalize" }}
                className="form-control"
                name="localidad"
                ref={form.register}
                defaultValue={fields["localidad"]}
              />
            </div>
            <div className="form-group offset-md-1 col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Telefono Primario
              </label>
              <InputElement
                name={"telefono1"}
                className={
                  "form-control ht-30 " +
                  (form.errors["telefono1"] ? "parsley-error" : "")
                }
                mask="(9999) 999-9999"
                inputRef={form.register({ required: true })}
                value={fields["telefono1"] || ""}
                onChange={(e) => {
                  let value = e.target.value;
                  setFields((fields) => ({
                    ...fields,
                    telefono1: value,
                  }));
                }}
              />
            </div>
            <div className="form-group offset-md-1 col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Telefono Secundario
              </label>
              <InputElement
                name={"telefono2"}
                className="form-control"
                mask="(9999) 999-9999"
                inputRef={form.register}
                value={fields["telefono2"] || ""}
                onChange={(e) => {
                  let value = e.target.value;
                  setFields((fields) => ({
                    ...fields,
                    telefono2: value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="form form-row mg-t-10">
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Obra Social
              </label>
              <input
                className="form-control"
                name="obra_social"
                ref={form.register}
                defaultValue={fields["obra_social"]}
              />
            </div>
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Obra Social Nro
              </label>
              <input
                className="form-control"
                name="obra_social_num"
                ref={form.register}
                defaultValue={fields["obra_social_num"]}
              />
            </div>
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Obra Social Token
              </label>
              <input
                className="form-control"
                name="obra_social_token"
                ref={form.register}
                defaultValue={fields["obra_social_token"]}
              />
            </div>
          </div>
          <div className="form form-row">
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Profesion
              </label>
              <input
                style={{ textTransform: "capitalize" }}
                className="form-control"
                name="profesion"
                ref={form.register}
                defaultValue={fields["profesion"]}
              />
            </div>
          </div>
          {!props.arg && (
            <button
              type="button"
              className="btn btn-az-primary mg-t-10 float-right"
              onClick={form.handleSubmit((x) => {
                setStatus("loading");
                let tmp = services.format_pacientes(x);
                save.post(config.api_pacientes, tmp);
              })}
            >
              <i className="far fa-save tx-15 mg-r-5"></i>Crear Paciente
            </button>
          )}
          {props.arg && (
            <ButtonStatus
              clase={"btn btn-az-primary mg-t-10 float-right"}
              status={status}
              // txt_error={save.error ? "Error " + save.error.code : null}
              txt_success={"Guardado!"}
              txt_loading="Guardando.."
              onClick={form.handleSubmit((x) => {
                const tmp = services.format_pacientes(x);
                setStatus("loading");
                if (props.arg) {
                  save.put(config.api_pacientes + props.arg + "/", tmp);
                } else {
                  save.post(config.api_pacientes, tmp);
                }
              })}
            >
              {props.arg && (
                <>
                  <i className="far fa-save tx-15 mg-r-5"></i>Actualizar
                </>
              )}
              {!props.arg && (
                <>
                  <i className="far fa-save tx-15 mg-r-5"></i>Crear Paciente
                </>
              )}
            </ButtonStatus>
          )}
          <button
            type="button"
            className="btn btn-az-primary mg-t-10 mg-r-10 float-right"
            onClick={() => {
              if (props.arg) menu.setView("pacientes", props.arg);
              else menu.setView("home");
            }}
          >
            Volver
          </button>
        </>
      </CardComponent>
    </BasicView>
  );
}
