import React, { useEffect, useState } from "react";
import Spinner from "./Spinner.jsx";
import ErrorElement from "./ErrorElement";
import { useTransition, animated } from "react-spring";

export default function ContentView(props) {
  let body = [];
  let count = 0;

  useEffect(() => {
    return () => {
      set(false);
    };
  }, []);

  const [show, set] = useState(true);
  const transitions = useTransition(show, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  // let condition = props.condition === undefined ? true : props.condition;

  if (props.loading) return <Spinner />;

  props.error &&
    props.error.forEach((element) => {
      if (element) {
        body.push(<ErrorElement key={count++} text={element} />);
      }
    });

  if (body.length === 0) {
    if (props.children) body = props.children;
    else body = <p>Atención: No hay componente</p>;
  }

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props}>
          {body}
        </animated.div>
      )
  );
  // return body;
}
