import React, { useState, useEffect, useContext } from "react";
import { config } from "../../config";
import { AuthContext } from "../Authentication/AuthComponent";

class MyError extends Error {
  constructor(message, object) {
    super(message);
    this.message = message;
    this.name = "MyError";
    this.object = object;
  }
}

const useConnection = (initLoading = false) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(initLoading);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("");
  const auth = useContext(AuthContext);

  const [_url, setUrl] = useState("");
  const [_body, setBody] = useState({});
  const [_method, setMethod] = useState("");

  const fetchData = (url, method, body) => {
    // const isLoggedIn = auth ? auth.checkLoginStatus() : false;
    const token = auth ? auth.getToken() : null;

    let headers = {};

    if (token) {
      headers = {
        Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: "Token " + token,
      };
    } else {
      headers = {
        Accept: "application/json",
        // "Content-Type": "application/json",
      };
    }

    // console.log(
    //   "Llamado ",
    //   method,
    //   " a fetchData de: ",
    //   url,
    //   "header: ",
    //   headers,
    //   " con datos: ",
    //   body
    // );

    setLoading(true);
    setStatus("loading");

    setError(null);
    setData(null);

    if (
      _url === url &&
      _body &&
      body &&
      JSON.stringify([..._body.entries()]) ===
        JSON.stringify([...body.entries()]) &&
      _method === method
    ) {
      setLoading(false);
      setStatus("done");
      return;
    } else {
      setBody(body);
      setUrl(url);
      setMethod(method);
    }

    let url_txt = url.split(config.server)[1];
    let options = {
      method: method,
      headers: headers,
    };

    if (body) {
      options.body = body;
    }

    fetch(url, options)
      .then((response) => {
        return response.text().then((x) => {
          try {
            let data = JSON.parse(x);
            return { status: response.status, data: data };
            // Do your JSON handling here
          } catch (err) {
            return { status: response.status, data: x };
            // It is text, do you text handling here
          }
        });
      })
      .then((r) => {
        if (r.status < 300) {
          setData(r.data);
        } else {
          let msg;
          if (r.status === 400) {
            msg = "Solicitud Incorrecta: " + url_txt;
          } else if (r.status === 401) {
            msg = "No autorizado: " + url_txt;
          } else if (r.status === 404) {
            msg = "No encontrado: " + url_txt;
          } else {
            msg = "Error Desconocido: " + url_txt;
          }
          console.log(r.data);
          setError({ code: r.status, msg: msg, obj: r.data });
        }
      })
      .catch((er) => {
        console.log(er);
        setError({
          code: 0,
          msg: "Error de Conexión: " + url_txt,
          obj: er,
        });
      })
      .finally(() => {
        setLoading(false);
        setStatus("done");
      });
  };

  const get = (url) => {
    fetchData(url, "GET", null);
  };

  const options = (url) => {
    fetchData(url, "OPTIONS", null);
  };

  const post = (url, body) => {
    fetchData(url, "POST", body);
  };

  const put = (url, body) => {
    fetchData(url, "PUT", body);
  };

  const patch = (url, body) => {
    fetchData(url, "PATCH", body);
  };

  const del = (url) => {
    fetchData(url, "DELETE", null);
  };

  // const list_ = (list) => {
  //   Promise.all(list.map((e) => fetchData(e.url, e.method, e.body)));
  // };

  const list = (list) => {
    // const isLoggedIn = auth ? auth.checkLoginStatus() : false;
    const token = auth ? auth.getToken() : null;
    let headers = {};

    if (token) {
      headers = {
        Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: "Token " + token,
      };
    } else {
      headers = {
        Accept: "application/json",
        // "Content-Type": "application/json",
      };
    }

    setStatus("loading");
    setLoading(true);
    Promise.all(
      list.map((e) => {
        let options = {
          method: e.method,
          headers: headers,
        };

        if (e.body && e.method !== "GET") {
          options.body = e.body;
        }
        return fetch(e.url, options);
      })
    )
      .then((response) =>
        Promise.all(
          response.map((r) => {
            if (r.status < 300)
              return r.json().then((x) => ({ status: r.status, data: x }));
            else {
              return r.text().then((x) => ({ status: r.status, data: x }));
            }
          })
        ).then((r) => {
          let result = [];
          r.forEach((element) => {
            let d = null;
            let e = null;
            if (element.status < 300) {
              d = element.data;
            } else {
              e = { code: element.status, msg: "Error", obj: element.data };
            }
            result.push({ data: d, error: e });
          });
          setData(result);
        })
      )
      .catch((er) => {
        setError({ code: 0, msg: "Error de Conexion", obj: er.message });
      })
      .finally(() => {
        setStatus("done");
        setLoading(false);
      });
  };

  return {
    data,
    isLoading,
    error,
    status,
    get,
    options,
    post,
    patch,
    put,
    del,
    list,
  };
};

const useCommons = (commons, url_commons) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const commons_ = useConnection();

  useEffect(() => {
    if (!sessionStorage.getItem(commons)) {
      commons_.get(url_commons);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (commons_.status === "done") {
      if (commons_.data) {
        sessionStorage.setItem(commons, JSON.stringify(commons_.data));
        setLoading(false);
      }

      if (commons_.error) {
        setError("Error obteniendo " + commons);
        setLoading(false);
      }
    }
  }, [commons_.status]);

  return { error, isLoading };
};

export { useCommons, useConnection };
