import { config } from "../config";
import * as utils from "../components/Utils/Utils";
const defaultValue = (val, def) => {
  if (val == undefined) {
    return def;
  } else {
    return val;
  }
};

export const data_embarazo = {
  activo: (x) => defaultValue(x, true),
  estado: (x) => defaultValue(x, "P"),
  nombre_bebe: (x) => utils.toTitleCase(utils.formatString(x)),
  fertilidad: (x) => defaultValue(x, "E"),
  tipo_tratamiento: (x) => defaultValue(x, ""),
  fecha_tratamiento: (x) => utils.inputFecha(x),
  conyuge_nombres: (x) => utils.toTitleCase(utils.formatString(x)),
  conyuge_apellido: (x) => utils.toTitleCase(utils.formatString(x)),
  conyuge_edad: (x) => defaultValue(x, ""),
  conyuge_grupo_sanguineo: (x) => defaultValue(x, ""),
  conyuge_profesion: (x) => defaultValue(x, ""),
  medico_derivante: (x) => defaultValue(x, ""),
  atencion_personalizada: (x) => defaultValue(x, ""),
  peso_anterior: (x) => defaultValue(x, ""),
  talla: (x) => defaultValue(x, ""),
  fpp: (x) => utils.inputFecha(x),
  fum: (x) => utils.inputFecha(x),
  fin_parto_fecha: (x) => utils.inputFecha(x),
  fin_parto_tipo: (x) => defaultValue(x, ""),
  fin_parto_sexo: (x) => defaultValue(x, ""),
  fin_parto_peso: (x) => defaultValue(x, ""),
  fin_parto_patologia: (x) => defaultValue(x, ""),
  fin_parto_nacido_vivo: (x) => defaultValue(x, ""),
  fin_parto_nacido_vivo_estado: (x) => defaultValue(x, ""),
  examen_mama: (x) => defaultValue(x, ""),
  examen_pap: (x) => defaultValue(x, ""),
  antitetanica: (x) => defaultValue(x, ""),
  antitetanica_actual_1: (x) => defaultValue(x, ""),
  antitetanica_actual_2: (x) => defaultValue(x, ""),
  sensibil_rh: (x) => defaultValue(x, false),
  riesgoso: (x) => defaultValue(x, false),
  paciente: (x) => defaultValue(x, ""),
  observaciones: (x) => defaultValue(x, ""),
};

export const data_consulta_emb = {
  fecha: (x) => new Date(x).toISOString(),
  semana: (x) => defaultValue(x, ""),
  peso: (x) => defaultValue(x, ""),
  tension_arterial_min: (x) => defaultValue(x, ""),
  tension_arterial_max: (x) => defaultValue(x, ""),
  altura_uterina: (x) => defaultValue(x, ""),
  presentacion: (x) => defaultValue(x, ""),
  fcf: (x) => defaultValue(x, ""),
  mov_fetal: (x) => defaultValue(x, ""),
  observaciones: (x) => defaultValue(x, ""),
  medico: (x) => defaultValue(x, ""),
  embarazo: (x) => defaultValue(x, ""),
};

export const data_antec_embarazo = {
  abortos: (x) => defaultValue(x, 0),
  partos_vaginales: (x) => defaultValue(x, 0),
  partos_cesareas: (x) => defaultValue(x, 0),
  viven: (x) => defaultValue(x, 0),
  mueren_semana: (x) => defaultValue(x, 0),
  mueren_despues: (x) => defaultValue(x, 0),
  nacidos_muertos: (x) => defaultValue(x, 0),
  fecha_terminacion_anterior: (x) => utils.inputFecha(x),
  algun_peso_menor: (x) => defaultValue(x, 0),
  peso_mayor: (x) => defaultValue(x, 0),
  paciente: (x) => defaultValue(x, ""),
};

export function format_antec_embarazo(data) {
  let form = new FormData();
  for (const [key, val] of Object.entries(data)) {
    if (data_antec_embarazo[key])
      form.append(key, data_antec_embarazo[key](val));
  }

  return form;
}

export function format_antec_gral(data) {
  let list = {};
  for (const [k, v] of Object.entries(data)) {
    let value = new FormData();
    if (k !== "paciente") {
      let key = k.split("_")[0];
      if (!list[key]) {
        value.append("antecedente", key);
        value.append("paciente", data["paciente"]);
        value.append("extra_numerico", "");
        value.append("extra_descripcion", "");
        value.append("valor", false);

        if (data[key + "_text"]) {
          value.set("extra_descripcion", data[key + "_text"]);
        }

        if (data[key + "_number"]) {
          value.set("extra_numerico", data[key + "_number"]);
        }

        if (data[key] != undefined) {
          value.set("valor", data[key]);
        }

        list[key] = value;
      }
    }
  }
  return list;
}

export function format_data_embarazo(data) {
  let form = new FormData();
  for (const [key, val] of Object.entries(data)) {
    if (data_embarazo[key]) form.append(key, data_embarazo[key](val));
  }

  if (data["alta"]) {
    form.set("estado", "A");
  }

  if (data["fertilidad"] !== "T") {
    form.append('tipo_tratamiento', "");
    form.append('fecha_tratamiento', "");
  }

  if (data["estado"] !== "F") {
    form.append("fin_parto_fecha", "");
    form.append("fin_parto_tipo", "");
    form.append("fin_parto_peso", "");
    form.append("fin_parto_patologia", "");
    form.append("fin_parto_nacido_vivo", "");
    form.append("fin_parto_nacido_vivo_estado", "");
  }

  if (data["fin_parto_tipo"] === "" || data["fin_parto_tipo"] === "A") {
    form.append("fin_parto_peso", "");
    form.append("fin_parto_patologia", "");
    form.append("fin_parto_nacido_vivo", "");
    form.append("fin_parto_nacido_vivo_estado", "");
  }

  form.append("activo", true);

  return form;
}

export function format_consulta_embarazo(data) {
  let form = new FormData();
  for (const [key, val] of Object.entries(data)) {
    if (data_consulta_emb[key]) form.append(key, data_consulta_emb[key](val));
  }

  return form;
}

export const data_paciente = {
  apellido: (x) => utils.toTitleCase(utils.formatString(x)),
  nombres: (x) => utils.toTitleCase(utils.formatString(x)),
  documento: (x) => defaultValue(x, ""),
  sexo: (x) => "F",
  fecha_nacimiento: (x) => utils.inputFecha(x),
  telefono1: (x) => defaultValue(x, ""),
  telefono2: (x) => defaultValue(x, ""),
  email: (x) => {
    return x === undefined ? "" : x.toLocaleLowerCase;
  },
  domicilio: (x) => utils.toTitleCase(utils.formatString(x)),
  localidad: (x) => utils.toTitleCase(utils.formatString(x)),
  grupo_sanguineo: (x) => defaultValue(x, ""),
  obra_social: (x) => defaultValue(x, ""),
  obra_social_num: (x) => defaultValue(x, ""),
  obra_social_token: (x) => defaultValue(x, ""),
  profesion: (x) => utils.toTitleCase(utils.formatString(x)),
};

export function format_pacientes(data) {
  let form = new FormData();
  for (const [key, val] of Object.entries(data)) {
    if (data_paciente[key]) form.append(key, data_paciente[key](val));
  }

  form.append("sexo", "F");
  return form;
}

export const data_consulta_gral = {
  fecha: (x) => new Date(x).toISOString(),
  motivo: (x) => defaultValue(x, ""),
  diagnostico: (x) => defaultValue(x, ""),
  indicacion: (x) => defaultValue(x, ""),
  observaciones: (x) => defaultValue(x, ""),
  medico: (x) => defaultValue(x, ""),
  paciente: (x) => defaultValue(x, ""),
};

export function format_consulta_gral(data) {
  let form = new FormData();
  for (const [key, val] of Object.entries(data)) {
    if (data_consulta_gral[key]) form.append(key, data_consulta_gral[key](val));
  }

  return form;
}

export const data_laboratorios = {
  fecha: (x) => utils.inputFecha(x),
  laboratorio: (x) => defaultValue(x, ""),
  valor_text: (x) => defaultValue(x, ""),
  valor_bool: (x) => defaultValue(x, ""),
  valor_int: (x) => defaultValue(x, ""),
  medico: (x) => defaultValue(x, ""),
  paciente: (x) => defaultValue(x, ""),
};

export function format_laboratorios(data) {
  let form = new FormData();
  for (const [key, val] of Object.entries(data)) {
    if (data_laboratorios[key]) form.append(key, data_laboratorios[key](val));
  }
  return form;
}

export const data_abm_laboratorios = {
  nombre: (x) => utils.toTitleCase(utils.formatString(x)),
  abreviatura: (x) => defaultValue(x.toUpperCase(), ""),
  valor_bool: (x) => defaultValue(x, false),
  valor_text: (x) => defaultValue(x, false),
  valor_int: (x) => defaultValue(x, false),
};

export function format_abm_laboratorios(data) {
  let form = new FormData();
  for (const [key, val] of Object.entries(data)) {
    if (data_abm_laboratorios[key])
      form.append(key, data_abm_laboratorios[key](val));
  }
  return form;

}

export const data_abm_usuario = {
  email: (x) => defaultValue(x.toLowerCase(), ""),
  first_name: (x) => utils.toTitleCase(utils.formatString(x)),
  last_name: (x) => utils.toTitleCase(utils.formatString(x)),
  especialidad: (x) => utils.toTitleCase(utils.formatString(x)),
  is_active: (x) => defaultValue(x, false),
  groups: (x) => defaultValue(x, []),
  username: (x) => defaultValue(x, ""),
  password: (x) => defaultValue(x, ""),
};

export function format_abm_usuario(data) {
  let form = new FormData();
  for (const [key, val] of Object.entries(data)) {
    if (data_abm_usuario[key]) form.append(key, data_abm_usuario[key](val));
  }
  return form;

}

