import React, { useState, useEffect } from "react";

const Switch = React.forwardRef((props, ref) => {
  const [estado, setEstado] = useState(false);

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      setEstado(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
    <div
      className={estado ? "az-toggle on" : "az-toggle off"}
      onClick={() => {
        let est = !estado;
        setEstado(est);
        if (props.callback) props.callback(est);
      }}
    >
      <span></span>
      <input
        type="checkbox"
        checked={estado}
        name={props.name}
        className="invisible"
        ref={ref}
        onChange={() => {
          setEstado(!estado);
        }}
      />
    </div>
  );
});

export default Switch;
