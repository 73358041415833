import React from "react";

export default function WorkingView() {
  return (
    <div>
      <h2 className="az-content-header d-block d-md-flex">
        Vista en construcción
      </h2>
    </div>
  );
}
