import React, { useContext, useState } from "react";

export default function Options(props) {
  let html;
  if (props.opcion_vacia)
    html = [
      <option key={0} value="">
        {props.opcion_vacia}
      </option>,
    ];
  else html = [];

  let count = 1;
  if (props.data) {
    props.data.forEach((element) => {
      html.push(
        <option key={count++} value={props.value_field(element)}>
          {props.name_field(element)}
        </option>
      );
    });
  }

  return html;
}
