import React, { useContext, useEffect } from "react";
import { AuthContext } from "./components/Authentication/AuthComponent";
import AuthenticatedApp from "./components/Authentication/AuthenticatedApp";
import UnAuthenticatedApp from "./components/Authentication/UnAuthenticatedApp";

function App() {
  const auth = useContext(AuthContext);

  return (
    <>
      {!auth.isLoggedIn && <UnAuthenticatedApp />}
      {auth.isLoggedIn && <AuthenticatedApp />}
    </>
  );
}

export default App;
