import React, { useState, useEffect, useMemo, useContext } from "react";
import Swal from "sweetalert2";

import { config } from "../../config.js";
import { useForm } from "react-hook-form";
import { useConnection } from "../../components/Utils/MyHooks";
import * as utils from "../../components/Utils/Utils";
import OptionList from "../Templates/OptionList";
import CardComponent from "../Templates/CardComponent.jsx";
import TableEditableComponent from "../Templates/TableEditableComponent.jsx";
import ContentView from "../Templates/ContentView.jsx";
import WrapperPermisos from "../../components/Authentication/WrapperPermisos.jsx";
import { AuthContext } from "../../components/Authentication/AuthComponent";
import DebugErrors from "../Templates/DebugErrors";
import ButtonStatus from "../Templates/ButtonStatus";

const FileLoader = (props) => {
  const [label, setLabel] = useState("");
  // const [otro, setOtro] = useState(false);
  const [select, setSelect] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const upload = useConnection();

  const auth = useContext(AuthContext);

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  useEffect(() => {
    if (upload.status === "done") {
      if (upload.data) {
        props.callback(upload.data);
      } else {
        console.log(upload.error);
        // error_msg(upload.error.msg);
      }
    }
  }, [upload.status]);

  return (
    <>
      {config.debug && <DebugErrors watch={[upload]} />}
      <div className="az-content-label tx-12">Carga de Archivos</div>
      <div className="form-row mg-t-20">
        <div className="form-group col-md-5 col-lg-5">
          <div className="custom-file col-md-12 col-lg-12">
            <input
              className="custom-file-input"
              type="file"
              name={"file"}
              ref={register({ required: true })}
              onChange={(event) => {
                if (event.target.files[0]) setLabel(event.target.files[0].name);
                else setLabel("Desconocido");
              }}
            />
            <label
              className={
                errors["file"]
                  ? "custom-file-label bd-danger"
                  : "custom-file-label"
              }
            >
              {label ? label : "Seleccionar archivo"}
            </label>
          </div>
        </div>
        <div className="form-group col-md-2 col-lg-2">
          <select
            className={
              errors["descripcion"]
                ? "custom-select wd-100p bd bd-1 bd-danger"
                : "custom-select wd-100p"
            }
            style={{ height: "38px" }}
            name={"descripcion"}
            ref={register({ required: true })}
            value={select}
            onChange={(event) => {
              setSelect(event.target.value);
              // setOtro(event.target.value === "otro");
            }}
          >
            <OptionList
              opcion_vacia={"-"}
              data={utils.estudios}
              value_field={(x) => {
                return x.nombre;
              }}
              name_field={(x) => {
                return x.nombre;
              }}
            />
            <option value="otro">Otros</option>
          </select>
        </div>
        {select === "otro" && (
          <div className="form-group col-md-3 col-lg-3">
            <input
              type="text"
              className={
                errors["descripcion_otro"]
                  ? "form-control wd-100p bd bd-1 bd-danger"
                  : "form-control wd-100p"
              }
              style={{ height: "38px" }}
              placeholder={"Descripción"}
              name={"descripcion_otro"}
              ref={register({ required: true })}
            />
          </div>
        )}
        <div className="form-group col-md-3 col-lg-3">
          <ButtonStatus
            clase={"btn btn-secondary"}
            status={upload.status}
            txt_success={"Cargado!"}
            txt_loading="Cargando.."
            onClick={handleSubmit((x) => {
              setLabel("");
              setSelect("");

              let form = new FormData();

              form.append("fecha", utils.inputFecha());
              form.append(
                "descripcion",
                x.descripcion_otro ? x.descripcion_otro : x.descripcion
              );
              let file = x.file[0].name.split(".");
              let ext = file[file.length - 1];
              let name = file[0] ? file[0].toUpperCase() : "";
              form.append("file", x.file[0], name + "." + ext);
              form.append("paciente", props.data_paciente.id);
              form.append("medico", auth.getSessionData().id);

              upload.post(config.api_pacientes_estudios, form);
            })}
          >
            <i className="fas fa-cloud-upload-alt mg-r-5"></i> Cargar
          </ButtonStatus>
          {/* <button
            type="button"
            className="btn btn-secondary"
            onClick={handleSubmit((x) => {
              setLabel("");
              setSelect("");

              let form = new FormData();

              form.append("fecha", utils.inputFecha());
              form.append(
                "descripcion",
                x.descripcion_otro ? x.descripcion_otro : x.descripcion
              );
              let file = x.file[0].name.split(".");
              let ext = file[file.length - 1];
              let name = file[0] ? file[0].toUpperCase() : "";
              form.append("file", x.file[0], name + "." + ext);
              form.append("paciente", props.data_paciente.id);
              form.append("medico", auth.getSessionData().id);

              upload.post(config.api_pacientes_estudios, form);
            })}
          >
            <i className="fas fa-cloud-upload-alt mg-r-5"></i> Cargar
          </button> */}
        </div>
      </div>
    </>
  );
};

export default function EstudiosMainView(props) {
  const [data, setData] = useState([]);
  const files_load = useConnection();
  const files_delete = useConnection();
  const [del_file, setDelFile] = useState(null);

  useEffect(() => {
    if (props.data_estudios) {
      setData(props.data_estudios);
    }
  }, [props.data_estudios]);

  const delete_msg = (id) =>
    Swal.fire({
      title: "Borrar archivo?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setDelFile(id);
        files_delete.del(config.api_pacientes_estudios + id + "/");
      }
    });

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  useEffect(() => {
    if (files_delete.status === "done") {
      if (!files_delete.error) {
        let d = data.filter((x) => {
          return x.id !== del_file;
        });
        setData(d);
      } else {
        error_msg(files_delete.error.msg);
      }
    }
  }, [files_delete.status]);

  return (
    <ContentView
      error={files_load.error ? [files_load.error.msg] : []}
      loading={files_load.status === "loading"}
    >
      <CardComponent>
        <FileLoader
          {...props}
          callback={(x) => {
            setData((prev) => {
              return [x, ...prev];
            });
          }}
        />
      </CardComponent>
      <CardComponent style={"mg-t-10 ht-540"}>
        <div className="az-content-label tx-12">Archivos Cargados</div>
        <div
          className={"scroll"}
          style={{ height: "490px", overflowY: "auto" }}
        >
          {useMemo(
            () => (
              <TableEditableComponent
                data={data}
                header={[
                  {
                    title: "Fecha Carga",
                    cellStyle: { width: "20%", padding: 0 },
                    field: "created_at",
                    defaultSort: "desc",
                    render: (row) => {
                      let date = new Date(row.created_at);
                      return (
                        date.getDate().toString().padStart(2, 0) +
                        "/" +
                        (date.getMonth() + 1).toString().padStart(2, 0) +
                        "/" +
                        date.getFullYear().toString()
                      );
                    },
                  },
                  {
                    title: "Nombre",
                    cellStyle: { width: "50%", padding: 0 },
                    field: "file",
                    render: (row) => {
                      let name, link;
                      // if (row.upload) {
                      //   name = row.file_name;
                      //   return name;
                      // } else {
                      name = row.file.split("/");
                      name = name[name.length - 1];
                      name =
                        name.length > 30 ? name.substring(0, 30) + "..." : name;
                      return (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={row.file}
                        >
                          {name}
                        </a>
                      );
                      // }
                    },
                  },
                  {
                    title: "Descripción",
                    cellStyle: { width: "40%", padding: 0 },
                    field: "descripcion",
                  },
                  {
                    title: "Acciones",
                    cellStyle: { width: "5%", padding: 0 },
                    render: (row) => {
                      return (
                        <WrapperPermisos
                          groups={["propietario"]}
                          users={[row.medico]}
                          default={"-"}
                        >
                          <button
                            type="button"
                            className="btn btn-icon pd-0 wd-20"
                            onClick={() => {
                              delete_msg(row.id);
                            }}
                          >
                            <i className="typcn typcn-trash tx-gray-500 tx-25-f"></i>
                          </button>
                        </WrapperPermisos>
                      );
                    },
                  },
                ]}
                no_toolbar={true}
                sorting={true}
                paging={false}
                max_items={4}
                no_editable={true}
                font={13}
              />
            ),
            [data]
          )}
        </div>
      </CardComponent>
    </ContentView>
  );
}
