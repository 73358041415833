import React, { useState, useEffect } from "react";

import ContentView from "../../Templates/ContentView";
import OptionList from "../../Templates/OptionList";
import { config } from "../../../config";
import { useConnection } from "../../../components/Utils/MyHooks";
import * as print from "../../../services/print";

import NewEmbarazo from "./Obstetricia_Modules/NewEmbarazo";
import ExistenteEmbarazo from "./Obstetricia_Modules/ExistenteEmbarazo";
import WrapperPermisos from "../../../components/Authentication/WrapperPermisos";

export default function ConsultaObstetriciaMainView(props) {
  const [data_embarazo_selected, setDataEmbarazoSelected] = useState();
  const [embarazos_list, setEmbarazosList] = useState([]);
  const [embarazoSelectedID, setEmbarazoSelectedID] = useState("");
  const [all_alta, setAllAlta] = useState(false);

  const update_embarazo = useConnection();
  const new_embarazo = useConnection();

  const checkAlta = (data) => {
    let found = data.every((x) => {
      return x && x.estado === "A";
    });

    return found || data.length === 0;
  };

  useEffect(() => {
    if (props.data_embarazo) {
      let alta = checkAlta(props.data_embarazo);
      setEmbarazosList(props.data_embarazo);
      setAllAlta(alta);
      if (!alta) {
        setEmbarazoSelectedID(
          props.data_embarazo[props.data_embarazo.length - 1].id
        );
      } else {
        setEmbarazoSelectedID("");
      }
    }
  }, [props.data_embarazo]);

  useEffect(() => {
    if (update_embarazo.status === "done") {
      if (update_embarazo.data) {
        let alta = checkAlta(update_embarazo.data);
        setEmbarazosList(update_embarazo.data);
        setAllAlta(alta);
      }
    }
  }, [update_embarazo.status]);

  useEffect(() => {
    if (new_embarazo.status === "done") {
      if (new_embarazo.data) {
        setEmbarazosList(new_embarazo.data);
        setEmbarazoSelectedID(
          new_embarazo.data[new_embarazo.data.length - 1].id
        );
        setAllAlta(false);
      }
    }
  }, [new_embarazo.status]);

  useEffect(() => {
    if (embarazoSelectedID) {
      let embarazo = embarazos_list.filter((x) => {
        return x.id === Number(embarazoSelectedID);
      });

      if (embarazo.length) setDataEmbarazoSelected(embarazo[0]);
    }
  }, [embarazoSelectedID]);

  return (
    <ContentView
      title={""}
      error={update_embarazo.error ? [update_embarazo.error.msg] : []}
      loading={false}
    >
      <div className="row">
        <div className="col-md-3">
          <select
            className="custom-select"
            value={embarazoSelectedID}
            onChange={(event) => {
              setEmbarazoSelectedID(event.target.value);
            }}
          >
            <OptionList
              data={all_alta ? [...embarazos_list, null] : embarazos_list}
              value_field={(x) => {
                if (x) return x.id;
                else return "";
              }}
              name_field={(x) => {
                if (x) return `Embarazo #${embarazos_list.indexOf(x) + 1}`;
                else return "Nuevo Embarazo";
              }}
            />
          </select>
        </div>
        <div className="col-md-9">
          {embarazoSelectedID !== "" && (
            <div className="btn-icon-list float-right">
              <button
                type="button"
                className="btn btn-az-primary btn-icon"
                onClick={() => {
                  props.print_ficha(
                    data_embarazo_selected,
                    embarazos_list.indexOf(data_embarazo_selected) + 1
                  );
                }}
              >
                <i className="fas fa-print"></i>
              </button>
            </div>
          )}
        </div>
      </div>
      <div>
        {embarazoSelectedID !== "" && (
          <ExistenteEmbarazo
            data={data_embarazo_selected}
            callback={() => {
              update_embarazo.get(
                config.api_embarazos_por_paciente + props.data_paciente.id + "/"
              );
            }}
          />
        )}
        {embarazoSelectedID === "" && (
          <WrapperPermisos
            groups={["propietario"]}
            users={[]}
            default={<h4>No tiene permisos para realizar esta accion</h4>}
          >
            <NewEmbarazo
              paciente={props.data_paciente}
              callback={() => {
                new_embarazo.get(
                  config.api_embarazos_por_paciente +
                    props.data_paciente.id +
                    "/"
                );
              }}
            />
          </WrapperPermisos>
        )}
      </div>
    </ContentView>
  );
}
