import React, { useEffect, useState } from "react";
import ButtonStatus from "../../Templates/ButtonStatus";
import { useForm } from "react-hook-form";
import { useConnection } from "../../../components/Utils/MyHooks";
import Swal from "sweetalert2";
import DebugErrors from "../../Templates/DebugErrors";
import { config } from "../../../config";
import * as services from "../../../services/embarazo_services";

const MyInputs = React.forwardRef((props, ref) => {
  const [estado, setEstado] = useState(false);
  const [txt, setTxt] = useState("");
  const [number, setNumber] = useState("");

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      setEstado(props.defaultValue.valor || "");
      setTxt(props.defaultValue.extra_descripcion || "");
      setNumber(props.defaultValue.extra_numerico || "");
    }
  }, [props.defaultValue]);

  if (!props.render.extra_descripcion) {
    return (
      <div className="form form-row">
        <div className="form-group col-md-8 col-lg-8 pd-l-5 mg-r-5">
          <label className="ckbox">
            <input
              type="checkbox"
              name={props.render.id}
              ref={ref}
              checked={estado}
              onChange={() => {
                setEstado(!estado);
              }}
            />
            <span>{props.render.nombre}</span>
          </label>
        </div>
        {props.render.extra_numerico && (
          <div className="form-group col-md-3">
            <input
              type="number"
              disabled={!estado}
              placeholder="Cantidad"
              className="form-control"
              name={props.render.id + "_number"}
              value={estado ? number : ""}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
              ref={ref}
            />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="form-row">
        <div className="form-group col-md-3">
          <span>{props.render.nombre}</span>
        </div>
        <div className="form-group col-md-9">
          <textarea
            className="form-control"
            name={props.render.id + "_text"}
            value={txt}
            onChange={(e) => {
              setTxt(e.target.value);
            }}
            ref={ref}
          />
        </div>
      </div>
    );
  }
});

export default function AntecedentesGeneral(props) {
  const [fields, setFields] = useState({});
  const [error, setError] = useState([]);

  const form = useForm();
  const save = useConnection();
  const [status, setStatus] = useState("");

  const getValueData = (data, key) => {
    if (!data) return null;

    let tmp = data.filter((element) => {
      return element.antecedente === key;
    });

    if (tmp.length > 0) return tmp[0];
    else return null;
  };

  useEffect(() => {
    let tmp = {};
    if (props.init_data) {
      props.init_data.forEach((element) => {
        if (!tmp[element.id]) tmp[element.id] = {};

        let obj = getValueData(props.data, element.id);
        if (obj) {
          tmp[element.id] = obj;
        } else {
          tmp[element.id] = {
            valor: false,
            extra_descripcion: "",
            extra_numerico: "",
          };
        }
      });
      setFields(tmp);
    }
  }, []);

  // const error_msg = (msg) =>
  //   Swal.fire({
  //     title: msg,
  //     icon: "error",
  //     confirmButtonText: "Aceptar",
  //   });

  useEffect(() => {
    let err = [];
    if (save.status === "done") {
      if (save.data) {
        save.data.forEach((element) => {
          if (element.error) err.push(element);
        });
        setStatus("done");
      } else {
        // err = save.error.msg;
        setStatus("");
      }

      if (err.length > 0) setError(err);
      // if (err) error_msg(err);
    }
  }, [save.status]);

  let html = [];
  props.init_data.forEach((e) => {
    html.push(
      <MyInputs
        key={e.id}
        defaultValue={fields[e.id]}
        render={e}
        ref={form.register}
      />
    );
  });

  return (
    <>
      {config.debug && <DebugErrors watch={error} />}
      {config.debug && error.length === 0 && (
        <>
          <div style={{ height: "500px" }}>{html}</div>
          <ButtonStatus
            clase={"btn btn-az-primary mg-t-20 float-right"}
            status={status}
            txt_success={"Guardado!"}
            txt_loading="Guardando.."
            onClick={form.handleSubmit((x) => {
              setStatus("loading");
              let tmp = [];
              let form_ant = services.format_antec_gral({
                ...x,
                paciente: props.paciente.id,
              });

              for (const [k, v] of Object.entries(form_ant)) {
                let ant = props.data.filter((x) => {
                  return x.antecedente == k;
                });

                if (ant[0]) {
                  tmp.push({
                    url: config.api_pacientes_antecedentes + ant[0].id + "/",
                    method: "PUT",
                    body: v,
                  });
                } else {
                  tmp.push({
                    url: config.api_pacientes_antecedentes,
                    method: "POST",
                    body: v,
                  });
                }
              }
              save.list(tmp);
            })}
          >
            <i className="far fa-save tx-15 mg-r-5"></i>Guardar
          </ButtonStatus>
        </>
      )}
    </>
  );
}
