import React, { useState, useEffect } from "react";
import * as services from "../../../../services/embarazo_services";
import Options from "../../../Templates/OptionList";
import * as utils from "../../../../components/Utils/Utils";
import MyDatePicker from "../../../Templates/MyDatePicker";
import WrapperPermisos from "../../../../components/Authentication/WrapperPermisos";

const NewRegistroEmbarazo = React.forwardRef((props, ref) => {
  const [fields, setFields] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.data) {
      setFields(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.errors) {
      setErrors(props.errors);
    }
  }, [props.errors]);

  return (
    <>
      {!props.data && (
        <div className="az-content-label mg-b-5 tx-12">Consulta</div>
      )}
      <div className="mg-t-10">
        <div className="form form-row">
          <WrapperPermisos users={[]} groups={['propietario']}>
            <div className="form-group col-md-2 col-lg-2 mg-b-5">
              <label className="az-content-label-sm tx-gray-600">Fecha</label>
              <MyDatePicker
                name={"fecha"}
                ref={ref({ required: true })}
                fecha={utils.inputFecha(fields['fecha'])}
              />
            </div>
          </WrapperPermisos>
          <div className="form-group col-md-2 col-lg-2 mg-b-5">
            <label className="az-content-label-sm tx-gray-600">Semana</label>
            <input
              type="number"
              className={
                "form-control wd-60p " +
                (errors["semana"] ? "parsley-error" : "")
              }
              name="semana"
              defaultValue={fields["semana"]}
              ref={ref({
                required: true
              })}
            />
          </div>
          <div className="form-group col-md-2 col-lg-2 mg-b-5 ">
            <label className="az-content-label-sm tx-gray-600">Peso (KG)</label>
            <input
              type="number"
              className="form-control wd-60p"
              name="peso"
              defaultValue={fields["peso"]}
              ref={ref}
            />
          </div>
          <div className="form-group col-md-3 col-lg-3 mg-b-5 ">
            <label className="az-content-label-sm tx-gray-600">
              Tensión (MM HG)
            </label>
            <div className="form-row">
              <div className="form-group col-md-5 col-lg-5 mg-b-5">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Max"
                  name="tension_arterial_max"
                  defaultValue={fields["tension_arterial_max"]}
                  ref={ref}
                />
              </div>
              /
              <div className="form-group col-md-5 col-lg-5 mg-b-5">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Min"
                  name="tension_arterial_min"
                  defaultValue={fields["tension_arterial_min"]}
                  ref={ref}
                />
              </div>
            </div>
          </div>
          <div className="form-group col-md-3 col-lg-3 mg-b-5">
            <label className="az-content-label-sm tx-gray-600">
              Altura Uterina (CM)
            </label>
            <input
              type="number"
              className="form-control wd-40p"
              name="altura_uterina"
              defaultValue={fields["altura_uterina"]}
              ref={ref}
            />
          </div>
        </div>
        <div className="form form-row">
          <div className="form-group col-md-2 col-lg-2 mg-b-5">
            <label className="az-content-label-sm tx-gray-600">
              Presentación
            </label>
            <select
              className="custom-select"
              name="presentacion"
              ref={ref}
              value={fields["presentacion"]}
              onChange={(e) => {
                let value = e.target.value;
                setFields((prev) => ({
                  ...prev,
                  presentacion: value,
                }));
              }}
            >
              <Options
                data={utils.presentaciones_embarazo}
                opcion_vacia={"-"}
                value_field={(x) => {
                  return x.id;
                }}
                name_field={(x) => {
                  return x.nombre;
                }}
              />
            </select>
          </div>
          <div className="form-group offset-md-2 col-md-2 col-lg-2 mg-b-5">
            <label className="az-content-label-sm tx-gray-600">F.C.F</label>
            <select
              className="custom-select"
              name="fcf"
              ref={ref}
              value={fields["fcf"]}
              onChange={(e) => {
                let value = e.target.value;
                setFields((prev) => ({ ...prev, fcf: value }));
              }}
            >
              <Options
                data={utils.fcf_embarazo}
                opcion_vacia={"-"}
                value_field={(x) => {
                  return x.id;
                }}
                name_field={(x) => {
                  return x.nombre;
                }}
              />
            </select>
          </div>
          <div className="form-group offset-md-1 col-md-2 col-lg-2 mg-b-5">
            <label className="az-content-label-sm tx-gray-600">
              Movimiento
            </label>
            <select
              className="custom-select wd-70p"
              name="mov_fetal"
              ref={ref}
              value={fields["mov_fetal"]}
              onChange={(e) => {
                let value = e.target.value;
                setFields((prev) => ({ ...prev, mov_fetal: value }));
              }}
            >
              <option value="">-</option>
              <option value={true}>SI</option>
              <option value={false}>NO</option>
            </select>
          </div>
        </div>
        <div className="form form-row">
          <div className="form-group col-md-10 col-lg-10">
            <label className="az-content-label-sm tx-gray-600">
              Observaciones
            </label>
            <textarea
              defaultValue={fields["observaciones"]}
              className="form-control"
              rows="2"
              name="observaciones"
              ref={ref}
            />
          </div>
          {props.submit && (
            <div className="form-group col-md-2 col-lg-2 mg-b-5">
              <button
                type="button"
                style={{ marginTop: "35px" }}
                className="btn btn-az-primary"
                onClick={props.submit}
              >
                <i className="far fa-save tx-15 mg-r-5"></i> Guardar
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default NewRegistroEmbarazo;
