import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import CardComponent from "../../../Templates/CardComponent";

import { config } from "../../../../config";
import * as services from "../../../../services/embarazo_services";
import * as utils from "../../../../components/Utils/Utils";
import { useConnection } from "../../../../components/Utils/MyHooks";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../../components/Authentication/AuthComponent";
import Collapse from "@material-ui/core/Collapse";
import ListConsultasEmbarazo from "./ListConsultasEmbarazo";
import NewConsultaEmbarazo from "./NewConsultaEmbarazo";
import DatosEmbarazoActual from "./DatosEmbarazoActual";
import DebugErrors from "../../../Templates/DebugErrors";
import TabComponent from "../../../Templates/TabComponent";
import ButtonStatus from "../../../Templates/ButtonStatus";

export default function ConsultaObstetriciaMainView(props) {
  const [data_embarazo, setDataEmbarazo] = useState();
  const [data_consultas, setDataConsultas] = useState([]);
  const [open, setOpen] = useState(false);

  const newRegistro = useForm();
  const datosEmbarazo = useForm();

  const connect_new_registro = useConnection();
  const connect_mod_embarazo = useConnection();

  const auth = useContext(AuthContext);

  const [status, setStatus] = useState("");

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  useEffect(() => {
    if (props.data) {
      setDataEmbarazo(props.data);
      setDataConsultas(props.data.consultas);
    }
  }, [props.data]);

  useEffect(() => {
    if (connect_new_registro.status === "done") {
      if (connect_new_registro.data) {
        setOpen(false);
        let array = [...data_consultas, connect_new_registro.data];
        let ordered = array.sort((a, b) => {
          return new Date(b.fecha) - new Date(a.fecha);
        });
        setDataConsultas(ordered);
      } else {
        // error_msg("Error al guardar consulta");
      }
    }
  }, [connect_new_registro.status]);

  useEffect(() => {
    if (connect_mod_embarazo.status === "done")
      if (connect_mod_embarazo.data) {
        setStatus("done");
        //Refresco la lista de embarazos luego de cada modificacion de datos ya que si se dio
        // de alta tengo que permitir crear nuevos embarazos. Por otro lado si se dio de alta accidentalmente
        // y luego se quiere deshacer, necesito volver a pedir el listado para poder quitar el enlace a Nuevo Embarazo
        props.callback();
      } else {
        setStatus("");
        // error_msg("Error al guardar datos de Embarazo");
      }
  }, [connect_mod_embarazo.status]);

  return (
    <>
      {config.debug && (
        <DebugErrors watch={[connect_mod_embarazo, connect_new_registro]} />
      )}
      <TabComponent
        nav={[
          {
            id: "datos_embarazo",
            nav: "Datos Embarazo",
            active: true,
            component: (
              <>
                <div
                  className="scroll"
                  style={{
                    padding: "10px",
                    height: "525px",
                    overflowY: "auto",
                  }}
                >
                  <DatosEmbarazoActual
                    data={data_embarazo}
                    ref={datosEmbarazo.register}
                    errors={datosEmbarazo.errors}
                  />
                </div>
                <div style={{ borderTop: "1px solid #cdd4e0" }}>
                  <ButtonStatus
                    clase={"btn btn-az-primary mg-t-10 float-right"}
                    status={status}
                    txt_success={"Guardado!"}
                    txt_loading="Guardando.."
                    onClick={datosEmbarazo.handleSubmit((x) => {
                      setStatus("loading");
                      let form = services.format_data_embarazo({
                        ...x,
                        paciente: data_embarazo.paciente,
                      });

                      connect_mod_embarazo.patch(
                        config.api_embarazos + data_embarazo.id + "/",
                        form
                      );
                    })}
                  >
                    <i className="far fa-save tx-15 mg-r-5"></i> Actualizar
                  </ButtonStatus>
                </div>
              </>
            ),
          },
          {
            id: "listas_consultas",
            nav: "Consultas Embarazo",
            active: true,
            component: (
              <div style={{ padding: "10px", height: "550px" }}>
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={() => setOpen(!open)}
                >
                  <i className="fas fa-plus"></i> Nueva Consulta
                </button>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <div className="pd-20 pd-sm-20 bg-gray-100 mg-t-10">
                    <NewConsultaEmbarazo
                      key={open ? "1" : "2"}
                      ref={newRegistro.register}
                      errors={newRegistro.errors}
                      submit={newRegistro.handleSubmit((x) => {
                        let form = services.format_consulta_embarazo({
                          ...x,
                          embarazo: data_embarazo.id,
                          medico: auth.getSessionData().id,
                        });
                        connect_new_registro.post(
                          config.api_consultas_embarazos,
                          form
                        );
                      })}
                    />
                  </div>
                </Collapse>
                <ListConsultasEmbarazo
                  data={data_consultas}
                  callback={setDataConsultas}
                  medico={auth.getSessionData().id}
                />
              </div>
            ),
          },
        ]}
      />
    </>
  );
}
