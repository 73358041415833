import React, { useEffect, useState } from "react";
import Modal from "react-modal";

export default function TestModal(props) {
  return (
    <Modal
      isOpen={props.isOpen}
      ariaHideApp={false}
      onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
        },
        overlay: {
          zIndex: 100,
          backgroundColor: "#313131bf",
        },
      }}
      contentLabel="Example Modal"
    >
      <h5>{props.title}</h5>
      <hr></hr>
      {props.children}
      <hr></hr>
      <div className="d-flex" style={{ placeContent: "flex-end" }}>
        <div className="mg-r-5 float-right">{props.cancelar}</div>
        <div className="mg-r-5 float-right">{props.eliminar}</div>
        <div className="float-right">{props.aceptar}</div>
      </div>
    </Modal>
  );
}
