import React from "react";

export default function Spinner() {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-grow" role="status" style={{ marginTop: "30%" }}>
        <span className="sr-only">Cargando...</span>
      </div>
    </div>
  );
}
