import React from "react";

import MainLayout from "../../layouts/MainLayout";
import MenuProvider from "../Utils/MenuContext";

export default function AuthenticatedApp(props) {
  console.log("Autenticado");
  return (
    <MenuProvider>
      <MainLayout />
    </MenuProvider>
  );
}
