import React, { useEffect, useState } from "react";
import HomeView from "../../views/HomeView";
import WorkingView from "../../views/WorkingView";
import PacientesMain from "../../views/Pacientes/PacientesMain";
import PacientesABMDatos from "../../views/Pacientes/PacientesABMDatos";
import PacientesListView from "../../views/Pacientes/PacientesListView";
import EmbarazosListView from "../../views/Pacientes/EmbarazosListView";
import AdminLaboratoriosView from "../../views/AdminLaboratoriosView";
import AdminColaboradoresView from "../../views/AdminColaboradoresView";
import ErrorView from "../../views/Templates/ErrorView";

const MenuContext = React.createContext();

export default function MenuProvider(props) {
  const [view, setView] = useState(<HomeView />);
  const [caller, setCaller] = useState("home");
  const [history, setHistory] = useState({});

  function initChildren() {
    setHistory({
      id: "home",
      view: "home",
      arg: "",
      breadcrumb: "inicio",
      parent: "root",
      child: null,
    });
  }

  useEffect(() => {
    initChildren();
  }, []);

  function setChild(h, obj) {
    if (h) {
      if (h.id === obj.parent) {
        h.child = obj;
      } else {
        h.child = setChild(h.child, obj);
      }
    }

    return h;
  }

  function handleHistory(obj) {
    let h = JSON.parse(JSON.stringify(history));

    if (obj.view === "home") initChildren();
    else {
      setChild(h, obj);
      setHistory(h);
    }
  }

  function linkViewButton(view, arg, txt_breadcrumb) {
    let x = arg ? view + "_id" : view;

    handleHistory({
      id: x,
      view: view,
      arg: arg,
      breadcrumb: txt_breadcrumb,
      parent: caller,
      child: null,
    });
    handleView(view, arg);
  }

  function linkViewNav(view, arg, txt_breadcrumb) {
    let x = arg ? view + "_id" : view;

    handleHistory({
      id: x,
      view: view,
      arg: arg,
      breadcrumb: txt_breadcrumb,
      parent: "home",
      child: null,
    });
    handleView(view, arg);
  }

  function handleView(view, arg) {
    let x = arg ? view + "_id" : view;

    if (caller !== x) {
      setCaller(x);
    } else {
      return;
    }

    switch (view) {
      case "home":
        setView(<HomeView />);
        break;
      case "pacientes":
        setView(<PacientesMain arg={arg} />);
        break;
      case "abm-pacientes":
        setView(<PacientesABMDatos arg={arg} />);
        break;
      case "pacientes-list":
        setView(<PacientesListView arg={arg} />);
        break;
      case "embarazos-list":
        setView(<EmbarazosListView arg={arg} />);
        break;
      case "abm-laboratorios":
        setView(<AdminLaboratoriosView arg={arg} />);
        break;
      case "abm-colaboradores":
        setView(<AdminColaboradoresView arg={arg} />);
        break;
      case "error":
        setView(<ErrorView arg={arg} />);
        break;
      default:
        setView(<WorkingView />);
        break;
    }
  }

  return (
    <MenuContext.Provider
      value={{
        linkButton: linkViewButton,
        linkNav: linkViewNav,
        setView: handleView,
        currentView: view,
        history: history,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
}

export { MenuContext };
