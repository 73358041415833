import React, { useContext } from "react";
import { AuthContext } from "../../components/Authentication/AuthComponent";

export default function ContentTab(props) {
  let html = [];
  let count = 1;
  if (props.data) {
    props.data.forEach((element) => {
      html.push(
        <div
          key={element.id}
          className={
            count === 2 ? "tab-pane fade active show" : "tab-pane fade"
          }
          id={"tab_" + element.id}
        >
          {element.content}
        </div>
      );
      count++;
    });
  }
  return <div className="tab-content">{html}</div>;
}
