import React, { useState, useEffect, useMemo } from "react";
import * as utils from "../../../components/Utils/Utils";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  root: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    padding: 5,
  },
});

export default function LaboratoriosTable(props) {
  const [header, setHeader] = useState([]);
  const [body, setBody] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (props.data) {
      makeTable(props.data);
    }
  }, [props.data]);

  const makeTable = (data) => {
    let h = ["Laboratorio"];
    let d = {};

    let ordered = data.sort((a, b) => {
      return new Date(b.fecha) - new Date(a.fecha);
    });

    ordered.forEach((element) => {
      if (!d[element.laboratorio])
        d[element.laboratorio] = {
          lab: element.laboratorio,
          data: [],
        };
      d[element.laboratorio] = {
        lab: element.laboratorio,
        data: [...d[element.laboratorio].data, element],
      };

      if (!h.includes(element.fecha)) h.push(element.fecha);
    });

    setBody(Object.values(d));
    setHeader(h);
  };

  const get_lab_data = (id) => {
    if (props.laboratorios_base) {
      let h = props.laboratorios_base.filter((e) => {
        return Number(id) === e.id;
      });
      return h[0];
    } else return null;
  };

  const Row = (props) => {
    const [row, setRow] = useState(null);
    let body = [];

    useEffect(() => {
      setRow(get_lab_data(props.laboratorios_paciente.lab));
    }, [props.laboratorios_base, props.laboratorios_paciente.lab]);

    const format_row = (row) => {
      let value = "";
      let l = get_lab_data(row.laboratorio);

      if (!l) return "";

      if (l.valor_text) {
        value = row.valor_text;
      }

      if (l.valor_bool) {
        value = row.valor_bool ? "+" : "-";
      }

      if (l.valor_int) {
        if (value !== "") value = value + " (" + row.valor_int + ")";
        else value = row.valor_int;
      }

      return <span>{value}</span>;
    };

    let count = 0;
    for (let index = 0; index < props.header.length; index++) {
      if (index !== 0) {
        let found = props.laboratorios_paciente.data.filter((e) => {
          return e.fecha === props.header[index];
        });
        if (found.length > 0)
          body.push(
            <TableCell
              className={classes.root}
              style={{
                fontSize: "12px",
                cursor: "pointer",
              }}
              key={count++}
              align="center"
              onClick={() => {
                props.edit(found[0]);
              }}
            >
              {format_row(found[0])}
            </TableCell>
          );
        else
          body.push(
            <TableCell
              className={classes.root}
              style={{
                fontSize: "12px",
                cursor: "pointer",
              }}
              key={count++}
              align="center"
              onClick={() =>
                props.new(props.header[index], props.laboratorios_paciente.data)
              }
            ></TableCell>
          );
      }
    }

    return (
      <TableRow key={new Date().getTime()}>
        <TableCell
          align="left"
          className={classes.root}
          style={{
            fontSize: "12px",
            color: "#7987a1",
          }}
        >
          {row ? row.nombre : ""}
        </TableCell>
        {body}
      </TableRow>
    );
  };

  let count = 0;

  return (
    <TableContainer
      style={{
        maxWidth: 1080,
        height: 458,
        overflowY: "auto",
      }}
      className={"scroll"}
    >
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {header.map((e) => {
              let alignment = "center";
              let value = utils.displayFecha(utils.formatFecha(e));
              if (e === "Laboratorio") {
                alignment = "left";
                value = "Laboratorio";
              }
              return (
                <TableCell
                  align={alignment}
                  key={count++}
                  className={classes.root}
                >
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {body.map((e) => (
            <Row
              key={e.lab}
              header={header}
              laboratorios_paciente={e}
              {...props}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
