import React, { useContext, useEffect, useState } from "react";
import BasicView from "../Templates/BasicView";
import ColComponent from "../Templates/ColComponent";
import CardComponent from "../Templates/CardComponent";
import PacienteDatosPersonales from "./PacienteDatosPersonales";

import HistorialConsultas from "./HistorialConsultas";
// import ConsultaGeneralMainView from "../HC/ConsultaGeneralMainView";
import ConsultaGinecologiaMainView from "./HC/ConsultaGinecologiaMainView";
import ConsultaObstetriciaMainView from "./HC/ConsultaObstetriciaMainView";
import EstudiosMainView from "./EstudiosMainView";
import LaboratoriosMainView from "./LaboratoriosMainView";
import AntecedentesView from "./Antecedentes/AntecedentesView";
import ContentTab from "../Templates/ContentTab";
import { MenuContext } from "../../components/Utils/MenuContext";
import { useConnection, useGetList } from "../../components/Utils/MyHooks";
import { config } from "../../config";
import * as print from "../../services/print";

export default function PacientesMain(props) {
  const get_data = useConnection(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);

  const list_url = [
    {
      url: config.api_pacientes + props.arg + "/",
      method: "GET",
      body: null,
      error_msg: "Error al obtener datos paciente",
      key: "paciente-data",
    },
    {
      url: config.api_embarazos_por_paciente + props.arg + "/",
      method: "GET",
      body: null,
      error_msg: "Error al obtener datos de embarazos",
      key: "paciente-embarazo",
    },
    {
      url:
        config.api_pacientes_antecedentes_obstetricos +
        "?paciente=" +
        props.arg,
      method: "GET",
      body: null,
      error_msg: "Error al obtener datos de antecedentes obstétricos",
      key: "paciente-ant-obst",
    },
    {
      url: config.api_consultas_generales + "?paciente=" + props.arg,
      method: "GET",
      body: null,
      error_msg: "Error al obtener datos de consultas generales",
      key: "paciente-consultas",
    },
    {
      url: config.api_pacientes_antecedentes + "?paciente=" + props.arg,
      method: "GET",
      body: null,
      error_msg: "Error al obtener datos de antecedentes paciente",
      key: "paciente-ant",
    },
    {
      url: config.api_pacientes_laboratorios + "?paciente=" + props.arg,
      method: "GET",
      body: null,
      error_msg: "Error al obtener datos de laboratorios paciente",
      key: "paciente-lab",
    },
    {
      url: config.api_pacientes_estudios + "?paciente=" + props.arg,
      method: "GET",
      body: null,
      error_msg: "Error al obtener datos de estudios paciente",
      key: "paciente-estudios",
    },
  ];

  useEffect(() => {
    if (props.arg) {
      get_data.list(list_url);
    }
  }, [props.arg]);

  useEffect(() => {
    let d = [];
    let e = [];
    if (get_data.status === "done") {
      if (get_data.data) {
        get_data.data.forEach((element, key) => {
          if (!element.data) {
            e[list_url[key].key] = [list_url[key].error_msg];
          } else {
            d[list_url[key].key] = element.data;
          }
        });
      } else {
        e = [get_data.error.msg];
      }

      setData(d);
      setError(e);
    }
  }, [get_data.status]);

  const menu = useContext(MenuContext);
  const print_ficha_embarazo = (embarazo_selected, number) => {
    print.fichaObstetricia(
      data["paciente-data"],
      embarazo_selected,
      number,
      data["paciente-ant"],
      data["paciente-ant-obst"]
    );
  };

  const print_ficha_general = () => {
    print.fichaGeneral(
      data["paciente-data"],
      data["paciente-consultas"],
      data["paciente-ant"],
      data["paciente-ant-obst"]
    );
  };

  let nav_data = [
    {
      id: "home",
      nav: "Inicio",
      active: false,
      item: (
        <a href="#" className="nav-link" onClick={() => menu.linkNav("home")}>
          <i className="typcn typcn-home-outline"></i> Inicio
        </a>
      ),
    },
    {
      id: "antecedentes",
      nav: "Antecedentes",
      active: true,
      content: (
        <AntecedentesView
          data_paciente={data["paciente-data"]}
          data_antecedentes={data["paciente-ant"]}
          data_antecedentes_embarazo={data["paciente-ant-obst"]}
        />
      ),
    },
    {
      id: "consulta_obstetricia",
      nav: "Obstetricia",
      content: (
        <ConsultaObstetriciaMainView
          data_paciente={data["paciente-data"]}
          data_embarazo={data["paciente-embarazo"]}
          print_ficha={(x, y) => print_ficha_embarazo(x, y)}
        />
      ),
    },
    {
      id: "consulta_ginecologia",
      nav: "Ginecologia",
      content: (
        <ConsultaGinecologiaMainView
          data_paciente={data["paciente-data"]}
          data_consulta={data["paciente-consultas"]}
          print_ficha={() => print_ficha_general()}
        />
      ),
    },

    {
      id: "archivos",
      nav: "Archivos",
      content: (
        <EstudiosMainView
          data_paciente={data["paciente-data"]}
          data_estudios={data["paciente-estudios"]}
        />
      ),
    },
    {
      id: "laboratorios",
      nav: "Laboratorios",
      content: (
        <LaboratoriosMainView
          data_paciente={data["paciente-data"]}
          data_laboratorios={data["paciente-lab"]}
        />
      ),
    },
  ];
  return (
    <BasicView
      title={""}
      error={error}
      loading={get_data.isLoading}
      nav={nav_data}
    >
      <CardComponent style={"mg-b-10"}>
        <div className="row">
          <ColComponent xl={3} lg={3} style={"pd-r-0"}>
            <PacienteDatosPersonales
              data_paciente={data["paciente-data"]}
              // data_embarazo={data["paciente-embarazo"]}
              editar={() => menu.setView("abm-pacientes", props.arg)}
            />
          </ColComponent>
          <ColComponent xl={9} lg={9} style={"pd-r-5-f"}>
            <ContentTab data={nav_data} />
          </ColComponent>
        </div>
      </CardComponent>
    </BasicView>
  );
}
