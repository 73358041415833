import React, { useEffect, useState, useContext } from "react";
import { useConnection } from "../components/Utils/MyHooks";
import { MenuContext } from "../components/Utils/MenuContext";
import { AuthContext } from "../components/Authentication/AuthComponent";
import { config } from "../config";
import ErrorView from "../views/Templates/ErrorView";
import Spinner from "../views/Templates/Spinner";

export default function MainLayout() {
  const menu = useContext(MenuContext);
  const auth = useContext(AuthContext);

  const conn = useConnection(true);
  // const groups = useConnection(true);

  const [error, setError] = useState([]);

  document.body.style = "background: none";
  const list = [
    {
      url: config.api_user_permissions,
      method: "GET",
      body: null,
    },
    {
      url: config.api_groups,
      method: "GET",
      body: null,
      error_msg: "Error al obtener permisos",
      key: "groups",
    },
  ];

  useEffect(() => {
    conn.list(list);
  }, []);

  useEffect(() => {
    let e = [];
    if (conn.status === "done") {
      if (conn.data) {
        if (conn.data[0].data) auth.setSessionData(conn.data[0].data);
        else e.push("Error al obtener datos de usuario");

        if (conn.data[1].data) auth.setGroupData(conn.data[1].data);
        else e.push("Error al obtener datos de grupos");

        setError(e);
      } else {
        setError([conn.error.msg]);
      }
    }
  }, [conn.status]);

  if (conn.isLoading) {
    return <Spinner />;
  } else {
    if (error.length > 0) {
      return (
        <ErrorView
          arg={{
            text: error,
            subtext: "Por favor intente nuevamente más tarde",
          }}
        />
      );
    } else {
      return <div className="az-body">{menu.currentView}</div>;
    }
  }
}
