import React, { useContext, useEffect, useState } from "react";
import CardComponent from "../../Templates/CardComponent";
import ContentView from "../../Templates/ContentView";
import TableEditableComponent from "../../Templates/TableEditableComponent";
import TableComponent from "../../Templates/TableComponent";
import ButtonStatus from "../../Templates/ButtonStatus";
import { useConnection } from "../../../components/Utils/MyHooks";
import { useForm } from "react-hook-form";
import { config } from "../../../config";
import * as utils from "../../../components/Utils/Utils";
import * as services from "../../../services/embarazo_services";
import TestModal from "../../Templates/TestModal";
import Swal from "sweetalert2";
import WrapperPermisos from "../../../components/Authentication/WrapperPermisos";
import { AuthContext } from "../../../components/Authentication/AuthComponent";
import MyDatePicker from "../../Templates/MyDatePicker";
import DebugErrors from "../../Templates/DebugErrors";

const NewConsulta = React.forwardRef((props, ref) => {
  const [fields, setFields] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.data) {
      setFields(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.errors) {
      setErrors(props.errors);
    }
  }, [props.errors]);

  return (
    <div className="mg-t-10">
      <div className="form form-row">
        <WrapperPermisos users={[]} groups={["propietario"]}>
          <div className="form-group col-md-2 col-lg-2 mg-b-5">
            <label className="az-content-label-sm tx-gray-600">Fecha</label>
            <MyDatePicker
              name={"fecha"}
              ref={ref({ required: true })}
              fecha={utils.inputFecha(fields["fecha"])}
            />
          </div>
        </WrapperPermisos>
      </div>
      <div className="form form-row">
        <div className="form-group col-md-4 col-lg-4">
          <label className="az-content-label-sm tx-gray-600">
            Motivo de Consulta
          </label>
          <textarea
            className={
              "form-control " + (errors["motivo"] ? "parsley-error" : "")
            }
            name="motivo"
            ref={ref({ required: true })}
            defaultValue={fields["motivo"]}
          />
        </div>
        <div className="form-group col-md-4 col-lg-4">
          <label className="az-content-label-sm tx-gray-600">Diagnóstico</label>
          <textarea
            className="form-control"
            name="diagnostico"
            ref={ref}
            defaultValue={fields["diagnostico"]}
          />
        </div>
        <div className="form-group col-md-4 col-lg-4">
          <label className="az-content-label-sm tx-gray-600">
            Indicaciones
          </label>
          <textarea
            className="form-control"
            name="indicacion"
            ref={ref}
            defaultValue={fields["indicacion"]}
          />
        </div>
      </div>
      <div className="form form-row">
        <div className="form-group col-md-12 col-lg-12">
          <label className="az-content-label-sm tx-gray-600">
            Observaciones
          </label>
          <textarea
            className="form-control"
            name="observaciones"
            ref={ref}
            defaultValue={fields["observaciones"]}
          />
        </div>
      </div>
    </div>
  );
});

export default function ConsultaGeneralMainView(props) {
  const form = useForm();
  const form_modal = useForm();
  const [fields, setFields] = useState([]);
  const save_consulta = useConnection();
  const update_consulta = useConnection();
  const del_consulta = useConnection();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [edit_data, setEditData] = useState([]);

  const [status, setStatus] = useState("");

  const auth = useContext(AuthContext);

  useEffect(() => {
    if (props.data_consulta) {
      setFields(props.data_consulta);
    }
  }, [props.data_consulta]);

  useEffect(() => {
    if (save_consulta.status === "done") {
      if (save_consulta.data) {
        setStatus("done");
        let array = [...fields, save_consulta.data];
        let ordered = array.sort((a, b) => {
          return new Date(b.fecha) - new Date(a.fecha);
        });
        setFields(ordered);
      } else {
        setStatus("");
        // error_msg(save_consulta.error.msg);
      }
    }
  }, [save_consulta.status]);

  useEffect(() => {
    if (update_consulta.status === "done") {
      if (update_consulta.data) {
        setIsOpen(false);
        setFields((prev) =>
          prev.map((o) => {
            let tmp = update_consulta.data;
            if (o.id === update_consulta.data.id) return tmp;
            return o;
          })
        );
      } else {
        setIsOpen(false);
        // error_msg(update_consulta.error.msg);
      }
    }
  }, [update_consulta.status]);

  const del_msg = (data) =>
    Swal.fire({
      title: "¿Eliminar Consulta?",
      icon: "warning",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setEditData(data);
        del_consulta.del(config.api_consultas_generales + data.id + "/");
      }
    });

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  useEffect(() => {
    if (del_consulta.status === "done") {
      if (!del_consulta.error) {
        let l = fields.filter((x) => {
          return x.id !== edit_data.id;
        });
        setFields(l);
      } else {
        // error_msg(del_consulta.error.msg);
      }
    }
  }, [del_consulta.status]);

  return (
    <ContentView title={""} error={[]} loading={false}>
      {config.debug && (
        <DebugErrors watch={[del_consulta, update_consulta, save_consulta]} />
      )}
      <TestModal
        title={"Editar Consulta"}
        isOpen={modalIsOpen}
        aceptar={
          <WrapperPermisos groups={["propietario"]} users={[edit_data.medico]}>
            <button
              type="button"
              className="btn btn-az-primary"
              onClick={form_modal.handleSubmit((x) => {
                let f = services.format_consulta_gral({
                  ...x,
                  medico: auth.getSessionData().id,
                });

                update_consulta.patch(
                  config.api_consultas_generales + edit_data.id + "/",
                  f
                );
              })}
            >
              <i className="far fa-save tx-15 mg-r-5"></i>Actualizar
            </button>
          </WrapperPermisos>
        }
        cancelar={
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </button>
        }
      >
        <NewConsulta
          data={edit_data}
          ref={form_modal.register}
          errors={form_modal.errors}
        />
      </TestModal>
      <WrapperPermisos groups={["propietario", "colaboradores"]} users={[]}>
        <CardComponent header={""}>
          <div className="az-content-label tx-12 mg-b-5">Nueva Consulta</div>
          <NewConsulta
            key={save_consulta.data ? 1 : 0}
            ref={form.register}
            errors={form.errors}
          />
          <ButtonStatus
            clase={"btn btn-az-primary float-right wd-150"}
            status={status}
            txt_success={"Guardado!"}
            txt_loading="Guardando.."
            onClick={form.handleSubmit((x) => {
              setStatus("loading");
              let f = services.format_consulta_gral({
                ...x,
                paciente: props.data_paciente.id,
                medico: auth.getSessionData().id,
              });
              save_consulta.post(config.api_consultas_generales, f);
            })}
          >
            <i className="far fa-save tx-15 mg-r-5"></i>Guardar
          </ButtonStatus>
        </CardComponent>
      </WrapperPermisos>
      <CardComponent style={"mg-t-10"} header={""}>
        <div className="d-flex">
          <div className="az-content-label tx-12">
            Listado de Consultas ({fields.length})
          </div>
          <div className="btn-icon-list float-right">
            {fields.length > 0 && (
              <button
                type="button"
                className="btn btn-az-primary btn-icon"
                onClick={() => {
                  props.print_ficha();
                }}
              >
                <i className="fas fa-print"></i>
              </button>
            )}
          </div>
        </div>
        <div
          className={"scroll"}
          style={{ height: "243px", overflowY: "auto" }}
        >
          <TableEditableComponent
            no_toolbar={true}
            sorting={true}
            print_button={false}
            download_button={false}
            paging={false}
            no_editable={true}
            max_items={5}
            data={fields}
            header={[
              {
                title: "Fecha",
                field: "fecha",
                cellStyle: { width: "15%", padding: 0 },
                render: (x) => {
                  return utils.displayFecha(x.fecha);
                },
              },
              {
                title: "Motivo",
                field: "motivo",
                cellStyle: {
                  width: "30%",
                  paddingLeft: 5,
                  paddingTop: 5,
                  paddingBottom: 5,
                },
                render: (x) => {
                  return x.motivo;
                },
              },
              {
                title: "Medico",
                field: "medico",
                cellStyle: { width: "20%", padding: 0 },
                render: (x) => {
                  return "Dr. " + x.medico_apellido + ", " + x.medico_nombres;
                },
              },
              {
                title: "Acciones",
                field: "acciones",
                cellStyle: { width: "10%", padding: 0 },
                render: (x) => {
                  return (
                    <div className="btn-group" role="group">
                      <button
                        type="button"
                        className="btn btn-icon pd-0 wd-20 mg-r-10"
                        onClick={() => {
                          setEditData(x);
                          setIsOpen(true);
                        }}
                      >
                        <i className="typcn typcn-edit tx-gray-500 tx-25-f"></i>
                      </button>
                      <WrapperPermisos
                        groups={["propietario"]}
                        users={[x.medico]}
                      >
                        <button
                          type="button"
                          className="btn btn-icon pd-0 wd-20"
                          onClick={() => {
                            del_msg(x);
                          }}
                        >
                          <i className="typcn typcn-trash tx-gray-500 tx-25-f"></i>
                        </button>
                      </WrapperPermisos>
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      </CardComponent>
    </ContentView>
  );
}
