import React, { useEffect, useState } from "react";
import ContentView from "../Templates/ContentView";
import * as utils from "../../components/Utils/Utils";
import Collapse from "@material-ui/core/Collapse";
import WrapperPermisos from "../../components/Authentication/WrapperPermisos";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

export default function PacienteDatosPersonales(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [listener, setListener] = useState();
  const [open, setOpen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", setListener);

    return () => {
      window.removeEventListener("resize", setListener);
    };
  }, []);

  useEffect(() => {
    if (props.data_paciente) {
      setData(props.data_paciente);
    }
    setLoading(false);
  }, [props.data, props.data_embarazo]);

  useEffect(() => {
    let w = 400;
    if (listener == undefined) {
      setWidth(window.innerWidth);
      if (window.innerWidth < w) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    } else {
      setWidth(listener.target.innerWidth);
      if (listener.target.innerWidth < w) {
        setOpen(false);
      } else setOpen(true);
    }
  }, [listener]);

  return (
    <ContentView error={[]} loading={loading}>
      <div
        className="az-content-left-profile"
        style={
          open ? (width < 769 ? { height: "500px" } : { height: "680px" }) : {}
        }
      >
        <div className="az-profile-overview">
          <div className="d-flex justify-content-between mg-b-10">
            <div>
              <h5 className="az-profile-name">
                {`${data.apellido}, ${data.nombres}`}
              </h5>
              {/* <p className="az-profile-name-text">{`DNI ${data.documento}`}</p> */}
            </div>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>

          <hr className="mg-y-10" />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="tx-13" id="style-scroll">
              <div className="">
                <label className="mg-b-0 tx-gray-600">
                  Fecha de Nacimiento
                </label>
                <div className="row pd-l-15">
                  <span className="tx-medium">
                    {`${utils.displayFecha(
                      utils.formatFecha(data.fecha_nacimiento)
                    )} -
                      ${data.edad} años`}
                  </span>
                  {utils.isBirthDay(data.fecha_nacimiento) && (
                    <span style={{ fontSize: "15px", marginLeft: "5px" }}>
                      &#127881;
                    </span>
                  )}
                </div>
              </div>
              <div className="mg-t-10">
                <label className="mg-b-0 tx-gray-600">Documento</label>
                <div className="row pd-l-15">
                  <span className="tx-medium">{`DNI ${data.documento}`}</span>
                </div>
              </div>
              <div className="mg-t-10">
                <label className="mg-b-0 tx-gray-600">Grupo Sanguíneo</label>
                <div className="row pd-l-15">
                  <span className="tx-medium">
                    {data.grupo_sanguineo && (
                      <span className="tx-medium">
                        {
                          utils.grupo_sanguineo.filter((x) => {
                            return x.id === data.grupo_sanguineo;
                          })[0].nombre
                        }
                      </span>
                    )}
                  </span>
                </div>
              </div>

              <div className="mg-t-10">
                <label className="mg-b-0 tx-gray-600">Domicilio</label>
                <div className="row pd-l-15">
                  <span className="tx-medium">{data.domicilio}</span>
                </div>
                <div className="row pd-l-15">
                  <span className="tx-medium">{data.localidad}</span>
                </div>
              </div>
              <div className="mg-t-10">
                <label className="mg-b-0 tx-gray-600">Teléfonos</label>
                <div className="row pd-l-15">
                  <span className="tx-medium">{data.telefono1}</span>
                  <span
                    className="az-contact-star active"
                    style={{ marginTop: "-4px", marginLeft: "5px" }}
                  >
                    <i className="typcn typcn-star"></i>
                  </span>
                </div>
                <div className="row pd-l-15">
                  <span className="tx-medium mg-t-4">{data.telefono2}</span>
                </div>
              </div>
              {/* <div className="mg-t-10">
              <label className="mg-b-0 tx-gray-600">Estado Civil</label>
              <div className="row pd-l-15">
                <span className="tx-medium">{data.estado_civil}</span>
              </div>
            </div>
            <div className="mg-t-10">
              <label className="mg-b-0 tx-gray-600">Estudios</label>
              <div className="row pd-l-15">
                <span className="tx-medium">Alfabeta</span>
                <i
                  className="typcn typcn-tick tx-20 tx-success"
                  style={{ marginTop: "-8px", marginLeft: "5px" }}
                ></i>
              </div>
              <div className="row pd-l-15">
                <span className="tx-medium">Secundario - 5 años</span>
              </div>
            </div> */}
              <div className="mg-t-10">
                <label className="mg-b-0 tx-gray-600">Obra Social</label>
                <div className="row pd-l-15">
                  <span className="tx-medium">{`${data.obra_social} - ${data.obra_social_num}`}</span>
                </div>
                <div className="row pd-l-15">
                  <span className="tx-medium">{`TOKEN ${data.obra_social_token}`}</span>
                </div>
              </div>
              <div className="mg-t-10">
                <label className="mg-b-0 tx-gray-600">Profesión</label>
                <div className="row pd-l-15">
                  <span className="tx-medium">{data.profesion}</span>
                </div>
              </div>
              <WrapperPermisos groups={["propietario"]} users={[]}>
                <div className="mg-t-20">
                  <button
                    className="btn btn-outline-light btn-block"
                    onClick={props.editar}
                  >
                    Editar
                  </button>
                </div>
              </WrapperPermisos>
            </div>
          </Collapse>
        </div>
      </div>
    </ContentView>
  );
}
