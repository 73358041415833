import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Authentication/AuthComponent";
import NavTabs from "../../views/Templates/NavTabs";
import { MenuContext } from "../Utils/MenuContext";
import { useConnection } from "../../components/Utils/MyHooks";
import { config } from "../../config";
import WrapperPermisos from "../Authentication/WrapperPermisos";

export default function NewNavBar(props) {
  const auth = useContext(AuthContext);
  const menu = useContext(MenuContext);
  const session = auth.getSessionData();
  const conn = useConnection();

  useEffect(() => {
    if (conn.status === "done") {
      if (!conn.error) {
        auth.doLogout();
      } else {
        menu.setView("error", "");
      }
    }
  }, [conn.status]);

  return (
    <div className="az-header">
      <div className="container">
        <div className="az-header-menu">
          <div className="az-header-menu-header">
            {/* <a href="index.html" className="az-logo">
              <span></span> logo
            </a> */}
            <div style={{ marginRight: "auto" }}>
              <img
                src={require("../../assets/img/Logo.png")}
                className="rounded-circle wd-50 bd bd-1 bd-gray-200"
                alt=""
              />
            </div>
            <a href="#" className="close">
              ×
            </a>
          </div>
          <NavTabs data={props.nav} />
        </div>
        <div className="">
          {/* <a href="index.html" className="az-logo">
            <span></span> azia
          </a> */}
          <a href="#" id="azMenuShow" className="az-header-menu-icon d-lg-none">
            <span></span>
          </a>
        </div>
        <div className="az-header-right">
          <span className="tx-15 tx-medium tx-az-primary">
            {session && session.first_name + " " + session.last_name}
          </span>
          <div className="dropdown az-header-notification">
            <a href="#" className="">
              <i className="typcn typcn-cog-outline"></i>
            </a>
            <div className="dropdown-menu">
              <div className="az-dropdown-header mg-b-20 d-sm-none">
                <a href="#" className="az-header-arrow">
                  <i className="icon ion-md-arrow-back"></i>
                </a>
              </div>
              <h6 className="az-notification-title">Configuración</h6>

              <div className="az-notification-list">
                <WrapperPermisos groups={["propietario"]} users={[]}>
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={() => menu.setView("abm-colaboradores", "")}
                  >
                    <i className="typcn typcn-group-outline tx-20 mg-r-5"></i>
                    Colaboradores
                  </a>
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={() => menu.setView("abm-laboratorios", "")}
                  >
                    <i className="typcn typcn-document-text tx-20 mg-r-5"></i>
                    Laboratorios
                  </a>
                </WrapperPermisos>
                <hr className="mg-y-5" />
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={() => {
                    conn.post(config.api_logout, {});
                  }}
                >
                  <i className="typcn typcn-power tx-20 mg-r-5"></i> Cerrar
                  Sesión
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
