import React, { useState, useContext } from "react";
import { AuthContext } from "./AuthComponent.jsx";
import LoginView from "../../views/LoginView.jsx";

export default function AuthenticatedApp(props) {
  console.log("No Autenticado");
  // const auth = useContext(AuthContext);
  // auth.doLogin({
  //   user: { username: "Maxi", email: "macsee@cubiqosoft.com" },
  //   token: "",
  // });
  return <LoginView />;
  // return null;
}
