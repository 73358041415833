import React, { useState } from "react";
import ErrorElement from "./ErrorElement";

export default function DebugErrors(props) {
  let count = 0;
  if (props.watch) {
    return props.watch.map((e) => {
      if (e.error)
        return (
          <div key={count++} className={"mg-t-5"}>
            <ErrorElement text={JSON.stringify(e.error.obj)} />
          </div>
        );
    });
  } else return null;
}
