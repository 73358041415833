import React, { useState, useEffect } from "react";
import Collapse from "@material-ui/core/Collapse";
import MyDatePicker from "../../../Templates/MyDatePicker";
import Switch from "../../../Templates/Switch";
import * as utils from "../../../../components/Utils/Utils";
import OptionList from "../../../Templates/OptionList";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ButtonStatus from "../../../Templates/ButtonStatus";
import Options from "../../../Templates/OptionList";
import { Height } from "@material-ui/icons";

const DatosEmbarazoActual = React.forwardRef((props, ref) => {
  const [fields, setFields] = useState([]);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    console.log(props.data);
    if (props.data) {
      setFields(props.data);
    }
    if (props.new) {
      setEdit(true);
    }
  }, [props.data]);

  return (
    <div>
      <div className="d-flex">
        <div className="az-content-label tx-12">Información Adicional</div>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setEdit(!edit)}
        >
          {edit ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>
      <Collapse in={edit} timeout="auto" unmountOnExit>
        {/* <div className="az-content-label mg-b-5 tx-12">Datos Conyuge</div> */}
        <div className="form form-row mg-t-10">
          <div className="form-group col-md-4 col-lg-4">
            <label className="az-content-label-sm tx-gray-600">
              Medico Derivante
            </label>
            <input
              style={{ textTransform: "capitalize" }}
              className="form-control"
              name="medico_derivante"
              ref={ref}
              defaultValue={fields["medico_derivante"]}
            />
          </div>
          <div className="form-group offset-md-2 col-md-3 col-lg-3">
            <label className="az-content-label-sm tx-gray-600">
              Atención Personalizada
            </label>
            <input
              className="form-control"
              name="atencion_personalizada"
              ref={ref}
              defaultValue={fields["atencion_personalizada"]}
            />
          </div>
        </div>
        <div data-label="Conyuge" className="df-example mg-b-10">
          <div className="form form-row mg-t-10">
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Apellido
              </label>
              <input
                className="form-control ht-30"
                style={{ textTransform: "capitalize" }}
                name="conyuge_apellido"
                ref={ref}
                defaultValue={fields["conyuge_apellido"]}
              />
            </div>
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">Nombres</label>
              <input
                className="form-control ht-30"
                style={{ textTransform: "capitalize" }}
                name="conyuge_nombres"
                ref={ref}
                defaultValue={fields["conyuge_nombres"]}
              />
            </div>
            <div className="form-group col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">Edad</label>
              <input
                type="number"
                className={
                  props.errors["conyuge_edad"]
                    ? "form-control ht-30 wd-60 bd bd-1 bd-danger"
                    : "form-control ht-30 wd-60"
                }
                name="conyuge_edad"
                ref={ref({
                  validate: (value) => {
                    return Number.isInteger(Number(value));
                  },
                })}
                defaultValue={fields["conyuge_edad"]}
              />
            </div>
            <div className="form-group offset-md-1 col-md-2 col-lg-2">
              <label className="az-content-label-sm tx-gray-600">
                Grupo Sanguineo
              </label>
              <select
                className="custom-select wd-60p"
                name="conyuge_grupo_sanguineo"
                ref={ref}
                value={fields["conyuge_grupo_sanguineo"] || ""}
                onChange={(event) => {
                  let e = event.target.value;
                  setFields((fields) => ({
                    ...fields,
                    conyuge_grupo_sanguineo: e,
                  }));
                }}
              >
                <OptionList
                  opcion_vacia={"-"}
                  data={utils.grupo_sanguineo}
                  name_field={(x) => {
                    return x.nombre;
                  }}
                  value_field={(x) => {
                    return x.id;
                  }}
                />
              </select>
            </div>
            <div className="form-group col-md-3 col-lg-3">
              <label className="az-content-label-sm tx-gray-600">
                Profesión
              </label>
              <input
                className="form-control"
                name="conyuge_profesion"
                ref={ref}
                defaultValue={fields["conyuge_profesion"]}
              />
            </div>
          </div>
        </div>
      </Collapse>
      <hr className="mg-t-0"></hr>
      <div className="az-content-label mg-b-5 tx-12">Datos Embarazo</div>
      <div className="form form-row">
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Nombre Bebé</label>
          <input
            type="text"
            style={{ textTransform: "capitalize" }}
            className="form-control"
            name="nombre_bebe"
            ref={ref}
            defaultValue={fields["nombre_bebe"]}
          />
        </div>
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Sexo</label>
          <select
            className="custom-select wd-50p"
            name="fin_parto_sexo"
            ref={ref}
            value={fields["fin_parto_sexo"]}
            onChange={(e) => {
              let value = e.target.value;
              setFields((fields) => ({
                ...fields,
                fin_parto_sexo: value,
              }));
            }}
          >
            <option value="">-</option>
            <option value="M">M</option>
            <option value="F">F</option>
          </select>
        </div>
      </div>
      <div className="form form-row ">
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Fertilidad</label>
          <select
            className="custom-select wd-70p"
            name="fertilidad"
            ref={ref}
            onChange={(e) => {
              let value = e.target.value;
              setFields((fields) => ({
                ...fields,
                fertilidad: value,
              }));
            }}
            value={fields["fertilidad"] || ""}
          >
            <Options
              opcion_vacia={"-"}
              data={utils.fertilidad}
              value_field={(x) => {
                return x.id;
              }}
              name_field={(x) => {
                return x.nombre;
              }}
            />
          </select>
        </div>
        {fields["fertilidad"] && fields["fertilidad"] === "T" && (
          <>
            <div className="form-group col-md-4 col-lg-4">
              <label className="az-content-label-sm tx-gray-600">
                Tratamiento
              </label>
              <select
                className="custom-select wd-70p"
                name="tipo_tratamiento"
                ref={ref}
                onChange={(e) => {
                  let value = e.target.value;
                  setFields((fields) => ({
                    ...fields,
                    tipo_tratamiento: value,
                  }));
                }}
                value={fields["tipo_tratamiento"] || ""}
              >
                <Options
                  opcion_vacia={"-"}
                  data={utils.tipo_tratamiento}
                  value_field={(x) => {
                    return x.id;
                  }}
                  name_field={(x) => {
                    return x.nombre;
                  }}
                />
              </select>
            </div>
            <div className="form-group col-md-4 col-lg-4">
              <label className="az-content-label-sm tx-gray-600">
                Fecha Tratamiento
              </label>
              <MyDatePicker
                name={"fecha_tratamiento"}
                fecha={fields["fecha_tratamiento"]}
                ref={ref}
              />
            </div>
          </>
        )}
      </div>
      <div className="form form-row ">
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">
            Peso Anterior (Kg)
          </label>
          <input
            type="number"
            className="form-control wd-40p"
            name="peso_anterior"
            ref={ref}
            defaultValue={fields["peso_anterior"]}
          />
        </div>
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Talla (Cm)</label>
          <input
            type="number"
            className="form-control wd-40p"
            name="talla"
            ref={ref}
            defaultValue={fields["talla"]}
          />
        </div>
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Fecha U.M.</label>
          <div
            style={{ width: "max-content" }}
            className={props.errors["fum"] ? "bd bd-1 bd-danger" : ""}
          >
            <MyDatePicker
              name={"fum"}
              ref={ref({ required: true })}
              fecha={fields["fum"]}
            />
          </div>
        </div>
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">Fecha P.P.</label>
          <div
            style={{ width: "max-content" }}
            className={props.errors["fpp"] ? "bd bd-1 bd-danger" : ""}
          >
            <MyDatePicker
              name={"fpp"}
              ref={ref({ required: true })}
              fecha={fields["fpp"]}
            />
          </div>
        </div>
      </div>
      <div className="form form-row mg-t-10">
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">ATT Previa</label>
          <div className="d-flex">
            <label className="rdiobox wd-40p">
              <input
                name="antitetanica"
                type="radio"
                id="rdio_att"
                ref={ref}
                value={true}
                onChange={() =>
                  setFields((fields) => ({ ...fields, antitetanica: true }))
                }
                checked={fields["antitetanica"] === true}
              />
              <span className="tx-12 pd-l-8-f">SI</span>
            </label>
            <label className="rdiobox wd-50p">
              <input
                name="antitetanica"
                type="radio"
                id="rdio_att"
                ref={ref}
                value={false}
                onChange={() =>
                  setFields((fields) => ({ ...fields, antitetanica: false }))
                }
                checked={fields["antitetanica"] === false}
              />
              <span className="tx-12 pd-l-8-f">NO</span>
            </label>
          </div>
        </div>
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">
            ATT Actual (Mes de Gesta)
          </label>
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="d-flex justify-content-between">
                <span className="mg-r-10 tx-medium">1°</span>
                <select
                  className="custom-select"
                  name="antitetanica_actual_1"
                  ref={ref}
                  value={fields["antitetanica_actual_1"] || ""}
                  onChange={(e) => {
                    let value = e.target.value;
                    setFields((fields) => ({
                      ...fields,
                      antitetanica_actual_1: value,
                    }));
                  }}
                >
                  <option value="">-</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="d-flex justify-content-between">
                <span className="mg-r-10 tx-medium">2°</span>
                <select
                  className="custom-select"
                  name="antitetanica_actual_2"
                  ref={ref}
                  value={fields["antitetanica_actual_2"] || ""}
                  onChange={(e) => {
                    let value = e.target.value;
                    setFields((fields) => ({
                      ...fields,
                      antitetanica_actual_2: value,
                    }));
                  }}
                >
                  <option value="">-</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group offset-md-1 col-md-2 col-lg-2">
          <label className="az-content-label-sm tx-gray-600">
            Sensibilidad RH
          </label>
          <Switch
            name={"sensibil_rh"}
            ref={ref}
            defaultValue={fields["sensibil_rh"]}
          />
        </div>
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">
            Embarazo de Riesgo
          </label>
          <Switch
            name={"riesgoso"}
            ref={ref}
            defaultValue={fields["riesgoso"]}
          />
        </div>
      </div>
      <div className="form form-row mg-t-10">
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">
            Examen Mamas Normal
          </label>
          <div className="d-flex">
            <label className="rdiobox wd-40p">
              <input
                type="radio"
                id="rdio_mamas"
                name="examen_mama"
                ref={ref}
                value={true}
                onChange={() =>
                  setFields((fields) => ({ ...fields, examen_mama: true }))
                }
                checked={fields["examen_mama"] === true}
              />
              <span className="tx-12 pd-l-8-f">SI</span>
            </label>
            <label className="rdiobox wd-50p">
              <input
                type="radio"
                id="rdio_mamas"
                name="examen_mama"
                ref={ref}
                value={false}
                onChange={() =>
                  setFields((fields) => ({ ...fields, examen_mama: false }))
                }
                checked={fields["examen_mama"] === false}
              />
              <span className="tx-12 pd-l-8-f">NO</span>
            </label>
          </div>
        </div>
        <div className="form-group col-md-3 col-lg-3">
          <label className="az-content-label-sm tx-gray-600">
            Examen PAP Normal
          </label>
          <div className="d-flex">
            <label className="rdiobox wd-40p">
              <input
                name="examen_pap"
                type="radio"
                id="rdio_pap"
                ref={ref}
                value={true}
                onChange={() =>
                  setFields((fields) => ({ ...fields, examen_pap: true }))
                }
                checked={fields["examen_pap"] === true}
              />
              <span className="tx-12 pd-l-8-f">SI</span>
            </label>
            <label className="rdiobox wd-50p">
              <input
                name="examen_pap"
                type="radio"
                id="rdio_pap"
                ref={ref}
                value={false}
                onChange={() =>
                  setFields((fields) => ({ ...fields, examen_pap: false }))
                }
                checked={fields["examen_pap"] === false}
              />
              <span className="tx-12 pd-l-8-f">NO</span>
            </label>
          </div>
        </div>

        {!props.new && (
          <div className="form-group col-md-3 col-lg-3">
            <label className="az-content-label-sm tx-gray-600">
              Embarazo Estado
            </label>
            <select
              className="custom-select wd-80p"
              name="estado"
              ref={ref}
              value={fields["estado"] === "A" ? "F" : fields["estado"] || "P"}
              onChange={(e) => {
                let value = e.target.value;
                setFields((fields) => ({
                  ...fields,
                  estado: value,
                }));
              }}
            >
              <option value="P">En Proceso</option>
              <option value="F">Finalizado</option>
              <option value="N">No Continua</option>
            </select>
          </div>
        )}
      </div>

      <Collapse
        in={fields["estado"] === "F" || fields["estado"] === "A"}
        timeout="auto"
        unmountOnExit
      >
        <div className="az-content-label mg-b-5 mg-t-10 tx-12">Parto</div>
        <div className="form form-row">
          <div className="form-group col-md-3 col-lg-3">
            <label className="az-content-label-sm tx-gray-600">
              Terminación
            </label>
            <select
              className="custom-select wd-70p"
              name="fin_parto_tipo"
              ref={ref}
              onChange={(e) => {
                let value = e.target.value;
                setFields((fields) => ({
                  ...fields,
                  fin_parto_tipo: value,
                }));
              }}
              value={fields["fin_parto_tipo"] || ""}
            >
              <Options
                opcion_vacia={"-"}
                data={utils.terminaciones_embarazo}
                value_field={(x) => {
                  return x.id;
                }}
                name_field={(x) => {
                  return x.nombre;
                }}
              />
            </select>
          </div>
          <div className="form-group col-md-3 col-lg-3">
            <label className="az-content-label-sm tx-gray-600">
              Fecha Terminación
            </label>
            <MyDatePicker
              name={"fin_parto_fecha"}
              ref={ref}
              fecha={fields["fin_parto_fecha"]}
            />
          </div>

          {fields["fin_parto_tipo"] !== "A" && fields["fin_parto_tipo"] !== "" && (
            <div className="form-group col-md-3 col-lg-3">
              <label className="az-content-label-sm tx-gray-600">
                Nacido Vivo
              </label>
              <div className="d-flex">
                <label className="rdiobox wd-40p">
                  <input
                    ref={ref}
                    name="fin_parto_nacido_vivo"
                    type="radio"
                    id="fin_parto_nacido_vivo"
                    value={true}
                    onChange={() =>
                      setFields((fields) => ({
                        ...fields,
                        fin_parto_nacido_vivo: true,
                      }))
                    }
                    checked={fields["fin_parto_nacido_vivo"] === true}
                  />
                  <span className="tx-12 pd-l-8-f">SI</span>
                </label>
                <label className="rdiobox wd-50p">
                  <input
                    ref={ref}
                    name="fin_parto_nacido_vivo"
                    type="radio"
                    id="fin_parto_nacido_vivo"
                    value={false}
                    onChange={() =>
                      setFields((fields) => ({
                        ...fields,
                        fin_parto_nacido_vivo: false,
                      }))
                    }
                    checked={fields["fin_parto_nacido_vivo"] === false}
                  />
                  <span className="tx-12 pd-l-8-f">NO</span>
                </label>
              </div>
            </div>
          )}
          <div className="form-group col-md-3 col-lg-3">
            <label className="az-content-label-sm tx-gray-600">Dar Alta</label>
            <Switch
              name={"alta"}
              ref={ref}
              callback={() => {
                setFields((fields) => ({
                  ...fields,
                  estado: "A",
                }));
              }}
              defaultValue={fields["estado"] === "A"}
            />
          </div>
        </div>

        {fields["fin_parto_tipo"] !== "A" &&
          fields["fin_parto_tipo"] !== "" &&
          fields["fin_parto_nacido_vivo"] && (
            <>
              <div className="az-content-label mg-b-5 mg-t-10 tx-12">
                Recién Nacido
              </div>
              <div className="form form-row">
                <div className="form-group col-md-3 col-lg-3">
                  <label className="az-content-label-sm tx-gray-600">
                    Peso (Kg)
                  </label>
                  <input
                    type="number"
                    className="form-control wd-40p"
                    name="fin_parto_peso"
                    ref={ref}
                    defaultValue={fields["fin_parto_peso"]}
                  />
                </div>
                <div className="form-group col-md-4 col-lg-4">
                  <label className="az-content-label-sm tx-gray-600">
                    Patologías
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="fin_parto_patologia"
                    ref={ref}
                    defaultValue={fields["fin_parto_patologia"]}
                  />
                </div>
              </div>
            </>
          )}
      </Collapse>
      <div className="form form-row">
        <div className="form-group col-md-6 col-lg-6">
          <div className="az-content-label mg-t-5 mg-b-10 tx-12">
            Observaciones Embarazo
          </div>
          <textarea
            className="form-control"
            name="observaciones"
            ref={ref}
            defaultValue={fields["observaciones"]}
          />
        </div>
      </div>
    </div>
  );
});

export default DatosEmbarazoActual;
