import React, { useState, useEffect } from "react";
import { config } from "../../config.js";
import ContentView from "../../views/Templates/ContentView.jsx";
const AuthContext = React.createContext();

function AuthComponent(props) {
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(checkLoginStatus());
  // const [token, setToken] = useState(getToken());
  const [session_data, setSessionData] = useState(null);
  const [group_data, setGroupData] = useState(null);

  function checkLoginStatus() {
    return sessionStorage.getItem("isLoggedIn");
  }

  function getToken() {
    return sessionStorage.getItem("token");
  }

  function getGroupData() {
    return group_data;
  }

  function getGroupDataName(id) {
    let tmp = [];
    if (group_data) {
      tmp = group_data.filter((x) => {
        return x.id === id;
      });
    }

    if (tmp.length > 0) return tmp[0].name;
    else return "";
  }

  function getSessionData() {
    return session_data;
  }

  function setToken(data) {
    sessionStorage.setItem("token", data.key);
  }

  function doLogin() {
    console.log("Log in....");
    sessionStorage.setItem("isLoggedIn", true);
    setLoggedIn(true);
  }

  function doLogout() {
    console.log("Log out....");
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("token");
    setLoggedIn(false);
  }

  return (
    <AuthContext.Provider
      value={{
        doLogin,
        doLogout,
        getToken,
        setToken,
        isLoggedIn,
        checkLoginStatus,
        getSessionData,
        setSessionData,
        setGroupData,
        getGroupData,
        getGroupDataName,
      }}
    >
      <ContentView error={[]} loading={loading}>
        {props.children}
      </ContentView>
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthComponent };
