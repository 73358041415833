let server = "https://drkuran.ddns.net";
// let server = "http://localhost:3000";
let urlv1 = server + "/api/v1";
let urlv2 = server + "/api/v2";
let auth = server + "/dj-rest-auth";

let config = {
  debug: true,
  server: server,
  api_antecedentes: urlv1 + "/antecedentes/",
  api_medicos: urlv1 + "/medicos/",
  api_consultas_generales: urlv1 + "/consultas_generales/",
  api_consultas_embarazos: urlv1 + "/consultas_embarazos/",
  api_laboratorios: urlv1 + "/laboratorios/",
  api_pacientes: urlv1 + "/pacientes/",
  api_pacientes_busqueda: urlv1 + "/get_pacientes/",
  api_pacientes_antecedentes: urlv1 + "/pacientes_antecedentes/",
  api_pacientes_antecedentes_obstetricos:
    urlv1 + "/pacientes_antecedentes_obstetricos/",
  api_pacientes_laboratorios: urlv1 + "/pacientes_laboratorios/",
  api_pacientes_estudios: urlv1 + "/estudios/",
  api_embarazos: urlv1 + "/embarazos/",
  api_embarazos_month: urlv1 + "/embarazos_by_month/",
  api_embarazos_por_paciente: urlv1 + "/embarazos_by_paciente/",
  api_login: auth + "/login/",
  api_logout: auth + "/logout/",
  api_user_permissions: auth + "/user/",
  api_users: urlv1 + "/users/",
  api_groups: urlv1 + "/groups/",
  api_change_pass: auth + "/password/change/",
};

export { config };
