import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    marginRight: "10px",
  },
}));

export default function ButtonStatus(props) {
  const [content, setContent] = useState("");
  const [disabled, setDisabled] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setContent(props.children);
  }, []);

  useEffect(() => {
    switch (props.status) {
      case "loading":
        setDisabled(true);
        setContent(
          <>
            <CircularProgress size={15} classes={{ root: classes.root }} />
            {props.txt_loading}
          </>
        );
        break;
      case "done":
        setDisabled(true);
        if (props.txt_error) {
          setContent(
            <>
              <i className="fas fa-exclamation-circle tx-15 mg-r-5"></i>
              {props.txt_error}
            </>
          );
        } else {
          setContent(
            <>
              <i className="far fa-check-circle tx-15 mg-r-5"></i>
              {props.txt_success}
            </>
          );
        }
        break;
      default:
        setContent(props.children);
        setDisabled(false);
        break;
    }
  }, [props.status, props.error]);

  useEffect(() => {
    if (props.status === "done") {
      setTimeout(function () {
        setContent(props.children);
        setDisabled(false);
      }, 1300);
    }
  }, [props.status]);

  return (
    <button
      type="button"
      className={props.clase}
      {...props}
      disabled={disabled}
    >
      {content}
    </button>
  );
}
