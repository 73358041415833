import React, { useContext, useState, useEffect } from "react";
import { MenuContext } from "../../components/Utils/MenuContext";
import ContentView from "./ContentView";
import NewNavBar from "../../components/NavBar/NewNabBar";
import loadJS from "../../assets/js/azia.js";
// import Spinner from "./Spinner";

const BreadCrumbComponent = () => {
  const menu = useContext(MenuContext);

  let html = [];
  let count = 2;
  let element = menu.history;

  while (element) {
    let view = element.view;
    let arg = element.arg;
    html.push(
      <span key={count++}>
        <a
          href="#"
          onClick={() => {
            menu.setView(view, arg);
          }}
        >
          {element.breadcrumb}
        </a>
      </span>
    );
    element = element.child;
  }

  return <div className="az-content-breadcrumb">{html}</div>;
};

export default function BasicView(props) {
  useEffect(() => {
    loadJS();
  }, []);

  return (
    <>
      <NewNavBar nav={props.nav} />
      <div className="az-content az-content-profile">
        <div className="container mn-ht-100p">
          <div className="az-content-body">
            {/* <BreadCrumbComponent /> */}
            {!props.loading && (
              <div className="az-dashboard-one-title">
                <div>
                  <h2 className="az-dashboard-title">{props.title}</h2>
                </div>
              </div>
            )}
            <ContentView {...props}>{props.children}</ContentView>
          </div>
        </div>
      </div>
    </>
  );
}
