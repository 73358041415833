import React, { useState, useEffect } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import * as utils from "../../components/Utils/Utils";
import es from "date-fns/locale/es";

const defaultMaterialTheme = createMuiTheme({
  // overrides: {
  //   MuiInputBase: {
  //     root: {
  //       border: "1px solid #cdd4e0!important",
  //       borderRadius: "0!important",
  //     },
  //   },
  //   MuiOutlinedInput: {
  //     root: {
  //       "&$focused $notchedOutline": {
  //         border: "none",
  //         borderRadius: "0!important",
  //       },
  //     },
  //   },
  // },
  palette: {
    primary: {
      main: "#642D6D",
    },
  },
});

const errorMaterialTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        border: "1px solid red!important",
        borderRadius: "0!important",
      },
    },
  },
  palette: {
    primary: {
      main: "#642D6D",
    },
  },
});

const MyDatePicker = React.forwardRef((props, ref) => {
  const [startDate, setStartDate] = useState(null);
  const [theme, setTheme] = useState(defaultMaterialTheme);

  useEffect(() => {
    if (props.fecha) {
      setStartDate(utils.formatFecha(props.fecha));
    }
  }, [props.fecha]);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <DatePicker
          inputVariant="outlined"
          invalidDateMessage="Fecha Inválida"
          margin="normal"
          id={props.name}
          format="dd/MM/yyyy"
          value={startDate}
          disabled={props.disabled}
          onChange={(e) => {
            // setTheme(defaultMaterialTheme);
            setStartDate(e);
          }}
        />
      </MuiPickersUtilsProvider>
      <input
        name={props.name}
        type="hidden"
        defaultValue={startDate}
        ref={ref}
      />
    </ThemeProvider>
  );
});

export default MyDatePicker;
