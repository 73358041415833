import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../components/Utils/MenuContext";
import { config } from "../config.js";
import { useConnection } from "../components/Utils/MyHooks";
import * as utils from "../components/Utils/Utils";
import Options from "./Templates/OptionList";
import ContentView from "./Templates/ContentView.jsx";
import ErrorElement from "./Templates/ErrorElement";

export default function ActivityPanel(props) {
  const [list_select, setListSelect] = useState([]);
  const [selected_month, setSelectedMonth] = useState();
  const [active_list, setActiveList] = useState(null);
  const [error, setError] = useState([]);
  const menu = useContext(MenuContext);

  useEffect(() => {
    let l = [];
    let e = [];
    let max = { cant: 0, key: 0 };
    if (props.data_panel.status === "done") {
      if (props.data_panel.data) {
        props.data_panel.data.forEach((element, key) => {
          if (element.data) {
            if (max.cant === 0) max = { cant: element.data.length, key: key };

            l.push({
              month: props.array_month[key].getMonth(),
              key: key,
              cant: element.data.length,
              txt:
                utils.meses_txt[props.array_month[key].getMonth()] +
                " " +
                props.array_month[key].getFullYear(),
            });
          } else {
            e = [
              "Error al obtener datos del mes " +
                utils.meses_txt[props.array_month[key].getMonth()],
            ];
          }
        });
      } else {
        e = [props.data_panel.error.msg];
      }

      setListSelect(l);
      setError(e);

      if (max.cant === 0) {
        l = l.filter((e) => {
          return e.month === new Date().getMonth();
        });

        if (l[0]) setSelectedMonth(l[0].key);
      } else {
        setSelectedMonth(max.key);
      }
    }
  }, [props.data_panel.status]);

  useEffect(() => {
    if (props.data_panel.data && props.data_panel.data[selected_month]) {
      setActiveList(props.data_panel.data[selected_month]);
    }
  }, [selected_month]);

  const ListEmbarazos = (props) => {
    let html = [];
    const badge = (num) => {
      if (num < 25) {
        return "tx-success";
      }
      if (num >= 25 && num < 30) {
        return "tx-warning";
      }
      if (num >= 30) {
        return "tx-danger";
      }
      return "";
    };

    const risk_indicator = (value) => {
      if (value) return "event-indicator bg-danger";
      else return "event-indicator bg-azia";
    };

    props.data.forEach((element) => {
      let semanas = Math.round(
        (new Date() - new Date(element.fum)) / (7 * 24 * 60 * 60 * 1000)
      );
      html.push(
        <div
          key={element.id}
          className="list-group-item list-embarazos cursor-pointer"
          onClick={() => menu.setView("pacientes", element.id_paciente)}
        >
          <div className={risk_indicator(element.riesgoso)}></div>
          <label>
            <span>FPP </span>
            {utils.displayFecha(utils.formatFecha(element.fpp))}
          </label>
          <h6>{`${element.apellido}, ${element.nombres}`}</h6>
          <p className="tx-12">{element.telefono1}</p>
          <small>
            <span className={badge(semanas)}>{semanas}</span> Semanas
          </small>
        </div>
      );
    });
    if (html.length > 0)
      return (
        <div className="list-group ht-500 overflow-y-auto list-emb-activos">
          {html}
        </div>
      );
    else {
      if (error.length > 0) {
        return <ErrorElement text={error[0].msg} />;
      } else {
        return (
          <div className="tx-center">
            <i>Sin embarazos programados para el mes</i>
          </div>
        );
      }
    }
  };

  return (
    <ContentView error={[]} loading={false}>
      <div className="card card-dashboard-events" style={{ height: "725px" }}>
        <div className="card-header border-bottom d-flex align-items-center justify-content-between">
          <h5 className="card-subtitle">Embarazos Activos</h5>
        </div>
        <div className="pd-20">
          <select
            className="custom-select ht-40 wd-100p"
            tabIndex="-1"
            onChange={(e) => setSelectedMonth(e.target.value)}
            value={selected_month}
          >
            <Options
              data={list_select}
              value_field={(x) => {
                return x.key;
              }}
              name_field={(x) => {
                return x.txt + " (" + x.cant + ")";
              }}
            />
          </select>
        </div>
        <div className="card-body">
          <ListEmbarazos data={active_list ? active_list.data : []} />
        </div>
      </div>
    </ContentView>
  );
}
