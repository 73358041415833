import React, { useState, useEffect } from "react";
import { config } from "../../config";
import * as ver from "../../assets/version.json";
// export const MyContext = React.createContext();

// When we use async/await, we rarely need .then, because await handles the waiting for us.
// And we can use a regular try..catch instead of .catch. That’s usually (not always) more convenient.

// But at the top level of the code, when we’re outside of any async function,
// we’re syntactically unable to use await, so it’s a normal practice to add
// .then/catch to handle the final result or falling-through errors.

let meses_txt = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const getCommons = (id, tabla, field = "") => {
  let data = JSON.parse(sessionStorage.getItem(tabla));
  let obj = null;
  if (data) {
    data.forEach((element) => {
      if (element.id === id)
        if (field) obj = element[field];
        else obj = element;
    });
  }
  return obj;
};

const getCommonsAll = (tabla, filter) => {
  let data = [];
  data = JSON.parse(sessionStorage.getItem(tabla));
  return data.filter(function (element, key) {
    return filter(element);
  });
};

const getFileIcon = (file) => {
  let ext = file.split(".").pop();
  let sub = "";
  let icon = "";

  if (ext.includes("doc")) {
    ext = "word";
  }

  if (ext.includes("xls")) {
    ext = "excel";
  }

  if (ext.includes("ppt")) {
    ext = "powerpoint";
  }

  if (["avi", "mp4", "mpg", "mpeg"].includes(ext)) {
    ext = "video";
  }

  switch (ext) {
    case "pdf":
      sub = "Documento PDF";
      icon = <i className="far fa-file-pdf tx-danger"></i>;
      break;
    case "word":
      sub = "Documento de Word";
      icon = <i className="far fa-file-word tx-primary"></i>;
      break;
    case "excel":
      sub = "Planilla de Excel";
      icon = <i className="far fa-file-excel tx-success"></i>;
      break;
    case "powerpoint":
      sub = "Presentación PowerPoint";
      icon = <i className="far fa-file-powerpoint tx-orange"></i>;
      break;
    case "video":
      sub = "Archivo Multimedia";
      icon = <i className="far fa-file-video tx-pink"></i>;
      break;
    default:
      sub = "Archivo Desconocido";
      icon = <i className="far fa-file-alt tx-gray-600"></i>;
      break;
  }

  return { icon: icon, subtitle: sub };
};

const formatFecha = (data) => {
  if (data) return new Date(data + "T00:00:00");
  else {
    let d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  }
};

const inputFecha = (data) => {
  let date = new Date();
  if (data) date = new Date(data);
  return (
    date.getFullYear().toString() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, 0) +
    "-" +
    date.getDate().toString().padStart(2, 0)
  );
};

const displayFecha = (data) => {
  let date = new Date();
  if (data) date = new Date(data);

  return (
    date.getDate().toString().padStart(2, 0) +
    "/" +
    (date.getMonth() + 1).toString().padStart(2, 0) +
    "/" +
    date.getFullYear().toString()
  );
};

const formatNumber = (number) => {
  return parseFloat(number).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const printFormdata = (form_data) => {
  for (var pair of form_data.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }
};

const formatString = (value) => {
  if (typeof value !== "string") {
    return value;
  } else {
    if (value === undefined || value === "") return "";
    else return value.replace(/^\s+|\s+$/g, "");
  }
};

const toTitleCase = (string) => {
  if (typeof string !== "string") return string;

  if (string === undefined) return "";

  if (string === "") return string;

  var sentence = string.trim();
  sentence = sentence.toLowerCase().split(" ");

  var temp = [];
  for (var i = 0; i < sentence.length; i++) {
    if (sentence[i] !== "")
      temp.push(sentence[i][0].toUpperCase() + sentence[i].slice(1));
  }
  return temp.join(" ");
};

const fertilidad = [
  { id: "E", nombre: "Espontáneo" },
  { id: "T", nombre: "Tratamiento" },
];

const tipo_tratamiento = [
  { id: "INS", nombre: "Inseminación" },
  { id: "FIV", nombre: "F.I.V." },
  { id: "IOP", nombre: "I.C.S.I. Con Óvulos Própios" },
  { id: "IOD", nombre: "I.C.S.I. Con Ovodonación" },
];

const grupo_sanguineo = [
  { id: 1, nombre: "A+" },
  { id: 2, nombre: "A-" },
  { id: 3, nombre: "B+" },
  { id: 4, nombre: "B-" },
  { id: 5, nombre: "AB+" },
  { id: 6, nombre: "AB-" },
  { id: 7, nombre: "0+" },
  { id: 8, nombre: "0-" },
];

const estudios = [
  { id: 1, nombre: "Ultrasonido" },
  { id: 2, nombre: "Informe" },
];

const estados_embarazo = [
  { id: "A", nombre: "Alta" },
  { id: "P", nombre: "En Proceso" },
  { id: "F", nombre: "Finalizado" },
  { id: "N", nombre: "No Continua" },
];

const terminaciones_embarazo = [
  { id: "A", nombre: "Aborto" },
  { id: "V", nombre: "Vaginal" },
  { id: "C", nombre: "Cesarea" },
  { id: "F", nombre: "Forceps" },
];

const presentaciones_embarazo = [
  { id: "Tr", nombre: "Transversal" },
  { id: "Pe", nombre: "Pélvico" },
  { id: "Ce", nombre: "Cefálico" },
  { id: "Po", nombre: "Podálica" },
  { id: "Ob", nombre: "Oblicua" },
];

const fcf_embarazo = [
  { id: "P", nombre: "Positiva" },
  { id: "N", nombre: "Negativa" },
  { id: "T", nombre: "Taquicardia" },
  { id: "B", nombre: "Bradicardia" },
  { id: "A", nombre: "Arritmia" },
];

const createListEmbarazosByMonth = () => {
  let max = 12;
  let array = [];
  for (let index = -1; index < max; index++) {
    let date = new Date();
    let d = new Date(date.setMonth(date.getMonth() + index));
    array.push(d);
  }
  return array;
};

const getVersion = () => {
  return ver.default;
};

const calcularEdad = (fecha) => {
  var dob = formatFecha(fecha);
  var today = formatFecha();
  var yearDiff = today.getFullYear() - dob.getFullYear();
  var monthDiff = today.getMonth() - dob.getMonth();
  var daysDiff = today.getDate() - dob.getDate();

  if (monthDiff < 0) yearDiff = yearDiff - 1;
  else if (daysDiff < 0) yearDiff = yearDiff - 1;

  return yearDiff;
};

const isBirthDay = (fecha) => {
  var dob = formatFecha(fecha);
  var today = formatFecha();

  return (
    dob.getDate() === today.getDate() && dob.getMonth() === today.getMonth()
  );
};

export {
  getCommons,
  getCommonsAll,
  getFileIcon,
  formatFecha,
  inputFecha,
  formatNumber,
  printFormdata,
  displayFecha,
  toTitleCase,
  formatString,
  calcularEdad,
  isBirthDay,
  meses_txt,
  grupo_sanguineo,
  estudios,
  createListEmbarazosByMonth,
  estados_embarazo,
  terminaciones_embarazo,
  presentaciones_embarazo,
  fcf_embarazo,
  fertilidad,
  tipo_tratamiento,
  getVersion,
};
