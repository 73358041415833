import React, { useState, useEffect, useContext } from "react";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { config } from "../../config.js";
import * as utils from "../../components/Utils/Utils";
import ColComponent from "./ColComponent.jsx";

export default function TableEditableComponent(props) {
  useEffect(() => {
    // console.log("cargando la tabla");
  });

  const tableIcons = {
    Check: () => (
      <svg
        viewBox="0 0 24 24"
        width="18"
        height="18"
        stroke="#8392a5"
        strokeWidth="2"
        fill="none"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    ),
    Clear: () => (
      <svg
        viewBox="0 0 24 24"
        width="18"
        height="18"
        stroke="#8392a5"
        strokeWidth="2"
        fill="none"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    ),
    Cancel: () => (
      <svg
        viewBox="0 0 24 24"
        width="18"
        height="18"
        stroke="#8392a5"
        strokeWidth="2"
        fill="none"
      >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
        <line x1="9" y1="9" x2="15" y2="15"></line>
        <line x1="15" y1="9" x2="9" y2="15"></line>
      </svg>
    ),
    Add: () => (
      <div className="d-flex align-items-center btn btn-outline-primary btn-icon">
        <svg
          viewBox="0 0 24 24"
          width="24"
          height="24"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
        <div className="tx-13">{props.add_text}</div>
      </div>
    ),
    Delete: () => (
      <svg
        viewBox="0 0 24 24"
        width="18"
        height="18"
        stroke="#8392a5"
        strokeWidth="2"
        fill="none"
      >
        <polyline points="3 6 5 6 21 6"></polyline>
        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
      </svg>
    ),
    Edit: () => (
      <svg
        viewBox="0 0 24 24"
        width="18"
        height="18"
        stroke="#8392a5"
        strokeWidth="2"
        fill="none"
      >
        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
      </svg>
    ),
    FirstPage: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="#8392a5"
      >
        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
      </svg>
    ),
    LastPage: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="#8392a5"
      >
        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" />
      </svg>
    ),
    NextPage: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        stroke="#8392a5"
        strokeWidth="2"
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    ),
    PreviousPage: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        stroke="#8392a5"
        strokeWidth="2"
      >
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      </svg>
    ),
    SortArrow: (props) => (
      <span {...props}>
        <svg
          viewBox="0 0 24 24"
          width="18"
          height="18"
          stroke="#8392a5"
          strokeWidth="2"
          fill="none"
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </span>
    ),
    Search: React.forwardRef((props, ref) => (
      <span {...props} ref={ref}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#8392a5"
          strokeWidth="2"
        >
          <circle cx="10" cy="10" r="5"></circle>
          <line x1="19" y1="19" x2="14" y2="14"></line>
        </svg>
      </span>
    )),
    ResetSearch: React.forwardRef((props, ref) => (
      <span {...props} ref={ref}>
        <svg
          viewBox="0 0 24 24"
          width="20"
          height="20"
          stroke="#8392a5"
          strokeWidth="2"
          fill="none"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </span>
    )),
  };

  const actions = {
    onRowAdd: (newData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          {
            props.add_function(newData);
          }
          resolve();
        }, 1000);
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          {
            props.edit_function(newData);
          }
          resolve();
        }, 1000);
      }),
    onRowDelete: (oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          {
            props.delete_function(oldData);
          }
          resolve();
        }, 1000);
      }),
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#0168fa",
        light: "#0000ff00",
      },
      secondary: {
        main: "#0168fa",
        light: "#0000ff00",
      },
    },
  });

  let all_button =
    props.callback_selection === undefined ? false : props.callback_selection;
  let print_button =
    props.print_button === undefined ? true : props.print_button;
  let download_button =
    props.download_button === undefined ? true : props.download_button;

  let filter = props.filter === undefined ? false : props.filter;

  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        key={props.always_refresh ? Date.now() : "editable_table"}
        columns={props.header}
        data={JSON.parse(JSON.stringify(props.data))}
        onRowClick={(event, rowData, togglePanel) => {
          if (props.callback_row) return props.callback_row(rowData);
        }}
        components={{
          Container: (props) => <div {...props} />,
          Toolbar: (props) => (
            <div className="ht-70">
              <div className="float-right">
                <MTableToolbar {...props} />
              </div>
              {print_button && (
                <div className="float-right mg-l-5">
                  <button
                    className="btn btn-light"
                    onClick={() =>
                      console.log("Imprimiendo Datos....", props.data)
                    }
                    title="Imprimir"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="6 9 6 2 18 2 18 9"></polyline>
                      <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path>
                      <rect x="6" y="14" width="12" height="8"></rect>
                    </svg>
                  </button>
                </div>
              )}
              {download_button && (
                <div className="float-right mg-l-5">
                  <button
                    className="btn btn-light"
                    onClick={() =>
                      console.log("Descargando datos a csv...", props.data)
                    }
                    title="Descargar"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                      <polyline points="7 10 12 15 17 10"></polyline>
                      <line x1="12" y1="15" x2="12" y2="3"></line>
                    </svg>
                  </button>
                </div>
              )}
              {all_button &&
                all_button(
                  props.selectedRows.filter((e) => {
                    return !filter(e);
                  })
                )}
            </div>
          ),
        }}
        title={""}
        icons={tableIcons}
        editable={props.no_editable ? {} : actions}
        // onSelectionChange={(rows) => {
        //   return rows.filter((e) => {
        //     return e.inscripcion.alta_academica && e.inscripcion.alta_contable;
        //   });
        // }}
        options={{
          selectionProps: (x) => {
            if (props.filter(x))
              return {
                disabled: true,
                checked: false,
              };
          },
          searchText: props.defaultSearch,
          selection: props.selection,
          toolbar: !props.no_toolbar,
          pageSize: props.max_items ? props.max_items : 10,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          headerStyle: {
            paddingLeft: 0,
            // fontFamily:
            //   '-apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif',
            // fontSize: 10,
            // fontWeight: 500,
            // letterSpacing: 0.5,
            // textTransform: "uppercase",
            // color: "#8392a5",
          },
          cellStyle: {
            padding: 0,
            height: 50,
            // paddingRight: 10,
            // fontFamily: '"IBM Plex Sans", sans-serif',
            // fontSize: props.font ? props.font : 13,
            // color: "#001737",
            // textAlign: "left",
          },
          searchFieldStyle: {
            padding: 0,
            height: 40,
            paddingRight: 10,
            fontFamily: '"IBM Plex Sans", sans-serif',
            fontSize: props.font ? props.font : 13,
            color: "#001737",
            textAlign: "left",
          },
          rowStyle: {
            height: 50,
          },
          // rowStyle: (data, index) => {
          //   if (index % 2) {
          //     return { backgroundColor: "#f3f4f7" };
          //   }
          // },
          sorting: props.sorting ? props.sorting : false,
          search: props.search ? props.search : false,
          paging: props.paging ? props.paging : false,
        }}
        localization={{
          header: { actions: "Acciones" },
          body: {
            emptyDataSourceMessage: "Sin Datos",
            addTooltip: "",
            editTooltip: "",
            deleteTooltip: "",
            editRow: {
              deleteText: "¿Eliminar Fila?",
              saveTooltip: "",
              cancelTooltip: "",
            },
          },
          toolbar: {
            searchTooltip: "",
            searchPlaceholder: "Buscar",
            nRowsSelected: "",
          },
          pagination: {
            labelRowsSelect: "Seleccionado",
            labelDisplayedRows: " {from}-{to} de {count}",
            firstTooltip: "Primero",
            previousTooltip: "Previo",
            nextTooltip: "Siguiente",
            lastTooltip: "Ultimo",
          },
        }}
      />
    </ThemeProvider>
  );
}
