import React, { useContext, useEffect, useMemo, useState } from "react";
import BasicView from "./Templates/BasicView.jsx";
import ContentView from "./Templates/ContentView.jsx";
import ColComponent from "./Templates/ColComponent.jsx";
import CardComponent from "./Templates/CardComponent.jsx";
import TableEditableComponent from "./Templates/TableEditableComponent";
import { MenuContext } from "../components/Utils/MenuContext";
import AutocompleteComponent from "./Templates/AutocompleteComponent";
import { config } from "../config.js";
import { useConnection } from "../components/Utils/MyHooks";
import * as utils from "../components/Utils/Utils";
import ActivityPanel from "./ActivityPanel";
import WrapperPermisos from "../components/Authentication/WrapperPermisos.jsx";

export default function HomeView() {
  const menu = useContext(MenuContext);
  const consultas = useConnection(true);
  const activos = useConnection(true);

  const [body, setBody] = useState([]);
  const [error, setError] = useState([]);
  const [selected, setSelected] = useState("todas");
  const [search, setSearch] = useState("");

  const [loading_consultas, setLoadingConsultas] = useState(true);

  const array_month = utils.createListEmbarazosByMonth();
  const fecha30 = new Date(new Date().setDate((new Date()).getDate() - 30)).toISOString().split('T')[0];

  useEffect(() => {
    let tmp = [];
    array_month.forEach((element) => {
      tmp.push({
        url:
          config.api_embarazos_month +
          element.getFullYear() +
          "/" +
          (element.getMonth() + 1) +
          "/P/",
        method: "GET",
        body: null,
      });
    });
    activos.list(tmp);
  }, []);

  useEffect(() => {
    consultas.list([
      {
        url: `${config.api_consultas_embarazos}?fecha__gte=${fecha30}`,
        method: "GET",
        body: null,
      },
      {
        url: `${config.api_consultas_generales}?fecha__gte=${fecha30}`,
        method: "GET",
        body: null,
      },
    ]);

    return () => {
      setBody([]);
      setError([]);
    };
  }, []);

  useEffect(() => {
    let d = [];
    let e = [];
    if (consultas.status === "done") {
      if (consultas.data) {
        consultas.data.forEach((element) => {
          if (element.data) d = d.concat(element.data);
          else e.push("Error al obtener consultas");
        });

        setBody(d);
        setError(e);
      } else {
        setError([consultas.error.msg]);
      }
      setLoadingConsultas(false);
    }
  }, [consultas.status]);

  useEffect(() => {
    if (selected === "todas") {
      consultas.list([
        {
          url: `${config.api_consultas_embarazos}?fecha__gte=${fecha30}`,
          method: "GET",
          body: null,
        },
        {
          url: `${config.api_consultas_generales}?fecha__gte=${fecha30}`,
          method: "GET",
          body: null,
        },
      ]);
    }

    if (selected === "obstetricia") {
      consultas.list([
        {
          url: `${config.api_consultas_embarazos}?fecha__gte=${fecha30}`,
          method: "GET",
          body: null,
        },
      ]);
    }

    if (selected === "ginecologia") {
      consultas.list([
        {
          url: `${config.api_consultas_generales}?fecha__gte=${fecha30}`,
          method: "GET",
          body: null,
        },
      ]);
    }
  }, [selected]);

  return (
    <BasicView
      title={""}
      error={error}
      loading={activos.isLoading || loading_consultas}
      nav={[
        {
          id: "home",
          nav: "Inicio",
          active: true,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("home")}
            >
              <i className="typcn typcn-home-outline"></i> Inicio
            </a>
          ),
        },
        {
          id: "pacientes_list",
          nav: "Listado Pacientes",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("pacientes-list")}
            >
              <i className="far fa-address-book mg-r-5 tx-16"></i> Listado
              Pacientes
            </a>
          ),
        },
        {
          id: "embarazos_list",
          nav: "Listado Embarazos",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("embarazos-list")}
            >
              <i className="far fa-list-alt mg-r-5 tx-16"></i> Listado Embarazos
            </a>
          ),
        },
      ]}
    >
      <div className="row row-xs">
        <ColComponent xl={9} lg={9} style={"mg-b-10"}>
          <CardComponent header={"Pacientes"}>
            <label className="az-content-label tx-11 tx-medium tx-gray-600">
              Búsqueda Rápida
            </label>
            <div className="row row-xs">
              <ColComponent xl={4} lg={4} sm={12} style={"mg-b-10"}>
                <AutocompleteComponent
                  defaultValue={""}
                  callback={(x) => {
                    menu.setView("pacientes", x.id);
                  }}
                  handleCaller={setSearch}
                  callback_mas={(x) => {
                    menu.setView("pacientes-list", search);
                  }}
                  url={config.api_pacientes_busqueda + "?apellido="}
                  render={(x) => {
                    return (
                      <>
                        <div>{x.apellido + ", " + x.nombres}</div>
                        <div className="tx-11 tx-gray-600 pd-b-0 pd-t-5">
                          {"DNI " + x.documento}
                        </div>
                      </>
                    );
                  }}
                />
              </ColComponent>
              <ColComponent xl={5} lg={5} sm={6}>
                <button
                  className="btn btn-secondary pd-y-0-f pd-x-10-f mn-ht-35"
                  onClick={() => {
                    menu.setView("pacientes-list", search);
                  }}
                >
                  Busqueda Extendida
                </button>
              </ColComponent>
              <ColComponent xl={3} lg={3} sm={6}>
                <WrapperPermisos groups={["propietario"]} users={[]}>
                  <div className="float-right">
                    <button
                      className="btn btn-az-primary pd-y-0-f pd-x-10-f mn-ht-35"
                      onClick={() => {
                        menu.setView("abm-pacientes", "");
                      }}
                    >
                      <i className="typcn typcn-user-outline tx-18"></i> Nuevo
                      Paciente
                    </button>
                  </div>
                </WrapperPermisos>
              </ColComponent>
            </div>
          </CardComponent>
          <CardComponent header="Consultas Últimos 30 Días" style={"mg-t-10"}>
            <div className="ht-ultimas-consultas" style={{ height: "467px" }}>
              <ContentView error={error} loading={consultas.isLoading}>
                <div
                  style={{
                    zIndex: "99",
                    position: "relative",
                  }}
                >
                  <label className="az-content-label tx-11 tx-medium tx-gray-600">
                    Tipo Consulta
                  </label>
                  <select
                    className="custom-select"
                    data-select2-id="1"
                    tabIndex="-1"
                    aria-hidden="true"
                    value={selected}
                    onChange={(e) => setSelected(e.target.value)}
                  >
                    <option value="todas" data-select2-id="3">
                      Todas
                    </option>
                    <option value="obstetricia" data-select2-id="15">
                      Obstetricia
                    </option>
                    <option value="ginecologia" data-select2-id="16">
                      Ginecología
                    </option>
                  </select>
                </div>
                {/* <NewTableComponent /> */}
                {useMemo(
                  () => (
                    <TableEditableComponent
                      no_toolbar={true}
                      sorting={true}
                      callback_row={(x) => {
                        menu.setView("pacientes", x.paciente);
                      }}
                      print_button={false}
                      download_button={false}
                      paging={true}
                      no_editable={true}
                      max_items={5}
                      data={body}
                      header={[
                        {
                          title: "Fecha",
                          field: "fecha",
                          width: "5%",
                          defaultSort: "desc",
                          render: (x) => {
                            return utils.displayFecha(x.fecha);
                          },
                        },
                        {
                          title: "Paciente",
                          field: "paciente",
                          width: "15%",
                          render: (x) => {
                            return (
                              x.paciente_apellido + ", " + x.paciente_nombres
                            );
                          },
                        },
                        {
                          title: "Tipo Consulta",
                          width: "15%",
                          render: (x) => {
                            if (x.embarazo) {
                              return (
                                <>
                                  <span className="badge badge-primary mg-r-5">
                                    Obstetricia
                                  </span>
                                  <span className="tx-gray-700 tx-medium tx-12">
                                    {`Semana ${x.semana}`}
                                  </span>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <span className="badge badge-info mg-r-5">
                                    Ginecologia
                                  </span>
                                </>
                              );
                            }
                          },
                        },
                        {
                          title: "Medico",
                          field: "medico",
                          width: "15%",
                          render: (x) => {
                            return (
                              "Dr. " +
                              x.medico_apellido +
                              ", " +
                              x.medico_nombres
                            );
                          },
                        },
                      ]}
                    />
                  ),
                  [body]
                )}
              </ContentView>
            </div>
          </CardComponent>
        </ColComponent>
        <ColComponent xl={3} lg={3}>
          <ActivityPanel data_panel={activos} array_month={array_month} />
        </ColComponent>
      </div>
    </BasicView>
  );
}
