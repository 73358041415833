import React, { useState, useEffect, useContext } from "react";
import { config } from "../../config";
import { AuthContext } from "../../components/Authentication/AuthComponent";

export default function ErrorView(props) {
  const auth = useContext(AuthContext);
  const [text, setText] = useState([]);
  const [subtext, setsubText] = useState(
    "Por Favor Intente Nuevamente Más Tarde"
  );

  useEffect(() => {
    if (props.arg) {
      setText(props.arg.text);
      setsubText(props.arg.subtext);
    }
  }, [props.arg]);
  let count = 1;

  return (
    <div className="az-error-wrapper">
      <h1>404</h1>
      {text.map((e) => {
        return <h2 key={count++}>{e}</h2>;
      })}
      <h6>{subtext}</h6>
      <a
        href="#"
        className="btn btn-outline-indigo"
        onClick={() => {
          auth.doLogout();
        }}
      >
        Volver
      </a>
    </div>
  );
}
