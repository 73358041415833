import React, { useState, useEffect } from "react";

import MyDatePicker from "../../Templates/MyDatePicker";
import * as services from "../../../services/embarazo_services";
import ButtonStatus from "../../Templates/ButtonStatus";
import DebugErrors from "../../Templates/DebugErrors";

import { useForm } from "react-hook-form";
import { useConnection } from "../../../components/Utils/MyHooks";
import Swal from "sweetalert2";
import { config } from "../../../config";

export default function AntecedentesEmbarazo(props) {
  const [fields, setFields] = useState([]);
  const [error, setError] = useState([]);

  const form = useForm();
  const save = useConnection();
  const [status, setStatus] = useState("");

  const [total_gestas, setTotalGestas] = useState({
    abortos: 0,
    vaginales: 0,
    cesareas: 0,
  });
  const [total_partos, setTotalPartos] = useState({
    viven: 0,
    mueren_antes: 0,
    mueren_despues: 0,
    muertos: 0,
  });

  useEffect(() => {
    if (props.data[0]) {
      let data = props.data[0];
      setFields(data);
      setTotalGestas({
        abortos: data.abortos,
        vaginales: data.partos_vaginales,
        cesareas: data.partos_cesareas,
      });
      setTotalPartos({
        viven: data.viven,
        mueren_antes: data.mueren_semana,
        mueren_despues: data.mueren_despues,
        muertos: data.nacidos_muertos,
      });
    }
  }, [props.data]);

  useEffect(() => {
    let err = [];
    if (save.status === "done") {
      if (save.data) {
        save.data.forEach((element) => {
          if (element.error) err.push(element);
        });
        setStatus("done");
      } else {
        // err = save.error.msg;
        setStatus("");
      }

      if (err.length > 0) setError(err);
      // if (err) error_msg(err);
    }
  }, [save.status]);

  let html = (
    <div className="row">
      <div className="col-md-3">
        <div data-label="Gestas" className="df-example">
          <div
            data-label="Abortos"
            className="form-group col-md-7 mg-t-15 df-example"
          >
            <input
              type="number"
              className={
                form.errors["abortos"]
                  ? "form-control ht-25 wd-50 bd bd-1 bd-danger"
                  : "form-control ht-25 wd-50"
              }
              name="abortos"
              defaultValue={fields["abortos"]}
              onChange={(e) => {
                let value = 0;
                if (e.target.value !== "") value = Number(e.target.value);

                setTotalGestas((total_gestas) => ({
                  ...total_gestas,
                  abortos: value,
                }));
              }}
              ref={form.register({
                validate: (value) => {
                  return Number.isInteger(Number(value));
                },
              })}
            />
          </div>
          <div
            data-label="Partos"
            className="form-group col-md-12 mg-t-10 df-example"
          >
            <div className="form form-row">
              <div className="form-group col-md-6">
                <label className="az-content-label-sm tx-gray-600">
                  Vaginales
                </label>
                <input
                  type="number"
                  className={
                    form.errors["partos_vaginales"]
                      ? "form-control ht-25 wd-50 bd bd-1 bd-danger"
                      : "form-control ht-25 wd-50"
                  }
                  name="partos_vaginales"
                  defaultValue={fields["partos_vaginales"]}
                  onChange={(e) => {
                    let value = 0;
                    if (e.target.value !== "") value = Number(e.target.value);

                    setTotalGestas((total_gestas) => ({
                      ...total_gestas,
                      vaginales: value,
                    }));
                  }}
                  ref={form.register({
                    validate: (value) => {
                      return Number.isInteger(Number(value));
                    },
                  })}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="az-content-label-sm tx-gray-600">
                  Cesareas
                </label>
                <input
                  type="number"
                  className={
                    form.errors["partos_cesareas"]
                      ? "form-control ht-25 wd-50 bd bd-1 bd-danger"
                      : "form-control ht-25 wd-50"
                  }
                  name="partos_cesareas"
                  defaultValue={fields["partos_cesareas"]}
                  onChange={(e) => {
                    let value = 0;
                    if (e.target.value !== "") value = Number(e.target.value);

                    setTotalGestas((total_gestas) => ({
                      ...total_gestas,
                      cesareas: value,
                    }));
                  }}
                  ref={form.register({
                    validate: (value) => {
                      return Number.isInteger(Number(value));
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className="form form-row">
            <div className="form-group col-md-12">
              <label className="az-content-label-sm tx-gray-600">
                Total Gestas
              </label>
              <input
                type="number"
                readOnly
                className="form-control ht-25 wd-50"
                value={Number(
                  total_gestas.abortos +
                    total_gestas.cesareas +
                    total_gestas.vaginales
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-9">
        <div data-label="Partos" className="df-example">
          <div className="form form-row mg-t-15">
            <div className="form-group col-md-4">
              <div data-label="Nacidos Muertos" className="df-example">
                <input
                  type="number"
                  className={
                    form.errors["nacidos_muertos"]
                      ? "form-control ht-25 wd-50 bd bd-1 bd-danger"
                      : "form-control ht-25 wd-50"
                  }
                  name="nacidos_muertos"
                  defaultValue={fields["nacidos_muertos"]}
                  onChange={(e) => {
                    let value = 0;
                    if (e.target.value !== "") value = Number(e.target.value);

                    setTotalPartos((total_partos) => ({
                      ...total_partos,
                      muertos: value,
                    }));
                  }}
                  ref={form.register({
                    validate: (value) => {
                      return Number.isInteger(Number(value));
                    },
                  })}
                />
              </div>
            </div>
            <div className="form-group offset-md-5 col-md-3">
              <label className="az-content-label-sm tx-gray-600">
                Total Partos
              </label>
              <input
                type="number"
                readOnly
                className="form-control ht-25 wd-50"
                value={Number(
                  total_partos.viven +
                    total_partos.mueren_antes +
                    total_partos.mueren_despues +
                    total_partos.muertos
                )}
              />
            </div>
          </div>
          <div data-label="Nacidos Vivos" className="df-example">
            <div className="form form-row">
              <div className="form-group col-md-2">
                <label className="az-content-label-sm tx-gray-600">Viven</label>
                <input
                  type="number"
                  className={
                    form.errors["viven"]
                      ? "form-control ht-25 wd-50 bd bd-1 bd-danger"
                      : "form-control ht-25 wd-50"
                  }
                  name="viven"
                  defaultValue={fields["viven"]}
                  onChange={(e) => {
                    let value = 0;
                    if (e.target.value !== "") value = Number(e.target.value);

                    setTotalPartos((total_partos) => ({
                      ...total_partos,
                      viven: value,
                    }));
                  }}
                  ref={form.register({
                    validate: (value) => {
                      return Number.isInteger(Number(value));
                    },
                  })}
                />
              </div>
              <div className="form-group col-md-3">
                <label className="az-content-label-sm tx-gray-600">
                  Mueren &lt; Semana 1
                </label>
                <input
                  type="number"
                  className={
                    form.errors["mueren_semana"]
                      ? "form-control ht-25 wd-50 bd bd-1 bd-danger"
                      : "form-control ht-25 wd-50"
                  }
                  name="mueren_semana"
                  defaultValue={fields["mueren_semana"]}
                  onChange={(e) => {
                    let value = 0;
                    if (e.target.value !== "") value = Number(e.target.value);

                    setTotalPartos((total_partos) => ({
                      ...total_partos,
                      mueren_antes: value,
                    }));
                  }}
                  ref={form.register({
                    validate: (value) => {
                      return Number.isInteger(Number(value));
                    },
                  })}
                />
              </div>
              <div className="form-group col-md-3">
                <label className="az-content-label-sm tx-gray-600">
                  Mueren &gt; Semana 1
                </label>
                <input
                  type="number"
                  className={
                    form.errors["mueren_despues"]
                      ? "form-control ht-25 wd-50 bd bd-1 bd-danger"
                      : "form-control ht-25 wd-50"
                  }
                  name="mueren_despues"
                  defaultValue={fields["mueren_despues"]}
                  onChange={(e) => {
                    let value = 0;
                    if (e.target.value !== "") value = Number(e.target.value);

                    setTotalPartos((total_partos) => ({
                      ...total_partos,
                      mueren_despues: value,
                    }));
                  }}
                  ref={form.register({
                    validate: (value) => {
                      return Number.isInteger(Number(value));
                    },
                  })}
                />
              </div>
              <div className="form-group offset-md-1 col-md-3">
                <label className="az-content-label-sm tx-gray-600">
                  Total Nacidos Vivos
                </label>
                <input
                  type="number"
                  readOnly
                  className="form-control ht-25 wd-50"
                  value={Number(
                    total_partos.viven +
                      total_partos.mueren_antes +
                      total_partos.mueren_despues
                  )}
                />
              </div>
            </div>
          </div>
          <div className="form form-row mg-t-10">
            <div className="form-group col-md-4">
              <label className="az-content-label-sm tx-gray-600">
                Fecha Ultimo Parto
              </label>
              <MyDatePicker
                name={"fecha_terminacion_anterior"}
                ref={form.register}
                fecha={fields["fecha_terminacion_anterior"]}
              />
            </div>
            <div className="form-group col-md-4">
              <label className="az-content-label-sm tx-gray-600">
                Algun RN pesó &lt; 2500g ?
              </label>
              <div className="d-flex">
                <label className="rdiobox wd-50p">
                  <input
                    name="algun_peso_menor"
                    type="radio"
                    id="rdio_att"
                    ref={form.register}
                    checked={fields["algun_peso_menor"] === true}
                    onChange={() =>
                      setFields((fields) => ({
                        ...fields,
                        algun_peso_menor: true,
                      }))
                    }
                  />
                  <span className="tx-12 pd-l-8-f">SI</span>
                </label>
                <label className="rdiobox wd-50p">
                  <input
                    name="algun_peso_menor"
                    type="radio"
                    id="rdio_att"
                    ref={form.register}
                    checked={fields["algun_peso_menor"] === false}
                    onChange={() =>
                      setFields((fields) => ({
                        ...fields,
                        algun_peso_menor: false,
                      }))
                    }
                  />
                  <span className="tx-12 pd-l-8-f">NO</span>
                </label>
              </div>
            </div>
            <div className="form-group col-md-4">
              <label className="az-content-label-sm tx-gray-600">
                Nacimiento Mayor Peso (g)
              </label>
              <input
                type="number"
                name="peso_mayor"
                ref={form.register}
                className="form-control wd-50p ht-30"
                defaultValue={fields["peso_mayor"]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {config.debug && <DebugErrors watch={error} />}
      {config.debug && error.length === 0 && (
        <>
          <div style={{ height: "500px" }}>{html}</div>
          <ButtonStatus
            clase={"btn btn-az-primary mg-t-20 float-right"}
            status={status}
            txt_success={"Guardado!"}
            txt_loading="Guardando.."
            onClick={form.handleSubmit((x) => {
              setStatus("loading");
              let tmp = [];

              let form_emb = services.format_antec_embarazo({
                ...x,
                paciente: props.paciente.id,
              });

              if (props.data.length > 0) {
                tmp.push({
                  url:
                    config.api_pacientes_antecedentes_obstetricos +
                    props.data[0].id +
                    "/",
                  method: "PUT",
                  body: form_emb,
                });
              } else {
                tmp.push({
                  url: config.api_pacientes_antecedentes_obstetricos,
                  method: "POST",
                  body: form_emb,
                });
              }

              save.list(tmp);
            })}
          >
            <i className="far fa-save tx-15 mg-r-5"></i>Guardar
          </ButtonStatus>
        </>
      )}
    </>
  );
}
