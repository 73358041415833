import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthComponent";

const useWrapper = () => {
  const context = useContext(AuthContext);
  const [permisos, setPermisos] = useState(null);

  useEffect(() => {
    let tmp = context.getSessionData();
    if (tmp) {
      setPermisos({
        user: tmp.id,
        groups: tmp.groups.map((x) => {
          return context.getGroupDataName(x);
        }),
        admin: tmp.is_staff,
      });
    }
  }, [context.getSessionData()]);

  const checkPermisos = (users, groups) => {
    if (!users && !groups) return true;
    if (!permisos) return false;
    if (permisos.admin) return true;

    if (users.includes(permisos.user)) {
      return true;
    } else if (permisos.groups.some((r) => groups.includes(r))) {
      return true;
    } else {
      return null;
    }
  };

  return { checkPermisos };
};

export default function WrapperPermisos(props) {
  const wrap = useWrapper();
  if (wrap.checkPermisos(props.users, props.groups)) return props.children;
  else return props.default || null;
}

export { WrapperPermisos, useWrapper };
