import React, { useEffect, useState } from "react";

function nav_tabs(data) {
  let html = [];
  let count = 1;
  data.forEach((element) => {
    if (element) {
      html.push(
        <a
          key={element.id}
          className={count === 1 ? "nav-link active" : "nav-link"}
          id={"tab_" + element.id + "_tab"}
          data-toggle="tab"
          href={"#tab_" + element.id}
          role="tab"
          aria-controls={"tab_" + element.id + "control"}
          aria-selected="true"
        >
          {element.nav}
          {element.subnav && (
            <div className="tx-11 tx-dark tx-medium">{element.subnav}</div>
          )}
        </a>
      );
      count++;
    }
  });
  return html;
}

function tab_content(data) {
  let html = [];
  let count = 1;
  data.forEach((element) => {
    if (element)
      html.push(
        <div
          key={element.id}
          className={
            count === 1 ? "tab-pane fade active show" : "tab-pane fade"
          }
          id={"tab_" + element.id}
          role="tabpanel"
          aria-labelledby={"tab_" + element.id + "_label"}
        >
          {element.component}
        </div>
      );
    count++;
  });
  return html;
}

export default function TabComponent(props) {
  return (
    <>
      <div
        style={{
          padding: "15px 15px 0",
          borderBottom: "1px solid #cdd4e0",
        }}
      >
        <nav className="nav nav-tabs" role="tablist">
          {nav_tabs(props.nav)}
        </nav>
      </div>
      <div className="tab-content mg-t-10">{tab_content(props.nav)}</div>
    </>
  );
}
