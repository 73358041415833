import React, { useContext, useEffect, useState } from "react";
import BasicView from "../Templates/BasicView";
import CardComponent from "../Templates/CardComponent";
import ContentView from "../Templates/ContentView";
import { MenuContext } from "../../components/Utils/MenuContext";
import { useConnection } from "../../components/Utils/MyHooks";
import { config } from "../../config";
import TableEditableComponent from "../Templates/TableEditableComponent";

export default function PacientesTableView(props) {
  const data_search = useConnection(true);
  const menu = useContext(MenuContext);

  useEffect(() => {
    data_search.get(config.api_pacientes_busqueda);
  }, []);

  return (
    <BasicView
      title={""}
      error={[]}
      loading={false}
      nav={[
        {
          id: "home",
          nav: "Inicio",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("home")}
            >
              <i className="typcn typcn-home-outline"></i> Inicio
            </a>
          ),
        },
        {
          id: "pacientes_list",
          nav: "Listado Pacientes",
          active: true,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("pacientes-list")}
            >
              <i className="far fa-address-book mg-r-5 tx-16"></i> Listado
              Pacientes
            </a>
          ),
        },
        {
          id: "embarazos_list",
          nav: "Listado Embarazos",
          active: false,
          item: (
            <a
              href="#"
              className="nav-link"
              onClick={() => menu.linkNav("embarazos-list")}
            >
              <i className="far fa-list-alt mg-r-5 tx-16"></i> Listado Embarazos
            </a>
          ),
        },
      ]}
    >
      <CardComponent style={"ht-725"}>
        <ContentView
          error={data_search.error ? [data_search.error.msg] : []}
          loading={data_search.isLoading}
        >
          <TableEditableComponent
            defaultSearch={props.arg}
            no_toolbar={false}
            sorting={true}
            search={true}
            callback_row={(x) => {
              menu.setView("pacientes", x.id);
            }}
            print_button={false}
            download_button={false}
            paging={true}
            no_editable={true}
            max_items={8}
            data={data_search.data ? data_search.data : []}
            header={[
              {
                title: "Apellido",
                field: "apellido",
                // width: "5%",
                defaultSort: "desc",
                render: (x) => {
                  return x.apellido;
                },
              },
              {
                title: "Nombres",
                field: "nombres",
                // width: "5%",
                defaultSort: "desc",
                render: (x) => {
                  return x.nombres;
                },
              },
              {
                title: "Documento",
                field: "documento",
                // width: "5%",
                defaultSort: "desc",
                render: (x) => {
                  return x.documento;
                },
              },
              {
                title: "Telefono",
                // width: "5%",
                defaultSort: "desc",
                render: (x) => {
                  if (x.telefono1 !== "") return x.telefono1;

                  if (x.telefono2 !== "") return x.telefono2;
                },
              },
            ]}
          />
        </ContentView>
      </CardComponent>
    </BasicView>
  );
}
