import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
import { AuthComponent } from "./components/Authentication/AuthComponent";
import * as serviceWorker from "./serviceWorker";

import "./assets/lib/fontawesome-free/css/all.min.css";
import "./assets/lib/ionicons/css/ionicons.min.css";
import "./assets/lib/typicons.font/typicons.css";
import "./assets/css/azia.css";
// import "./assets/js/azia.js";

require("bootstrap");

// var pdfMake = require("pdfmake/build/pdfmake.js");
// var pdfFonts = require("pdfmake/build/vfs_fonts.js");
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

ReactDOM.render(
  <AuthComponent>
    <App />
  </AuthComponent>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
