import React, { useContext, useState, useEffect } from "react";
import { config } from "../config.js";
import { AuthContext } from "../components/Authentication/AuthComponent";
import { useForm } from "react-hook-form";
import { useConnection } from "../components/Utils/MyHooks";
import * as utils from "../components/Utils/Utils";

export default function LoginView() {
  const context = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const login = useConnection();
  const reset = useConnection();

  const [reset_form, setResetForm] = useState(false);
  const form = useForm();

  const [count, setCount] = useState(null);

  useEffect(() => {
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/");
      console.log(c);
    });
  }, []);

  useEffect(() => {
    if (login.status === "done") {
      if (!login.error) {
        if (Object.keys(login.data).length > 0) {
          context.setToken(login.data);
          let values = form.getValues();
          if (values.user === values.password) {
            setResetForm(true);
          } else context.doLogin();
        }
      } else {
        console.log(login.error);
        if (login.error.code === 400) {
          let err = login.error.obj["non_field_errors"];
          if (err) {
            setError(err[0]);
          } else {
            setError(
              "Error de Conexión. Por Favor Verificar Conexión a Internet."
            );
          }
        } else {
          setError(login.error.msg);
        }
      }
    }
  }, [login.status]);

  useEffect(() => {
    if (reset.status === "done") {
      if (reset.error) {
        let e = [];
        if (reset.error.obj["new_password1"])
          e = e.concat(reset.error.obj["new_password1"]);

        if (reset.error.obj["new_password2"]) {
          e = e.concat(reset.error.obj["new_password2"]);
        }

        setError(e);
      } else {
        setError(null);
        setSuccess(true);
      }
    }
  }, [reset.status]);

  useEffect(() => {
    if (success) {
      if (count === 0) {
        setSuccess(false);
        context.doLogin();
      } else {
        setTimeout(() => setCount(count - 1), 1000);
      }
    }
  }, [count]);

  useEffect(() => {
    if (success) setCount(3);
  }, [success]);

  const doPost = form.handleSubmit((x) => {
    setError(null);
    let form = new FormData();
    form.append("username", x.user);
    form.append("password", x.password);
    login.post(config.api_login, form);
  });

  const doChange = form.handleSubmit((x) => {
    setError(null);
    let form = new FormData();
    form.append("new_password1", x.new_password1);
    form.append("new_password2", x.new_password2);
    reset.post(config.api_change_pass, form);
  });

  if (success) {
    return (
      <div className="az-signin-wrapper" style={{ marginTop: "5%" }}>
        <div
          className="card card-dashboard-events"
          style={{ backgroundColor: "#edeaf138" }}
        >
          <div className="card-body pd-20-f">
            <h4>Contraseña cambiada con éxito! Iniciando Sesión...</h4>
            <h4 className={"tx-center"}>{count}</h4>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="az-signin-wrapper" style={{ marginTop: "5%" }}>
          <div
            className="az-card-signin ht-550"
            style={{ backgroundColor: "#edeaf138" }}
          >
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <img
                src={require("../assets/img/Logo.png")}
                className="rounded-circle wd-150 bd bd-1 bd-gray-200"
                alt=""
              />
            </div>
            {reset_form && (
              <div
                className="mg-t-40"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    doChange();
                  }
                }}
              >
                <h3>Cambiar Contraseña</h3>
                <div className="form-group">
                  <label className="az-content-label-sm tx-gray-600">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    name="new_password1"
                    className={
                      form.errors["new_password1"]
                        ? "form-control bd-danger"
                        : "form-control"
                    }
                    ref={form.register({ required: true })}
                    autoComplete="off"
                    autoFocus
                  />
                </div>
                <div className="form-group">
                  <label className="az-content-label-sm tx-gray-600">
                    Confirmar Contraseña
                  </label>
                  <input
                    type="password"
                    name="new_password2"
                    className={
                      form.errors["new_password2"]
                        ? "form-control bd-danger"
                        : "form-control"
                    }
                    ref={form.register({ required: true })}
                    autoComplete="off"
                  />
                </div>
                <button
                  className="btn btn-az-primary btn-block"
                  onClick={() => doChange()}
                >
                  {reset.status === "loading" && "Cambiando..."}
                  {reset.status !== "loading" && "Cambiar"}
                </button>
                <div className="mg-t-10">
                  <span className="tx-danger tx-11">
                    {error &&
                      error.map((e) => {
                        let c = 1;
                        return (
                          <p key={c++} className="mg-b-5">
                            {e}
                          </p>
                        );
                      })}
                  </span>
                  <span className="tx-danger tx-13">{success}</span>
                </div>
              </div>
            )}
            {!reset_form && (
              <div
                className="mg-t-40"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    doPost();
                  }
                }}
              >
                <h3>Inicio de Sesión</h3>
                <div className="form-group">
                  <label className="az-content-label-sm tx-gray-600">
                    Usuario
                  </label>
                  <input
                    type="text"
                    name="user"
                    className={
                      form.errors["user"]
                        ? "form-control bd-danger"
                        : "form-control"
                    }
                    ref={form.register({ required: true })}
                    autoComplete="off"
                    autoFocus
                  />
                </div>
                <div className="form-group">
                  <label className="az-content-label-sm tx-gray-600">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    name="password"
                    className={
                      form.errors["password"]
                        ? "form-control bd-danger"
                        : "form-control"
                    }
                    ref={form.register({ required: true })}
                    autoComplete="off"
                  />
                </div>
                <button
                  className="btn btn-az-primary btn-block"
                  onClick={() => doPost()}
                >
                  {login.status === "loading" && "Ingresando..."}
                  {login.status !== "loading" && "Ingresar"}
                </button>
                <div className="mg-t-10">
                  <span className="tx-danger tx-11">{error}</span>
                </div>
              </div>
            )}
          </div>
          <div
            className="az-card-signin mg-t-5 ht-10"
            style={{ border: "none", boxShadow: "none", padding: "0px" }}
          >
            <span className="tx-right tx-11 tx-gray-600">
              {utils.getVersion().tag + "." + utils.getVersion().commit}
            </span>
          </div>
        </div>
      </>
    );
  }
}
