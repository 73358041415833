import React from "react";

export default function CardComponent(props) {
  return (
    <React.Fragment>
      <div
        className={
          props.style
            ? "card card-dashboard-events " + props.style
            : "card card-dashboard-events "
        }
      >
        {props.header ? (
          <div
            className="card-header border-bottom d-flex align-items-center justify-content-between"
            role="tab"
          >
            <h5 className="card-subtitle">{props.header}</h5>
            {props.button ? <div>{props.button}</div> : ""}
          </div>
        ) : (
          ""
        )}
        <div className="card-body pd-20-f">{props.children}</div>
      </div>
    </React.Fragment>
  );
}
