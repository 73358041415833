import React, { useEffect, useState } from "react";
import { config } from "../../config.js";
import { useFetch, useConnection } from "../../components/Utils/MyHooks";
import * as utils from "../../components/Utils/Utils";

export default function AutocompleteComponent(props) {
  const [searchInput, setSearchInput] = useState("");
  const connect = useConnection();
  const [data, setData] = useState([]);

  const handleSearch = (text) => {
    if (text.length > 2) {
      connect.get(props.url + text);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    if (props.defaultValue) {
      setSearchInput(props.defaultValue);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (connect.status === "done") {
      if (connect.data) setData(connect.data);
    }
  }, [connect.status]);

  const renderLi = (data) => {
    let html = [];

    data.some(function (x, key) {
      if (key > 3) {
        html.push(
          <li
            key={key}
            onClick={(e) => {
              props.callback_mas(searchInput);
              setData([]);
            }}
            className="list-group-item tx-center tx-brand-01 tx-12"
          >
            {data.length - 4} Resultados Más
          </li>
        );
        return true;
      } else
        html.push(
          <li
            key={key}
            className="list-group-item pd-5-f"
            onClick={() => {
              props.callback(x);
              setData([]);
            }}
          >
            {props.render(x)}
          </li>
        );
    });

    return html;
  };

  return (
    <React.Fragment>
      <input
        type="text"
        className={"form-control ht-35 wd-100p"}
        style={{ textTransform: "capitalize" }}
        value={searchInput}
        placeholder={"Ingresar más de 2 caracteres"}
        onChange={(event) => {
          setSearchInput(event.target.value);
          handleSearch(event.target.value);
          props.handleCaller(event.target.value);
        }}
        // onBlur={() => setData([])}
        onKeyDown={(event) => {
          if (event.key === "Escape") {
            setData([]);
          }
        }}
      />
      {data && (
        <ul className={"list-unstyled tx-13 autocomplete"}>{renderLi(data)}</ul>
      )}
    </React.Fragment>
  );
}
