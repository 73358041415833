import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import * as utils from "../../../../components/Utils/Utils";
// import Modal from "../../../Templates/Modal";
import NewConsultaEmbarazo from "./NewConsultaEmbarazo";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useConnection } from "../../../../components/Utils/MyHooks";
import { config } from "../../../../config";
import * as services from "../../../../services/embarazo_services";
import TestModal from "../../../Templates/TestModal";
import WrapperPermisos from "../../../../components/Authentication/WrapperPermisos";
import DebugErrors from "../../../Templates/DebugErrors";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  tablecell: {
    fontSize: "13px",
  },
});

const present = {
  Tr: "Transversal",
  Pe: "Pelvico",
  Ce: "Cefálico",
  Po: "Podálico",
  Ob: "Oblicua",
};

const fcf = {
  P: "Positivo",
  N: "Negativo",
  T: "Taquicardia",
  B: "Bradicardia",
  A: "Arritmia",
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" className={classes.tablecell}>
          {utils.displayFecha(row.fecha)}
        </TableCell>
        <TableCell align="center" className={classes.tablecell}>
          {row.semana ? row.semana : "-"}
        </TableCell>
        <TableCell align="center" className={classes.tablecell}>
          {row.peso ? row.peso : "-"}
        </TableCell>
        <TableCell align="center" className={classes.tablecell}>
          {`${row.tension_arterial_max ? row.tension_arterial_max : "-"}/${
            row.tension_arterial_min ? row.tension_arterial_min : "-"
          }`}
        </TableCell>
        <TableCell align="center" className={classes.tablecell}>
          {row.altura_uterina ? row.altura_uterina : "-"}
        </TableCell>
        <TableCell align="center" className={classes.tablecell}>
          {row.presentacion ? present[row.presentacion] : "-"}
        </TableCell>
        <TableCell align="center" className={classes.tablecell}>
          {row.fcf ? fcf[row.fcf] : "-"}
        </TableCell>
        <TableCell align="center" className={classes.tablecell}>
          {row.mov_fetal !== null ? (row.mov_fetal ? "SI" : "NO") : "-"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={1} style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <span className="tx-medium tx-14">Médico: </span>
                <span className="tx-13">
                  Dr. {`${row.medico_apellido}, ${row.medico_nombres}`}
                </span>
              </Grid>
              <Grid item xs={10}>
                <span className="tx-medium tx-14">Observaciones: </span>
                <span className="tx-13">
                  {row.observaciones ? row.observaciones : "-"}
                </span>
              </Grid>
              <WrapperPermisos groups={["propietario"]} users={[row.medico]}>
                <Grid item xs={2}>
                  <div className="btn-group float-right mg-b-10" role="group">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#edit_consulta_obst"
                      className="btn btn-icon pd-0 wd-20 mg-r-15"
                      onClick={() => {
                        props.edit(row);
                      }}
                    >
                      <i className="typcn typcn-edit tx-gray-500 tx-25-f"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-icon pd-0 wd-20"
                      onClick={() => {
                        props.delete(row.id);
                      }}
                    >
                      <i className="typcn typcn-trash tx-gray-500 tx-25-f"></i>
                    </button>
                  </div>
                </Grid>
              </WrapperPermisos>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ListRegistroEmbarazo(props) {
  const [editData, setEditData] = useState([]);
  const form = useForm();
  const update_consulta = useConnection();
  const delete_consulta = useConnection();
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (update_consulta.status) {
      if (update_consulta.status === "done") {
        if (update_consulta.data) {
          props.callback((prev) =>
            prev.map((o) => {
              let tmp = update_consulta.data;
              if (o.id === update_consulta.data.id) return tmp;
              return o;
            })
          );
          setIsOpen(false);
        } else {
          setIsOpen(false);
          // error_msg(update_consulta.error.msg);
        }
      }
    }
  }, [update_consulta.status]);

  const del_msg = (data) =>
    Swal.fire({
      title: "¿Eliminar Consulta?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setEditData(data);
        delete_consulta.del(config.api_consultas_embarazos + data.id + "/");
      }
    });

  const error_msg = (msg) =>
    Swal.fire({
      title: msg,
      icon: "error",
      confirmButtonText: "Aceptar",
    });

  useEffect(() => {
    if (delete_consulta.status === "done") {
      if (!delete_consulta.error) {
        let tmp = props.data.filter((x) => {
          return x.id !== editData.id;
        });
        props.callback(tmp);
      } else {
        // error_msg(delete_consulta.error.msg);
      }
    }
  }, [delete_consulta.status]);

  return (
    <>
      <TestModal
        title={"Editar Consulta"}
        isOpen={modalIsOpen}
        aceptar={
          <button
            type="button"
            className="btn btn-az-primary"
            onClick={form.handleSubmit((x) => {
              let form = services.format_consulta_embarazo({
                ...x,
                medico: props.medico,
              });
              update_consulta.patch(
                config.api_consultas_embarazos + editData.id + "/",
                form
              );
            })}
          >
            <i className="far fa-save tx-15 mg-r-5"></i>Actualizar
          </button>
        }
        cancelar={
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </button>
        }
      >
        <NewConsultaEmbarazo
          data={editData}
          errors={form.errors}
          ref={form.register}
        />
      </TestModal>
      {config.debug && (
        <DebugErrors watch={[update_consulta, delete_consulta]} />
      )}
      <TableContainer style={{ marginTop: "-20px" }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell size="small" />
              <TableCell>
                <p>Fecha</p>
                <p></p>
              </TableCell>
              <TableCell align="center">
                <p>Semana</p>
                <p></p>
              </TableCell>
              <TableCell align="center">
                <p className="mg-y-0">Peso</p>
                <p className="mg-y-0 az-content-label tx-11 tx-gray-600">Kg</p>
              </TableCell>
              <TableCell align="center">
                <p className="mg-y-0">Tensión</p>
                <p className="mg-y-0 az-content-label tx-11 tx-gray-600">
                  mm Hg
                </p>
              </TableCell>
              <TableCell align="center">
                <p className="mg-y-0">Altura</p>
                <p className="mg-y-0 az-content-label tx-11 tx-gray-600">Cm</p>
              </TableCell>
              <TableCell align="center">
                <p>Presentación</p>
                <p></p>
              </TableCell>
              <TableCell align="center">
                <p>F.C.F</p>
                <p></p>
              </TableCell>
              <TableCell align="center">
                <p>Mov.</p>
                <p></p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ fontSize: "11px" }}>
            {props.data &&
              props.data.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  edit={() => {
                    setEditData(row);
                    setIsOpen(true);
                  }}
                  delete={() => {
                    del_msg(row);
                  }}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
