import React, { useContext } from "react";
import { AuthContext } from "../../components/Authentication/AuthComponent";

export default function NavTabs(props) {
  let html = [
    // <li key="1" className="nav-item">
    //   <a href="" className="nav-link">
    //     <i className="typcn typcn-home-outline"></i> Inicio
    //   </a>
    // </li>,
  ];
  let count = 1;

  if (props.data) {
    props.data.forEach((element) => {
      if (!element.item) {
        html.push(
          <li key={count} className="nav-item">
            <a
              className={element.active ? "nav-link active" : "nav-link"}
              data-toggle="tab"
              href={"#tab_" + element.id}
            >
              {element.nav}
            </a>
          </li>
        );
      } else {
        html.push(
          <li
            key={count}
            className={element.active ? "nav-item active" : "nav-item"}
          >
            {element.item}
            {/* <a href="#" className="nav-link" onClick={element.link}>
              {element.icon}
            </a> */}
          </li>
        );
      }
      count++;
    });
  }
  return <ul className="nav az-nav-line">{html}</ul>;
}
